import React, { Component, useEffect, useState, useMemo } from 'react';
import { useFeathers, isPermitted } from '../../app/util';
import { useSelector } from 'react-redux';
import { Link, useParams, useHistory, useRouteMatch } from 'react-router-dom';
import moment from 'moment';
import {
  CTAButton,
  LoadingSpinner,
  CandidatePreviewTile,
  BackButton,
  Modal,
  DataDetailToolbar,
  DataField,
  EditOfficeField,
  TranslationPairDownload,
  DistrictSelectInput,
  FieldInlineEdit,
  HintTile,
  ElectionResultsGraph,
  PartyTag,
  SidePane,
  CoverageIcon,
  ManualVoteTally,
  RaceDataFieldEditor
 } from '../../components';

 import Menu from '@mui/material/Menu';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ArchiveIcon from '@mui/icons-material/Archive';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import PreviewIcon from '@mui/icons-material/Visibility';
import IconButton from '@mui/material/IconButton';
import CreateCandidate from '../CreateCandidate';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

const RaceDetail = () => {
  const feathers = useFeathers();
  const elections = useSelector(state => state.elections.byKey);
  const user = useSelector(state => state.user);

  /* On load, fetch the data for the race */
  const [ raceData, setRaceData ] = useState(null);
  const { id, key } = useParams();
  const history = useHistory();
  const route = useRouteMatch();
  const modalVisible = route.path.includes(`candidates/new`);
  const electionData = elections[key] ?? {};
  const [ electionDataDetail, setElectionDataDetail ] = useState(null);
  const electionOver = electionData.status === 'results-certified';
  const translationPermission = isPermitted(user, ['super-admin', 'translations']);
  const deletePermission = isPermitted(user, ['super-admin', 'editor', 'publisher']);
  const allowCreateGeneral = electionData && electionData?.primaryMode === 'PRIMARY_MODE_TOP_TWO';
  const [ moreButtonClicked, setMoreButtonClicked ] = useState(null);

  useEffect(() => {
    if(feathers && id) {
      loadDataForRace()
      loadElectionDataDetail()
    };
  }, [ feathers, id ])

  const loadDataForRace = async () => {
    setRaceData(null);
    try {
      const race = await feathers.getService('races').get(id);
      setRaceData(race);
    } catch (err) {
      console.log(`Error in retrieving race: `, err)
    }

    ReactTooltip.rebuild()
  }

  const [ deleteModalOpen, setDeleteModalOpen ] = useState(false);
  const archiveRace = async () => {
    try {
      await feathers.getService('races').patch(id, {
        coverageStatus: 'archived'
      })
      loadDataForRace()
    } catch(err) {
      console.log('Error in archiving', err)
    }
  }

  const unarchiveRace = async () => {
    try {
      console.log({
        coverageStatus: 'coverage'
      })
      await feathers.getService('races').patch(id, {
        coverageStatus: 'coverage'
      })
      loadDataForRace()
    } catch(err) {
      console.log('Error in unarchiving', err)
    }
  }

  const deleteRace = async () => {
    try {
      const res = await feathers.getService('races').remove(id);
      history.push(`/elections/${key}/races`);
    } catch (err) {
      console.error(`Could not delete race`, err);
    }
  };

  const [ creatingRunoff, setCreatingRunoff ] = useState(false);
  const [ runoffError, setRunoffError ] = useState(null);
  const createRunoff = async () => {
    const targetElection = electionDataDetail?.runoffElection?.key;
    if(!targetElection || !raceData?.result?.goingToRunoff || raceData?.runoffRace || creatingRunoff) {
      console.log('Invalid conditions for creating a runoff!')
      setRunoffError('Invalid conditions for creating a runoff!')
      return;
    }

    setRunoffError(null)
    setCreatingRunoff(true);
    try {
      const runoff = await feathers.getService('races').create({
        runoffPrecedent: id,
        election: targetElection
      })
      console.log('runoff result')
      console.log(runoff)
      loadDataForRace()
    } catch(err) {
      setRunoffError(err?.message);
    } finally {
      setCreatingRunoff(false);
    }
  }

  const [ creatingGeneral, setCreatingGeneral ] = useState(false);
  const [ generalCreationError, setGeneralCreationError ] = useState(null);
  const createGeneralRace = async () => {
    // TODO...
    if(creatingGeneral) return;

    const targetElection = electionDataDetail?.generalElection?.key;
    if(!targetElection || !raceData?.result?.goingToGeneral || raceData?.generalRace) {
      console.log('Invalid conditions for creating a general election race!')
      setRunoffError('Invalid conditions for creating a general election race!')
      return;
    }

    const winners = Object.entries(raceData.result.byCandidate).filter(([cdId, result]) => {
      return result.status === 'winner'
    }).map(([cdId, result]) => ({ _id: cdId }));

    setGeneralCreationError(null)
    setCreatingGeneral(true);
    try {
      const general = await feathers.getService('races').create({
        primaryPrecedent: [ {
          ...raceData,
          winners
        } ],
        election: targetElection
      })
      console.log('general result')
      console.log(general)
      loadDataForRace()
    } catch(err) {
      setGeneralCreationError(err?.message);
    } finally {
      setCreatingGeneral(false);
    }
  }

  const loadElectionDataDetail = async () => {
    const res = await feathers.getService('elections').get(key);
    setElectionDataDetail(res)
  }

  /* write in votes */
  const totalVotesCandidates = raceData?.result?.byCandidate
    ? Object.values(raceData.result.byCandidate).reduce((acc, val) => acc + (val?.totalVotes > 0 ? val.totalVotes : 0), 0)
    : 0;
  const totalVotes = (raceData?.writeInVotes || 0) + totalVotesCandidates;

  if(!raceData) {
    return (
      <Wrapper>
        <Section style={{ marginBottom: '20px' }}>
          <BackButton onClick={{ to: `/elections/${key}/races` }} />
        </Section>
        <EmptyStateWrapper>
          <LoadingSpinner />
        </EmptyStateWrapper>
      </Wrapper>
    );
  }

  const qualifyingPassed = electionData?.qualifyingDate
    ? moment().isAfter(moment.utc(electionData.qualifyingDate).add(1, 'day'))
    : true;
  const candidates = (raceData.candidates || []).filter(cd => qualifyingPassed ? (cd.qualified === 'yes') : true);
  const notQualifiedCandidates = (raceData.candidates || []).filter(cd => cd.qualified !== 'yes');

  return (
    (<Wrapper>
      {
        modalVisible &&
        (
          <Modal onClose={{ to: `/elections/${key}/races/${id}` }}>
            <CreateCandidate
              onClose={{ to: `/elections/${key}/races/${id}` }}
              onSuccess={() => {
                history.goBack()
                loadDataForRace()
              }}
            />
          </Modal>
        )
      }
      <div style={{ width: 'calc(100% - 36px)', padding: '0 36px 0 0' }}>
        <DataDetailToolbar
          onBack={{ to: {
            pathname: `/elections/${key}/races`,
            search: history?.location?.state?.racesSearch
          }}}
          titleComponent={
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <div>
                <Typography variant={'h1'}>{raceData.officeName}</Typography>
                {
                  raceData?.retention &&
                  <Typography variant={'body2'} style={{ color: '#666666' }}>
                    Retention election
                  </Typography>
                }
              </div>
              {raceData.party && <PartyTag party={raceData.party} style={{ marginLeft: '16px' }}/>}
              <CoverageIcon
                coverageStatus={raceData?.coverageStatus}
                style={{ marginLeft: '16px' }}
              />
            </div>
          }
          navTree={[
            {
              text: elections[key]?.name || 'Election',
              to: {
                pathname: `/elections/${key}`,
                search: history?.location?.state?.racesSearch
              }
            },
            {
              text: 'Races',
              to: {
                pathname: `/elections/${key}/races`,
                search: history?.location?.state?.racesSearch
              }
            },
            { text: raceData.officeName }
          ]}
          actionButtonsComponent={
            <ButtonContainer>
              <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={(e) =>
                  setMoreButtonClicked(e.currentTarget)
                }
                size="large">
                <MoreVertIcon />
              </IconButton>
              <IconButton
                data-tip='Preview Draft'
                style={{
                  marginLeft: '2px'
                }}
                href={`${window.location.hostname.includes('localhost') ? `http://localhost:3030` : `https://www.branch.vote`}/races/${raceData?.raceKey}?staging=true`}
                target='_blank'
                size="large">
                <PreviewIcon/>
              </IconButton>
            </ButtonContainer>
          }
        />
      </div>
      <ContentMain>
        <ContentInner>
          {
            raceData.maxChoices > 1 &&
            <Section style={{ marginBottom: '24px' }}>
              <HintTile style={{ marginTop: '0px' }}>
                <Typography variant='body1'>
                  This race is unique in that the top {raceData.maxChoices} candidates will be elected to the position.<br/><br/>Each voter will be able to pick {raceData.maxChoices} candidates for this race.
                </Typography>
              </HintTile>
            </Section>
          }
          {
            raceData?.result &&
            <Section style={{ flexDirection: 'row', alignItems: 'flex-start' }}>
              <div style={{ display: 'flex', flexDirection: 'column', width: '500px' }}>
                <Typography variant='h3'>Election Results</Typography>
                <ElectionResultsGraph
                  result={raceData.result}
                  candidates={candidates}
                  detailed={true}
                  notifications={false}
                />
              </div>
              <div style={{ marginLeft: '36px', display: 'flex', flexDirection: 'column'}}>
                {
                  raceData?.generalRace &&
                  <div style={{ padding: '24px', backgroundColor: '#FFFFFF' }}>
                    <Typography variant='h3'>General election</Typography>
                    <Typography variant='body1' style={{ marginTop: '8px' }}>This race has moved to a general election.</Typography>
                    <CTAButton
                      value='View general race'
                      mode='pill'
                      style={{
                        marginTop: '16px',
                        height: '24px'
                      }}
                      onClick={{ to: `/elections/${electionDataDetail?.generalElection?.key}/races/${raceData.generalRace}`}}
                    />
                  </div>
                }
                {
                  raceData?.runoffRace &&
                  <div style={{ padding: '24px', backgroundColor: '#FFFFFF' }}>
                    <Typography variant='h3'>Runoff election</Typography>
                    <Typography variant='body1' style={{ marginTop: '8px' }}>This race has moved to a runoff election.</Typography>
                    <CTAButton
                      value='View runoff'
                      mode='pill'
                      style={{
                        marginTop: '16px',
                        height: '24px'
                      }}
                      onClick={{ to: `/elections/${electionDataDetail?.runoffElection?.key}/races/${raceData.runoffRace}`}}
                    />
                  </div>
                }
                {
                  !raceData?.runoffRace && raceData?.result?.goingToRunoff && isPermitted(user, ['editor', 'super-admin', 'researcher', 'admin', 'reviewer', 'publisher']) &&
                  <CTAButton
                    value={creatingRunoff ? 'Creating...' : 'Create runoff'}
                    disabled={creatingRunoff}
                    mode='pill'
                    style={{
                      height: '24px'
                    }}
                    data-tip='This will create a new race for the upcoming election.'
                    onClick={createRunoff}
                  />
                }
                {
                  allowCreateGeneral && electionDataDetail?.generalElection?.key && !raceData?.generalRace && raceData?.result?.goingToGeneral && isPermitted(user, ['editor', 'super-admin', 'researcher', 'admin', 'reviewer', 'publisher']) &&
                  <Button
                    disabled={creatingGeneral}
                    size='medium'
                    variant='contained'
                    color='secondary'
                    data-tip='This will create a new race for the upcoming election.'
                    onClick={createGeneralRace}
                  >
                    {creatingGeneral ? 'Creating...' : 'Create general race'}
                  </Button>
                }
                {
                  (runoffError || generalCreationError) &&
                  <Typography variant='body1'>{runoffError || generalCreationError}</Typography>
                }
                {
                  raceData?.result &&
                  <DataField
                    style={{ marginTop: '24px' }}
                    title='Total votes'
                  >
                    <Typography variant='body1' style={{ fontSize: '14px' }}>{totalVotes} votes</Typography>
                  </DataField>
                }
                {
                  isPermitted(user, electionOver ? ['super-admin'] : ['editor', 'super-admin', 'researcher', 'admin', 'reviewer', 'publisher']) &&
                  <div style={{ marginTop: '24px'}}>
                  <ManualVoteTally raceId={id} onSave={loadDataForRace} writeInVotes={raceData?.writeInVotes}/>
                  </div>
                }
              </div>
            </Section>
          }
          <Section style={{ flexDirection: 'column' }}>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <Typography variant={'h3'}>
                {candidates.length} {qualifyingPassed ? 'qualifying ' : ''}candidate{(candidates.length) === 1 ? `` : `s`}
              </Typography>
              <ButtonContainer>
                {
                  isPermitted(user, electionOver ? ['super-admin'] : ['editor', 'super-admin', 'researcher', 'admin', 'reviewer', 'publisher']) &&
                  <Button
                    onClick={() => history.push(`/elections/${key}/races/${id}/candidates/new`) }
                    variant={'contained'}
                    size={'small'}
                    color={'secondary'}
                  >
                    Add candidate
                  </Button>
                }
              </ButtonContainer>
            </div>
            {
              candidates.length > 0
              ?
                <CandidatesWrapper>
                  {
                    candidates.map(cd => <CandidatePreviewTile
                      candidate={cd}
                      key={cd._id}
                      onClick={{ to: {
                        pathname: `/elections/${key}/races/${id}/candidates/${cd._id}`,
                        state: history.location?.state
                      }}}
                    />)
                  }
                </CandidatesWrapper>
              :
                <EmptyStateWrapper>
                  {
                    qualifyingPassed
                    ? <span>No candidates qualified yet.</span>
                    : <span>No candidates added to this race yet.</span>
                  }
                </EmptyStateWrapper>
            }
          </Section>
          {
            qualifyingPassed && notQualifiedCandidates.length > 0 &&
            <Section style={{ flexDirection: 'column' }}>
              <Typography variant={'h3'}>
                {notQualifiedCandidates.length} non-qualifying candidate{notQualifiedCandidates.length === 1 ? '' : 's'}
              </Typography>
              <CandidatesWrapper>
                {notQualifiedCandidates.map(cd => <CandidatePreviewTile
                  candidate={cd}
                  key={cd._id}
                  onClick={{ to: {
                    pathname: `/elections/${key}/races/${id}/candidates/${cd._id}`,
                    state: history.location?.state
                  }}}
                />)}
              </CandidatesWrapper>
            </Section>
          }
          <Section style={{ flexDirection: 'column' }}>
            <Typography variant={'h3'}>Office information</Typography>
            <DataRow>
              <EditOfficeField
                type='title'
                raceId={raceData._id}
                onChange={loadDataForRace}
                priorityLevel={raceData.priorityLevel}
                defaultValue={raceData.office.name}
                value={raceData.officeName}
                style={{ width: '25%' }}
              />
            </DataRow>
            <DataRow>
              <EditOfficeField
                type='issuesToCover'
                raceId={raceData._id}
                onChange={loadDataForRace}
                priorityLevel={raceData.priorityLevel}
                defaultValue={raceData.office.issuesToCover}
                value={raceData.issuesToCover}
                style={{ width: '100%' }}
              />
            </DataRow>
            <DataRow>
              <EditOfficeField
                type='short'
                raceId={raceData._id}
                onChange={loadDataForRace}
                priorityLevel={raceData.priorityLevel}
                defaultValue={raceData.office.descriptionShort}
                value={raceData.descriptionShort ?? '(No description yet.)'}
                style={{ width: '100%' }}
              />
            </DataRow>
            <DataRow>
              <EditOfficeField
                type='long'
                raceId={raceData._id}
                onChange={loadDataForRace}
                priorityLevel={raceData.priorityLevel}
                defaultValue={raceData.office.descriptionLong}
                value={raceData.descriptionLong ?? '(No description yet.)'}
                style={{ width: '100%' }}
              />
            </DataRow>
            <DataRow>
              <EditOfficeField
                editingDisabled={true}
                type='impactIssues'
                raceId={raceData._id}
                onChange={loadDataForRace}
                priorityLevel={raceData.priorityLevel}
                defaultValue={raceData.office.impactIssues}
                value={raceData.impactIssues}
                style={{ width: '100%' }}
              />
            </DataRow>
          </Section>
        </ContentInner>
        <SidePane style={{ height: '100%' }}>
          <RaceDataFieldEditor raceId={id}/>
        </SidePane>
      </ContentMain>
      <Menu
        id="long-menu"
        anchorEl={moreButtonClicked}
        keepMounted
        open={Boolean(moreButtonClicked)}
        onClose={() => setMoreButtonClicked(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {
          translationPermission &&
          <MenuItem
            onClick={() => {
              setMoreButtonClicked(null);
            }}
          >
            <TranslationPairDownload serviceName='races' objectId={raceData?._id}/>
          </MenuItem>
        }
        {
          deletePermission && raceData && raceData?.candidates?.length === 0 &&
          <MenuItem
            onClick={() => {
              setDeleteModalOpen(true);
              setMoreButtonClicked(null);
            }}
          >
            <ListItemIcon>
              <DeleteIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Delete race</ListItemText>
          </MenuItem>
        }
        {
          deletePermission && raceData && raceData?.candidates?.length > 0 && raceData?.coverageStatus !== 'archived' &&
          <MenuItem
            onClick={() => {
              archiveRace()
              setMoreButtonClicked(null);
            }}
          >
            <ListItemIcon>
              <ArchiveIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Archive race</ListItemText>
          </MenuItem>
        }
        {
          deletePermission && raceData?.coverageStatus === 'archived' &&
          <MenuItem
            onClick={() => {
              unarchiveRace()
              setMoreButtonClicked(null);
            }}
          >
            <ListItemIcon>
              <ArchiveIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Un-archive race</ListItemText>
          </MenuItem>
        }
      </Menu>
      <Dialog
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
      >
        <DialogTitle id="alert-dialog-title">
          {'Are you sure you want to delete the race?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setDeleteModalOpen(false)}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              setDeleteModalOpen(false);
              deleteRace();
            }}
            color="primary"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <ReactTooltip place='left' effect='solid' type='light' multiline/>
    </Wrapper>)
  );
}

const Wrapper = styled.div`
  width: calc(100% - 36px);
  height: calc(100vh - 24px - 24px);
  max-height: calc(100vh - 24px - 24px);
  padding: 24px 0 24px 36px;
  display: flex;
  flex-direction: column;
`;

const ContentMain = styled.div`
  padding: 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: calc(100% - 79px);
  max-height: calc(100% - 79px);
  width: 100%;
  align-items: stretch;
`

const ContentInner = styled.div`
  width: calc(100% - 36px);
  display: flex;
  flex-direction: column;
  padding: 24px 36px 24px 0;
  align-items: stretch;
  overflow-y: auto;
`


const Section = styled.div`
  margin: 0 0 64px;
  width: 100%;
  display: flex;
  h1 {
    font-size: 24px;
    margin: 0 0 0;
  }
`
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;

  div {
    margin: 0 0 0 14px;
  }
`
const OfficeDetails = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-right: 24px;
  margin-bottom: 12px;
  color: ${props => props.theme.colors.darkPurple};

  h1 {
    font-size: 28px;
    margin: 0 0 16px;
  }
`

const DataRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 40px;
`

const EmptyStateWrapper = styled.div`
  width: 100%;
  min-height: 80px;
  margin: 8px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FFFFFF;
  border-radius: 8px;
  border: solid 1px #EEEEEE;
  span {
    ${({ theme }) => theme.font.normal};
    opacity: 0.8;
    font-size: 16px;
  }
`
const CandidatesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: solid 1px #EEEEEE;
  border-radius: 8px;
  background-color: #FFFFFF;
  width: 100%;
  margin-top: 16px;
`

const coverageStatusKeys = {
  'future-coverage': 'Preparing',
  'coverage': 'Live',
  'no-coverage': 'No coverage',
  'no-primary': 'Uncontested',
  'unsure':'To be determined'
}

const SyncContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  svg {
    width: 34px;
    margin-right: 6px;
  }
`

export default RaceDetail;
