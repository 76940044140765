import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useSelector } from 'react-redux';
import { useFeathers } from '../../app/util';
import {
  DataDetailToolbar,
  Tile,
  BasicCoverageCount,
  ElectionResultsHookupCount,
  PhotoOptionsCount
 } from '../../components'
import styled from 'styled-components';
import {useParams} from "react-router-dom";
import Skeleton from '@mui/material/Skeleton';

const DataHookups = () => {
  const feathers = useFeathers();
  const electionKey = useParams()?.key;
  const election = useSelector(state => state?.elections?.byKey[electionKey]);
  const [ basicCoverageText, setBasicCoverageText ] = useState(undefined);
  const [ photoOptionsText, setPhotoOptionsText ] = useState(undefined);
  const [ electionResultsText, setElectionResultsText ] = useState(undefined);

  if(!electionKey) return null;

  return (
    <Wrapper>
      <DataDetailToolbar
        onBack={{ to: {
            pathname: `/elections/${electionKey}`,
          }}}
        navTree={[
          {
            text: 'Elections',
            to: {
              pathname: `/elections`,
            }
          },
          {
            text: election?.name,
            to: {
              pathname: `/elections/${electionKey}`,
            }
          },
          { text: 'Data Hook-ups'}
        ]}
      />
      <Typography style={{ marginBottom: '24px', marginTop: '36px' }} variant={'h1'}>Data Hook-ups</Typography>
      <Content>
        <DashboardTile onClick={{to : `/elections/${electionKey}/data/basic-coverage`}}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch', height: '100%', justifyContent: 'space-between'}}>
            <Typography variant='h3'>Basic coverage</Typography>
            {
              basicCoverageText
              ? <Typography variant='h1' style={{ fontSize: '48px' }}>{basicCoverageText}</Typography>
              : <Skeleton variant='text' width={120} height={30} />
            }
            <Typography variant='body1'>candidates completed</Typography>
          </div>
        </DashboardTile>
        <DashboardTile onClick={{to : `/elections/${electionKey}/data/photos`}}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch', height: '100%', justifyContent: 'space-between'}}>
            <Typography variant='h3'>Photo selections</Typography>
            {
              photoOptionsText
              ? <Typography variant='h1' style={{ fontSize: '48px' }}>{photoOptionsText}</Typography>
              : <Skeleton variant='text' width={120} height={30} />
            }
            <Typography variant='body1'>candidates completed</Typography>
          </div>
        </DashboardTile>
        <DashboardTile onClick={{to : `/elections/${electionKey}/data/results`}}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch', height: '100%', justifyContent: 'space-between'}}>
            <Typography variant='h3'>Election results</Typography>
            {
              electionResultsText
              ? <Typography variant='h1' style={{ fontSize: '48px' }}>{electionResultsText}</Typography>
              : <Skeleton variant='text' width={120} height={30} />
            }
            <Typography variant='body1'>races completed</Typography>
          </div>
        </DashboardTile>
      </Content>
      <BasicCoverageCount 
        electionKey={electionKey} 
        onLoadData={({ numCandidates, numCandidatesCompleted }) => setBasicCoverageText(`${numCandidatesCompleted} of ${numCandidates}`)}
      />
      <PhotoOptionsCount 
        electionKey={electionKey} 
        onLoadData={({ numCandidates, numCandidatesCompleted }) => setPhotoOptionsText(`${numCandidatesCompleted} of ${numCandidates}`)}
      />
      <ElectionResultsHookupCount 
        electionKey={electionKey} 
        onLoadData={({ numRaces, numRacesCompleted }) => setElectionResultsText(`${numRacesCompleted} of ${numRaces}`)}
      />
    </Wrapper>
  )
}


const Wrapper = styled.div`
width: calc(100% - 24px - 36px);
display: flex;
flex-direction: column;
padding: 36px 36px 36px 24px;
align-items: stretch;
`;

const Content = styled.div`
  width: 100%;
  display: grid;
  grid-gap: 16px;
  align-items: stretch;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, 176px);

`

const DashboardTilePrimer = ({style, ...props}) => <Tile {...({
  ...(props || {}),
  shadowEnabled: true,
  chevronEnabled: false,
  style: {
    ...(style || {}),
  }
})}/>

const DashboardTile = styled(DashboardTilePrimer)`
  
`


/*
      <Section>
        <Typography variant='h1'>Election Results (Candidates)</Typography>
        {
          electionResultsExpanded
          ? <ElectionResultsHookups electionKey={electionKey}/>
          : <Button
            color='primary'
            onClick={() => setElectionResultsExpanded(true)}
            variant='contained'
            style={{ alignSelf: 'flex-start', marginTop: '8px'}}
          >
            Load
          </Button>
        }
      </Section>
      <Section>
        <Typography variant='h1'>Election Results (Referendums)</Typography>
        {
          electionResultsExpandedMeasures
          ? <ElectionResultsHookups electionKey={electionKey} mode='measures'/>
          : <Button
            color='primary'
            onClick={() => setElectionResultsExpandedMeasures(true)}
            variant='contained'
            style={{ alignSelf: 'flex-start', marginTop: '8px'}}
          >
            Load
          </Button>
        }
      </Section>
      <Section>
        <Typography variant='h1'>Campaign finance</Typography>
        {
          cfExpanded
          ? <FinanceHookups electionKey={electionKey}/>
          : <Button
            color='primary'
            onClick={() => setCfExpanded(true)}
            variant='contained'
            style={{ alignSelf: 'flex-start', marginTop: '8px'}}
          >
            Load
          </Button>
        }

      </Section>
*/

export default DataHookups;
