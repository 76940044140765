import { Typography } from '@mui/material';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import Check from '@mui/icons-material/Check';
import Remove from '@mui/icons-material/Remove';

const ReviewProgress = ({
  candidate,
	coveragePlan,
	style,
	className
}) => {
	if(coveragePlan !== 'auto-coverage') throw new Error('ReviewProgress only supports auto-coverage candidates');

	// create a list of the status for each section that needs to be completed
	const sectionsWithStatuses = useMemo(() => {
		// bio personal, political, and professional each get their won status
		const bioStatuses = ['bioPersonal', 'bioPolitical', 'bioProfessional'].map(section => {
			const isMissing = candidate?.missingData?.[section];
			const hasText = !isMissing && candidate?.[section]?.length > 5;
			const hasSources = !isMissing && candidate?.[`${section}Sources`]?.length > 0;
			return {
				sectionName: nameForSection[section],
				status: (isMissing || (hasText && hasSources)) ? 'complete' : (
					(hasText && !hasSources) ? 'warning' : 'incomplete'
				),
				messages: [
					(!isMissing && hasText && !hasSources) ? 'Missing Sources' : null
				].filter(Boolean)
			}
		})
		const issueStatus = (candidate.issues || []).map(issue => {
			if(issue.missingData) return { sectionName: issue.title, status: 'complete' };
			if(issue.stances.filter(s => s.usefulness !== 0).length === 0) return { sectionName: issue.title, status: 'incomplete', messages: ['Needs additional research'] };
			const numUsefulStances = issue.stances.filter(s => s.usefulness !== 0).length;
			const everyStanceHasSources = issue.stances.every(s => s.sources.length > 0);
			const everyStanceHasText = issue.stances.every(s => s.text.length > 5);
			const autoStancesNeedUsefulness = issue.stances.filter(s => s.autoSummary?.isAutoSummary).filter(s => typeof(s.usefulness) === 'undefined');
			const autoStancesHaveUsefulness = autoStancesNeedUsefulness.length === 0;

			return {
				sectionName: issue.title,
				status: (autoStancesHaveUsefulness && everyStanceHasText && everyStanceHasSources) ? 'complete' : (
					(!everyStanceHasText || !everyStanceHasSources) ? 'warning' : 'incomplete'
				),
				messages: [
					`${numUsefulStances} stances`,
					(!autoStancesHaveUsefulness) ? `${autoStancesNeedUsefulness?.length} need usefulness scores` : null,
					(!everyStanceHasText) ? 'Missing text' : null,
					(!everyStanceHasSources) ? 'Missing some sources' : null
				].filter(Boolean)
			}
		})
		return [
			...bioStatuses,
			...issueStatus
		]
	}, [candidate])

	return (
		<Wrapper style={style} className={className}>
			{sectionsWithStatuses.map(({ sectionName, status, messages }) => (
				<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch', gap: '0px' }} key={sectionName}>
					<div style={{ display: 'grid', alignItems: 'center', gridGap: '4px', gridTemplateColumns: 'auto 1fr' }}>
						{
							status === 'warning' &&
							<PriorityHighIcon style={{ width: '15px', height: '15px', marginTop: '-4px' }} />
						}
						{
							status === 'complete' &&
							<Check style={{ width: '15px', height: '15px', marginTop: '-4px' }} />
						}
						{
							status === 'incomplete' &&
							<Remove style={{ width: '15px', height: '15px', marginTop: '-4px' }} />
						}
						<Typography variant='body1'>{sectionName}</Typography>
					</div>
					{
						messages?.length > 0 && messages.map(message => (
							<div style={{ marginLeft: '20px', marginTop: '-4px'}} key={message}>
								<Typography variant='caption'>{message}</Typography>
							</div>
						))
					}
				</div>
			))}
		</Wrapper>
	)
}

const nameForSection = {
	'bioPersonal': 'Personal Background',
	'bioPolitical': 'Political Background',
	'bioProfessional': 'Professional Background'
}

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 12px;
	width: 100%;
`

export { ReviewProgress };