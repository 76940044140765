import React from 'react';
import styled from 'styled-components';
import { CandidateProgressBar } from '../ProgressBar';
import { CandidatePhoto } from '../CandidatePhoto';
import { ConfigurableTaskCell } from './ConfigurableTaskCell';
import { Skeleton } from '@mui/material';


/*
*  A preview tile used within the RaceDetail page. For a task, displays some properties of the task
*  columns available:
*  	name = Displays the type of task and the .name of the task or the candidate.name field.
*     supports config options:
*       hideType = if true, will hide the type of task in the name. Set to true if the type of task is obvious based on the context
*   status = the status of the task
*   metrics =
* 	  For completed tasks, displays the time it took to complete the task. Otherwise, displays things like research level required.
*   link =
* 	 A link to the task
*/

const TaskPreviewTable = ({
  tasks,
  loading,
	style,
	className,
	actions = [],		//back-to-queue
  columnsToDisplay = ['name', 'status', 'metrics', 'actions', 'link', ],
  columnConfig = { // this is optional properties to configure the display of the columns
    name: {
     hideType: false, // if true will hide type of task in name,
		 showDetailName: false, // if true will show the detail name of the task (property task.detailName)
    },
  }
}) => {
  // top part shows as many rows in the array with loading bars until is loaded
  return (
		<Table numRows={loading ? 3 : tasks.length} style={style} className={className} numColumns={columnsToDisplay.length}>
			{
				loading && [1, 2, 3].map((index) => (
					<React.Fragment key={index}>
						<RowSpacer style={{ gridRow: `${index + 1} / ${index + 2}` }}/>
						<Cell style={{ gridRow: `${index + 1} / ${index + 2}`, gridColumn: `1 / 2` }}>
							<Skeleton variant='text' width='180px' height='20px'/>
						</Cell>
						<Cell style={{ gridRow: `${index + 1} / ${index + 2}`, gridColumn: `2 / 3` }}>
							<Skeleton variant='text' width='40px' height='20px'/>
						</Cell>
						<Seperator style={{ gridRow: `${index + 1} / ${index + 2}` }}/>
					</React.Fragment>
				))
			}
			{
				!loading && (tasks || []).map((task, index) => {
					return (
						<RowToRender actions={actions} task={task} key={index} rowNumber={index + 1} columnsToDisplay={columnsToDisplay} numRows={tasks.length} columnConfig={columnConfig}/>
					)
				})
			}
		</Table>
  )
}


const RowToRender = ({
	task,
	rowNumber,
	numRows,
  columnsToDisplay,
  columnConfig,
	actions
}) => {

	const gridRow = `${rowNumber} / ${rowNumber + 1}`;
	return (
		<>
			<RowSpacer style={{ gridRow }}/>
      {
        columnsToDisplay.map((column, i) => (
          <ConfigurableTaskCell task={task} column={column} style={{ gridRow, gridColumn: `${i + 1} / ${i + 2}` }} key={i} columnConfig={columnConfig[column] || {}} actions={actions}/>
        ))
      }
			{
				rowNumber !== numRows && <Seperator style={{ gridRow }}/>
			}
		</>
	)
}


const Table = styled.div`
	padding: 0 24px 0;
	display: grid;
	grid-gap: 0 12px;
	grid-template-columns: repeat(${props => props.numColumns}, auto);
	grid-template-rows: repeat(${props => props.numRows}, auto);
	text-decoration: none;
	border: solid 1px #EEEEEE;
	border-radius: 8px;
	background-color: #FFFFFF;
	align-items: center;
	width: calc(100% - 24px * 2);

	p {
		text-decoration: none;
	}
`

const RowSpacer = styled.div`
	grid-column: 1 / -1;
	height: 72px;
	width: 100%;
`

const Cell = styled.div`
	align-self: center;
`

const Seperator = styled.div`
	grid-column: 1 / -1;
	height: 1px;
	align-self: end;
	background-color: #EEEEEE;
	width: calc(100% + 24px * 2);
	margin-left: -24px;
`

export { TaskPreviewTable };
