import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import { StyledMarkdown } from '../StyledMarkdown';
import Text from '@mui/material/Typography';
import { ChevronRightButton } from '../ChevronRightButton';

const ExpandableContent = ({
  title,
  text,
  style={},
  children,
  chevronStyle = {},
  initiallyExpanded = false,
  chevronVariant = 'contained'
}) => {
  const contentRef = useRef(null);
  const wrapperRef = useRef(null);
  const [ expanded, setExpanded ] = useState(initiallyExpanded);
  const [ wrapperHeightInitial, setWrapperHeightInitial ] = useState(0);

  useEffect(() => {
    setWrapperHeightInitial(wrapperRef.current ? wrapperRef.current.getBoundingClientRect().height : 80)
  }, [])

  useEffect(() => {
    if (initiallyExpanded) setExpanded(initiallyExpanded)
  }, [initiallyExpanded])

  const toggleExpansion = () => setExpanded(!expanded);

  return (
    <Inner style={style}>
      <Header className='header' onClick={toggleExpansion}>
        <Text variant='body1' color='textPrimary' style={{ fontWeight: 'bold', flexGrow: 1 }}>{title}</Text>
        <ChevronRightButtonStyled
          className={expanded ? 'expanded' : ''}
          style={chevronStyle}
          variant={chevronVariant}
        />
      </Header>
      <Content className={expanded ? 'expanded' : ''} heightInner={(contentRef.current ? contentRef.current.getBoundingClientRect().height : 800) + wrapperHeightInitial}>
        <ContentInner ref={contentRef}>
          {
            text
            ? <ReactMarkdownStyled>{text}</ReactMarkdownStyled>
            : children
          }
        </ContentInner>
      </Content>
    </Inner>
  )
}

const Inner = styled.div`
  width: calc(100%);
  padding: 16px 0;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
`

const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  cursor: pointer;
`

const Content = styled.div`
  transition: max-height 0.1s linear;
  overflow-y: hidden;
  height: auto;

  max-height: 0px;
  &.expanded {
    max-height: ${props => props.heightInner}px;
  }
`

const ContentInner = styled.div`

`

const ReactMarkdownStyled = styled(StyledMarkdown)`
  margin-top: 12px;
  color: ${props => props.theme.palette.textPrimary};
`

const ChevronRightButtonStyled = styled(ChevronRightButton)`
  -webkit-transition: -webkit-transform 0.1s ease-out;
  -moz-transition:    -moz-transform 0.1s ease-out;
  -o-transition:      -o-transform 0.1s ease-out;
  -ms-transition:     -ms-transform 0.1s ease-out;
  transition:         transform 0.1s ease-out;

  margin-left: 8px;
  width: 18px;
  height: 18px;
  &.expanded {
    transform: rotate(90deg);
  }
`


export { ExpandableContent };
