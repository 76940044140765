import React, { useState, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router-dom';
import { useFeathers } from '../../app/util';
import {
  SearchableSelectInput
} from '..';
import Button from '@mui/material/Button';

const UpdateCandidateRace = ({
  onSuccess,
  populateOnStart = false,
  candidate,
  redirectOnFinish,
  election,
}) => {
  const feathers = useFeathers();
  const history = useHistory();

  const candidateId = useParams().id;
  const raceId = useParams().raceid;
  const [ formInputs, setFormInputs ] = useState({
    race: null,
  })

  const [submitting, setSubmitting] = useState(false);

  const submittable = formInputs.race && formInputs.race._id !== raceId && !submitting

  useEffect(() => {
    if(feathers && raceId && !formInputs.race && populateOnStart) loadInitialRace()
  }, [feathers, raceId, populateOnStart]);
  
  const createRaceOption = (race) => {
    return {
      text: race.officeName + (race?.party ? ` (${race?.party})` : ''),
      subText: race?.originalDistrict?.longName || race.district?.longName,
      _id: race._id,
      raceObj: race
    }
  }
  
  const loadInitialRace = async () => {
    const race = await feathers.getService('races').get(raceId);

    setFormInputs({ race: createRaceOption(race)})
  }


  const onChange = e => {
    const { name, value } = e.target;
    const update = {};
    update[name] = value;
    let newInputs = {
      ...formInputs,
      ...update
    };
    
    setFormInputs(newInputs)
  }

  /* manage searching options for selection, based on search text */
  const [ optionsForField, setOptionsForField ] = useState({});
  const [loadingForField, setLoadingForField] = useState({});
  
  const searchForField = async (field, searchText, addtlParams) => {
    if(!feathers) return;

    const loadingUpdate = {};
    loadingUpdate[field] = true;
    setLoadingForField({ ...loadingForField, ...loadingUpdate });

    let options = [];
      if (field === 'race') {
      const query = {
        election: election.key,
        $depopulate: ['candidates'],
        $skip: 0,
        $limit: 100,
        ...(searchText
          ? { $or: [ { candidatesSearchTerm: { $search: searchText } }, { officeSearchTerm: { $search: searchText } } ] }
          : {}
        ),
        $sort: { date: -1 }
      }
      const races = await feathers.getService('races').find({ query });
      options = races.data.filter(r => r.office?.district?.type !== 'country').map((r) => (createRaceOption(r)))
    }

    const updateForOptions = {};
    updateForOptions[field] = options;
    setOptionsForField({ ...optionsForField, ...updateForOptions })

    const loadingUpdate2 = {};
    loadingUpdate2[field] = false;
    setLoadingForField({ ...loadingForField, ...loadingUpdate2 });

    return options;
  }

  const submit = async () => {
    if(!submittable || submitting) return;
    setSubmitting(true)
    try {
      const creation = await feathers.getService('candidates').patch(candidateId, {
        race: formInputs.race._id
      })

      if (redirectOnFinish) {
        history.push(`/elections/${formInputs.race.raceObj.election?.key}/races/${formInputs.race._id}/candidates/${candidate._id}`)
      }

      if(onSuccess) onSuccess(creation)
    } catch(e) {
      console.log(e)
      setSubmitting(false)
    }
  }

  const raceRow = () => (
    <Row>
      <div className='label'>Race</div>
      <SearchableSelectInput
        name='race'
        onChangeSearchText={(searchText) => searchForField('race', searchText)}
        value={formInputs.race}
        onChange={onChange}
        optionsLoading={loadingForField.race}
        options={optionsForField?.race || []}
        style={{ flexGrow: 1, width: '100%' }}
      />
    </Row>
  )

  return (
    <Wrapper>
      <Top>
        {raceRow()}
      </Top>
      <Button
        size='small'
        disabled={!submittable || submitting}
        onClick={submit}
        variant='contained'
        color='primary'
        style={{ alignSelf: 'flex-end'}}
      >
        {submitting ? `Updating...` : 'Update'}
      </Button>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`
const Top = styled.div`
  width: 100%;
`;


const Row = styled.div`
  align-items: center;
  margin-right: 4px;
  width: 100%;
  margin-bottom: 12px;

  div.label {
    opacity: 0.7;
    font-size: 14px;
    ${props => props.theme.font.normal}
  }
`;


export { UpdateCandidateRace };
