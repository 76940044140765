import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import {useDropzone} from 'react-dropzone';
import { useFeathers } from '../../app/util';
import Button from '@mui/material/Button';

const axios = require('axios').default

/*
*  This component, when clicked via "ref", will initiate a file upload,
*  upload the file using the uploads service, and ultimately return
*  a full path of the resource uploaded within AWS
*
*  onUploadSuccess - a function called, returning the file that's been uploaded
*  onUploadStart
*  onUploadError
*  fileType – 'image' or 'soundbite'
*/

const FileUpload = ({
  onUploadSuccess,
  fileType,
  style = {}
}) => {
  const hiddenFileInput = useRef();
  const [ isFetching, setIsFetching ] = useState(false);
  const [ error, setError ] = useState(null);
  const feathers = useFeathers();

  const handleClick = event => {
    hiddenFileInput.current.click();
  };

  const onDrop = (inputs) => fileSelected(null, inputs[0])

  const fileSelected = async (event, file) => {
    setIsFetching(true);
    setError(null);

    try {
      const dataForm = new FormData();
      dataForm.append('file', file || event.target.files[0]);

      const { accessToken } = await feathers.get('authentication');

      const url = process.env.NODE_ENV === 'development'
          ? 'http://localhost:3030/api/v1/uploads'
          : 'https://www.branch.vote/api/v1/uploads';
      let urlWithType = url;
      switch(fileType) {
        case 'image':
          urlWithType += '?type=candidatePhoto';
          break;
        case 'soundbite':
          urlWithType += '?type=candidateSoundbite';
          break;
        case 'questionnaire':
          urlWithType += '?type=candidateQuestionnaire';
          break;
        default:
          break;
      }
      const result = await axios.post(urlWithType, dataForm, {
        headers: { 'Authorization' : accessToken }
      });

      onUploadSuccess(result.data.location)
    } catch (err) {
      console.error(err)
      setError(err)
    } finally {
      setIsFetching(false);
    }
  };

  let accept;
  switch(fileType) {
    case 'image':
      accept = '.png,.jpg,.jpeg';
      break;
    case 'soundbite':
      accept = '.m4a,.mp3';
      break;
    case 'questionnaire':
      accept = '.pdf,.doc,.docx';
      break;
    default:
      break;
  }
  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    onDrop,
    accept,
    noClick: true,
    maxFiles: 1
  })

  return (
    <Wrapper className='upload-button-wrapper'
      {...getRootProps()}
      isDragActive={isDragActive ? 1 : 0}
     style={style}
    >
      <Button variant='outlined' color='primary' onClick={handleClick}>
        { isFetching ? 'LOADING...' : 'UPLOAD'}
      </Button>
      <input type="file"
        {...getInputProps()}
        ref={hiddenFileInput}
        onChange={fileSelected}
        style={{ display:'none' }}
        accept={accept}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: calc(100% - 14px * 2);
  padding: 14px;
  border-radius: 8px;

  ${props => props.isDragActive
  ?`
    border: dashed 2px #CCCCCC;
    background-color: #EEEEEE;
  `
  :`
  `}
`

export default FileUpload;
