import React from 'react';
import CalendarIcon from '@mui/icons-material/CalendarToday';
import moment from 'moment';
import Typography from "@mui/material/Typography";
import styled from 'styled-components';

export const DueDate = ({
  date,
  style,
  showTime = true,
  className,
  showColor = true,
  extensionGranted
}) => {
  const hoursUntilDue = moment(date).diff(moment(), 'h');
  let color;
  if(hoursUntilDue < 24) {
    color = '#AA0000'
  } else if(hoursUntilDue < 48) {
    color = '#c69035'
  }

  const stylesInner = {
    ...((color && showColor) ? { color, fontWeight: 'bold' } : {}),
  }

  return (
    <Wrapper>
      <div style={{display: 'flex', alignItems: 'center', gap: '4px', ...(style || {})}} className={className}>
        <CalendarIcon style={stylesInner} color={'primary'}/>
        <Typography variant={'body1'} style={{ marginTop: '4px', ...stylesInner }} color={'primary'}>
          {moment(date).format('MMMM DD')}{showTime ? ` at ${moment(date).format('h:mm A')}` : ''}
        </Typography>
      </div>
      { extensionGranted &&
        <SubText>Extension Granted</SubText>
      }
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const SubText = styled.div`
  opacity: 0.7;
  font-size: 14px;
  font-style: italic;
  ${props => props.theme.font.normal};
  color: ${props => props.theme.colors.darkPurple};
`