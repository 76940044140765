import React, { useState } from 'react'
// background-color: -internal-light-dark(rgb(232, 240, 254), rgba(70, 90, 126, 0.4))
import {
  FormHelperText,
  OutlinedInput,
  FormControl,
  Typography,
  InputAdornment,
  IconButton
} from '@mui/material';
import styled from 'styled-components';
import { CTAButton } from '..';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const  AccountInformation = ({
  heading = '',
  firstName = '',
  lastName = '',
  setFirstName = () => { console.log('setFirstName') },
  setLastName = () => { console.log('setLastName') },
  createAccountFromInvitation = () => { console.log('createAccountFromInvitation') },
  setPassword = () => { console.log('setPassword') },
  setConfirmPassword = () => { console.log('setConfirmPassword') },
  includePassword = false,
  passwordError,
  disabled,
  setPasswordError = () => { console.log('setPasswordError') }
}) => {
  const [ showPassword, setShowPassword ] = useState()
  const [ showConfirmPassword, setShowConfirmPassword ] = useState()
  return (
    (<AccountInformationWrapper>
      <Typography
        variant="h6"
        align="center"
      >
        { heading }
      </Typography>
      <FormWrapper>
        <FormControl>
          <FormHelperText>First Name</FormHelperText>
          <OutlinedInput
            value={firstName}
            onChange={e => setFirstName(e.target.value)}
            color="secondary"
          ></OutlinedInput>
        </FormControl>
        <FormControl>
          <FormHelperText>Last Name</FormHelperText>
          <OutlinedInput
            value={lastName}
            onChange = {e => setLastName(e.target.value)}
          ></OutlinedInput>
        </FormControl>
        { includePassword &&
        <>
          <FormControl>
            <FormHelperText>Password</FormHelperText>
            <OutlinedInput
              label="Confirm Password"
              type={showPassword ? "text" : "password"}
              onChange={(e) => {
                setPassword(e.target.value)
              }}
              error={passwordError}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                    size="large">
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            ></OutlinedInput>
          </FormControl>
          <FormControl>
            <FormHelperText>Confirm Password</FormHelperText>
            <OutlinedInput
              label="Confirm Password"
              onChange={e => {
                setConfirmPassword(e.target.value)
                setPasswordError(false)
              }}
              type={showConfirmPassword ? "text" : "password"}
              error={passwordError}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    edge="end"
                    size="large">
                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              ></OutlinedInput>
            <FormHelperText style={{color: 'red'}}>
              {passwordError ? 'Passwords do not match' : ''}
            </FormHelperText>
          </FormControl>
        </>
        }
      </FormWrapper>
      <CTAButton
        styles={{ marginTop: '20px' }}
        onClick={createAccountFromInvitation}
        disabled={disabled}
      >
        Sign up
      </CTAButton>
    </AccountInformationWrapper>)
  );
}

const FormWrapper = styled.div `
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding-bottom: 2em;
`
const AccountInformationWrapper = styled.div`
  width: 600px;
`

export default AccountInformation
