import React, { useState, useEffect, useMemo } from "react";
import Typography from "@mui/material/Typography";
import {
  TaskPage,
} from "../../components";
import styled from "styled-components";
import { ReportedError } from "../../components/ReportedError";

const ReportedErrors = () => {
  const [task, setTask] = useState(null);
  const [error, setError] = useState(false);
  const [ holdingData, setHoldingData ] = useState(null);

  const onCheckoutTask = async (task) => {
    setTask(task);
    setHoldingData({
      note: '',
      status: null,
      ...(task.holdingData || {})
    });
    setError(false);
  }

  return (
    <Wrapper>
      <TaskPage
        taskType='reported-error'
        errorMessage={error?.message}
        childError={error}
        completionData={holdingData}
        holdingData={holdingData}
        completeButtonLabel={'Resolve'}
        task={task}
        sidePaneConfig={[]}
        allowMultipleCheckedOutTasks={true}
        onCheckoutTask={onCheckoutTask}
      >
        {
          task &&
          <InnerWrapper>
            <ReportedError 
              reportedErrorId={task?.details?.reportedError}
              onChange={setHoldingData}
            />
          </InnerWrapper>
        }
        {
          !task &&
          <Typography variant='body1'>Click a task on the left side pane to get started.</Typography>
        }
      </TaskPage>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%;
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 900px;
  padding-top: 36px;
`


export default ReportedErrors;
