import React, { useState, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router-dom';
import { useFeathers } from '../../app/util';
import {
  SearchableSelectInput
} from '..';
import Button from '@mui/material/Button';
import moment from 'moment';

const UpdateRaceElection = ({
  onSuccess,
  populateOnStart = false,
  race,
  redirectOnFinish,
}) => {
  const feathers = useFeathers();
  const history = useHistory();

  const electionOldKey = race?.election?.key || race?.election;
  const electionOld = race?.election;
  const raceId = race?._id || race;
  const [ formInputs, setFormInputs ] = useState({
    election: null,
  })

  const [submitting, setSubmitting] = useState(false);

  const submittable = formInputs.election && formInputs.election?.key !== electionOld && !submitting;

  useEffect(() => {
    if(feathers && electionOldKey && !formInputs.election && populateOnStart) loadInitialElection()
  }, [feathers, electionOldKey, populateOnStart]);
  
  const electionOptionFromElection = (election) => {
    return {
      text: election.name,
      subText: moment(election.date).format('LL'),
      key: election.key,
      electionObj: election
    }
  }
  
  const loadInitialElection = async () => {
    const election = await feathers.getService('elections').get(electionOldKey);

    setFormInputs({ election: electionOptionFromElection(election)})
  }


  const onChange = e => {
    const { name, value } = e.target;
    const update = {};
    update[name] = value;
    let newInputs = {
      ...formInputs,
      ...update
    };
    
    setFormInputs(newInputs)
  }

  /* manage searching options for selection, based on search text */
  const [ optionsForField, setOptionsForField ] = useState({});
  const [loadingForField, setLoadingForField] = useState({});
  
  const searchForField = async (field, searchText, addtlParams) => {
    if(!feathers) return;

    const loadingUpdate = {};
    loadingUpdate[field] = true;
    setLoadingForField({ ...loadingForField, ...loadingUpdate });

    let options = [];
      if (field === 'election') {
      const query = {
        state: electionOld?.state?._id || electionOld?.state,
        electionType: 'general',
        status: ['prep', 'live'],
        $skip: 0,
        $limit: 19,
        ...(searchText
          ? { name: { $search: searchText } }
          : {}
        ),
        $sort: { date: -1 }
      }
      const elections = await feathers.getService('elections').find({ query });
      options = elections.data.map((r) => (electionOptionFromElection(r)))
    }

    const updateForOptions = {};
    updateForOptions[field] = options;
    setOptionsForField({ ...optionsForField, ...updateForOptions })

    const loadingUpdate2 = {};
    loadingUpdate2[field] = false;
    setLoadingForField({ ...loadingForField, ...loadingUpdate2 });

    return options;
  }

  const submit = async () => {
    if(!submittable || submitting) return;
    setSubmitting(true)
    try {
      const patchOp = await feathers.getService('races').patch(raceId, {
        election: formInputs.election.key
      }, {
        query: {
          migrateRace: true
        }
      })
      console.log('patchOp', patchOp)

      if (redirectOnFinish) {
        history.push(`/elections/${formInputs.election.key}/races/${raceId}`)
      }

      if(onSuccess) onSuccess(patchOp)

      console.log('not implemented yet')
    } catch(e) {
      console.log(e)
      setSubmitting(false)
    }
  }

  return (
    <Wrapper>
      <Top>
        <Row>
          <div className='label'>Election</div>
          <SearchableSelectInput
            name='election'
            onChangeSearchText={(searchText) => searchForField('election', searchText)}
            value={formInputs.election}
            onChange={onChange}
            optionsLoading={loadingForField.election}
            options={optionsForField?.election || []}
            style={{ flexGrow: 1, width: '100%' }}
          />
        </Row>
      </Top>
      <Button
        size='small'
        disabled={!submittable || submitting}
        onClick={submit}
        variant='contained'
        color='primary'
        style={{ alignSelf: 'flex-end'}}
      >
        {submitting ? `Updating...` : 'Update'}
      </Button>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`
const Top = styled.div`
  width: 100%;
`;


const Row = styled.div`
  align-items: center;
  margin-right: 4px;
  width: 100%;
  margin-bottom: 12px;

  div.label {
    opacity: 0.7;
    font-size: 14px;
    ${props => props.theme.font.normal}
  }
`;


export { UpdateRaceElection };
