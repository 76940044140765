import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CancelIcon from '@mui/icons-material/Cancel';
import moment from 'moment';
import { OpenInNew } from '@mui/icons-material';
import { taskConfigurations } from '../../app/util';

const TaskPreviewTile = ({ task }) => {
  return (
	<Tile>
		<Row>
			{
				task.status === 'complete' &&
				<CheckCircleIcon  fontSize='small'style={{ color: 'green' }} />
			}
			{
				(task.status === 'not-started' || task.status === 'in-progress') &&
				<AccessTimeIcon  fontSize='small'/>
			}
			{
				(task.status === 'cancelled') &&
				<CancelIcon fontSize='small' style={{ color: 'red' }} />
			}
			<Typography variant="body1" style={{ flex: 1 }}><b>{taskConfigurations[task.type]?.title ?? task.type}</b></Typography>
			{
				task.status !== 'complete' &&
				<Link to={`/tasks/${task.type}?_id=${task._id}`} style={{ display: 'flex', flexDirection: 'row', textDecoration: 'underline' }}>
					<Typography variant="body2">
						View
					</Typography>
						<OpenInNew style={{ width: '16px', height: '16px' }} />
				</Link>
			}
		</Row>
		{
			task.status === 'complete' &&
			<Row>
				<Typography variant="body2">Completed by {task.completedByUser?.name ?? task.completedByUser?.firstName ?? task.completedByUser?.email} on {moment(task.completedAt).format('MMM Do, h:mma')}</Typography>
			</Row>
		}
	</Tile>
  );
}

const Tile = styled.div`
	display: flex;
	flex-direction: column;
	padding: 10px;
	border: 1px solid #ccc;
	background-color: #FFFFFF;
	text-decoration: none;
	color: black;
	border-radius: 5px;
	transition: all 0.3s ease;
	box-shadow: 0 0 10px rgba(0,0,0,0.1);
	gap: 4px;
	text-decoration: none;
`;

const Row = styled.div`
	display: flex;
	gap: 8px;
	align-items: center;
	text-decoration: none;
`;

export { TaskPreviewTile };