import React, { useState, useMemo, useEffect } from 'react';
import { useFeathers, isPermitted } from '../../app/util';
import {
  DataField,
  FieldInlineEdit,
  LoadingSpinner,
  DistrictSelectInput
} from '../index';
import InputList from '../InputList';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { useSelector } from 'react-redux';
import { Link, Tooltip, Typography } from '@mui/material';
import styled from 'styled-components';
import superagent from 'superagent';
import { EmailOutlined } from '@mui/icons-material';

const MeasureDataFieldEditor = ({
  measureId,
  onError,
  style,
  className
}) => {
  const feathers = useFeathers();
  const user = useSelector(state => state.user);
  const elections = useSelector(state => state.elections.byKey);
  const [ measureData, setMeasureData ] = useState(null);

  const electionKey = measureData?.election?.key || measureData?.election;
  const electionData = electionKey ? elections[electionKey] : null;
  const electionOver = electionData?.status === 'results-certified';
  const stateCode = electionData?.stateCode ? electionData.stateCode.toLowerCase() : null;

  const descendantOf = useMemo(
    () => electionData?.districts?.map((d) => d._id),
    [electionData]
  )

  const [ saving, setSaving ] = useState(false);
  const [ loading, setLoading ] = useState(true);

  const [ districtTypeConfig, setDistrictTypeConfig ] = useState(null);

  useEffect(() => {
    if(feathers && stateCode) fetchDistrictTypes()
  }, [ stateCode, feathers ])

  const fetchDistrictTypes = async () => {
    // use superagent to request 
    try {
      const response = await superagent.get('https://districts-microservice.herokuapp.com/api/v1/district-configurations/'+ stateCode);
      const { body } = response;
      setDistrictTypeConfig(body);
    } catch(err) {
      console.log(err)
    }
  }

  const editsMadeBy = useMemo(() => {
    const drafts = measureData?.previousDrafts || [];
    const usersUnique = drafts.map(d => d?.user).filter(Boolean).reduce((acc, cur) => {
      acc[cur?._id || cur] = cur;
      return acc;
    }, {});
    return Object.values(usersUnique).map(u => ({
      display: u === 'profiler' ? 'Profiler' : u?.name || u?.firstName || u?.email,
      email: u?.email
    }));
  }, [measureData])


  const [ editingDistrict, setEditingDistrict ] = useState(false);
  const [ newDistrictInput, setNewDistrictInput ] = useState(null);
  
  const [ editingCoveragePlan, setEditingCoveragePlan ] = useState(false);
  const [ coveragePlan, setCoveragePlan ] = useState(null);
  const saveCoveragePlan = async () => {
    try {
      const coveragePlanModified = coveragePlan === 'coverage' ? 'future-coverage' : 'no-coverage';
      const updateRes = await feathers.getService('measures').patch(measureId, {
        coverageStatus: coveragePlanModified,
      })
      setMeasureData(updateRes);
      setEditingCoveragePlan(false)
    } catch(err) {
      console.log(err)
    }
  }

  const [ entityMatchInputs, setEntityMatchInputs ] = useState([]);
  const [ editingEntityMatchInputs, setEditingEntityMatchInputs ] = useState(false);
  const saveEntityMatchInputs = async () => {
    const updateRes = await feathers.getService('races').patch(measureId, {
      entityMatchInputs: entityMatchInputs,
    })
    setMeasureData(updateRes);
    setEditingEntityMatchInputs(false)
    loadDataForMeasure()
  }

  const appearsOnBallotAs = useMemo(() => {
    if (!measureData) return ''
    const { entityMatchInputs } = measureData;
    if (!entityMatchInputs?.length) {
      return ''
    } else if (entityMatchInputs.length === 1) {
      return `${entityMatchInputs[0]}`
    } else {
      return entityMatchInputs.slice(0, -1).join(', ') + ' or ' + entityMatchInputs.slice(-1)
    }
  }, [measureData])

  const onChangeDistrict = (e) => setNewDistrictInput(e.target.value)
  const saveDistrict = async () => {
    // only save the inputs being edited
    const requestBody = {}
    if (newDistrictInput && newDistrictInput._id) {
      requestBody.district = newDistrictInput._id
      cancelDistrict()
    } else {
      return
    }

    try {
      const updateRes = await feathers.getService('measures').patch(measureId, requestBody)
      setMeasureData(updateRes);
      setEditingDistrict(false)
      loadDataForMeasure()
    } catch(err) {
      console.log(err)
    }
  }

  const cancelDistrict = () => {
    setEditingDistrict(false)
    setNewDistrictInput(null)
  }


  const loadDataForMeasure = async () => {
    setLoading(true)
    setMeasureData(null);
    try {
      const race = await feathers.getService('measures').get(measureId);
      setMeasureData(race);
      setLoading(false);
    } catch (err) {
      console.log(`Error in retrieving measure: `, err)
    } finally {
    }
  }

  const getNewDistrictInput = useMemo(() => {
    if (measureData?.originalDistrict) {
      return { text: measureData?.originalDistrict?.longName, ...measureData?.originalDistrict }
    }
    return { text: measureData?.district?.longName, ...measureData?.district }
    }, [measureData?.originalDistrict, measureData?.district])

  useEffect(() => {
    if(feathers) {
      loadDataForMeasure()
    }
  }, [ feathers, measureId ])

  if(loading) {
    return <Wrapper>
      <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center', padding: '36px'}}>
        <LoadingSpinner />
      </div>
    </Wrapper>
  }

  const linkToMeasure = window.location.href;

  return (
    <Wrapper>
      <Section>
        <Typography variant='h3'>Ballot display</Typography>
        <div className='seperator' />
        {
          editingDistrict
          ?
            <FieldInlineEdit
              inputComponent={
                <DistrictSelectInput
                  onChange={onChangeDistrict}
                  value={newDistrictInput}
                  districtTypes={districtTypeConfig?.measureTypes}
                  style={{ width: 'calc(100% - 15px)' }}
                  descendantOf={descendantOf}
                />
              }
              onCancel={cancelDistrict}
              onSave={saveDistrict}
              label='District'
            />
          :
            <DataField
              title='District'
              onEdit={
                isPermitted(user, electionOver ? ['super-admin', 'admin', 'editor'] : ['editor', 'super-admin', 'researcher', 'admin', 'reviewer', 'publisher'])
                ?
                  () => {
                    setNewDistrictInput(getNewDistrictInput)
                    setEditingDistrict(true)
                  }
                : null
              }
            >
              <div>
                <Typography variant={'body1'}>
                  { measureData.originalDistrict?.longName || measureData.district?.longName}
                </Typography>
                <Typography variant={'body2'} style={{ opacity: 0.7 }}>
                  Population: { measureData.district?.population ? `${measureData.district.population.toLocaleString()}` : '--'}
                </Typography>
              </div>
            </DataField>
        }
        {
          editingCoveragePlan
          ?
            <FieldInlineEdit
              inputComponent={
                <Select
                  value={coveragePlan}
                  onChange={(e) => setCoveragePlan(e.target.value)}
                  style={{ width: '100%' }}
                >
                  <MenuItem value='coverage'>Will cover</MenuItem>
                  <MenuItem value='no-coverage'>Won't cover</MenuItem>
                </Select>
              }
              onSave={saveCoveragePlan}
              label='Coverage plan'
            />
          :
            <DataField
              title='Coverage plan'
              onEdit={
                isPermitted(user, electionOver ? ['super-admin', 'admin', 'editor'] : ['editor', 'super-admin', 'researcher', 'admin', 'reviewer', 'publisher'])
                ?
                  () => {
                    const coveragePlanModified = measureData?.coverageStatus === 'no-coverage' ? 'no-coverage' : 'coverage';
                    setEditingCoveragePlan(coveragePlanModified)
                  }
                : null
              }
            >
              <div>
                <Typography variant={'body1'}>
                  { measureData?.coverageStatus === 'no-coverage' ? `Won't cover` : 'Will cover'}
                </Typography>
              </div>
            </DataField>
        }
        {
          editingEntityMatchInputs
          ?
            <FieldInlineEdit
              inputComponent={
              <InputList
                value={entityMatchInputs}
                name={'entityMatchInputs'}
                renderItem={(_item, i, ) => (
                <TextField
                  value={entityMatchInputs[i]}
                  onChange={(e) => {
                    const newInputs = entityMatchInputs.slice()
                    newInputs[i] = e.target.value
                    setEntityMatchInputs(newInputs)
                  }}
                  variant='outlined'
                  size='small'
                  style={{margin: '2px 0px', width: 'calc(100% - 16px)'}}
                />
              )}
               onChange={(e) =>{
                 setEntityMatchInputs(e.value || [])
                }}
                onAdd = {e => setEntityMatchInputs(em => [...em, ''])}
               />
              }
              onSave={saveEntityMatchInputs}
              label='Appears on ballot as'
            />
          :
            <DataField
              onEdit={
                isPermitted(user, electionOver ? ['super-admin', 'admin', 'editor'] : ['editor', 'super-admin', 'researcher', 'admin', 'reviewer', 'publisher'])
                ?
                  () => {
                    setEntityMatchInputs(measureData?.entityMatchInputs || [])
                    setEditingEntityMatchInputs(true)
                  }
                : null
              }
              title='Appears on ballot as'
            >
              <div style={{ display: 'flex', flexDirection: 'column', gap: '8px', paddingTop: '4px'}}>
                {
                  (measureData?.entityMatchInputs || [])?.length > 0 && measureData?.entityMatchInputs.map((input, i) => (
                    <Typography variant={'body1'} style={{ lineHeight: '1.1'}}>
                      {input}
                    </Typography>
                  ))
                }
                {
                  (measureData?.entityMatchInputs || [])?.length === 0 && (
                    <Typography variant={'body1'}>
                      --
                    </Typography>
                  )
                }
              </div>
            </DataField>
        }
      </Section>
      <Section>
        <Typography variant='h3'>Edit history</Typography>
        <div className='seperator' />
        <DataField title='Edited by'>
          {
            editsMadeBy.length > 0
            ? <div style={{ display: 'flex', flexDirection: 'column', gap: '4px'}}>
              {editsMadeBy.map((u, i) => (
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', content: 'space-between', gap: '4px', marginBottom: '8px' }}>
                    <Typography key={i} variant='body1' style={{}}>{u?.display}</Typography>
                    <Link href={`mailto:${u?.email}?subject=${encodeURIComponent(`Feedback on referendum: ${measureData?.title}`)}&body=${encodeURIComponent(`Here is some feedback on your ballot referendum summary for ${measureData?.title} in ${measureData?.district?.longName}: ${linkToMeasure}`)}`} style={{ fontSize: '12px', opacity: 0.7 }}>
                      <EmailOutlined style={{ fontSize: '18px', marginBottom: '-3px' }} />
                    </Link>
                  </div>
                ))}
            </div>
            : <Typography variant='body1' style={{}}>--</Typography>
          }
        </DataField>
        <DataField title='Versions'>
          <Typography variant='body1' style={{}}>{measureData?.previousDrafts?.length ?? '--'} versions</Typography>
        </DataField>
      </Section>
      <Section>
        <Typography variant='h3'>Analytics</Typography>
        <div className='seperator' />
        <DataField title='Expected readers'>
          <Typography variant='body1'>{measureData?.expectedReaders ? `${Math.round(measureData.expectedReaders).toLocaleString()}` : '--'}</Typography>
        </DataField>
        <DataField title='Number of readers'>
          <Typography variant='body1'>{typeof(measureData?.analytics?.views) !== 'undefined' ? `${Math.round(measureData.analytics?.views).toLocaleString()}` : '--'}</Typography>
        </DataField>
        <DataField title='Number of users'>
          <Tooltip title='The number of users that have this race on their ballot'>
            <Typography variant='body1'>{typeof(measureData?.analytics?.onBallot) !== 'undefined' ? `${Math.round(measureData.analytics?.onBallot).toLocaleString()}` : '--'}</Typography>
          </Tooltip>
        </DataField>
      </Section>
    </Wrapper>
  )
}


const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 48px;
`

const SyncContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  svg {
    width: 34px;
    margin-right: 6px;
  }
`

const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding-left: 16px;
  gap: 20px;

  h3 {
    margin-left: -16px;
    margin-bottom: -8px;
    font-size: 16px;
  }

  .seperator {
    height: 1px;
    width: 100%;
    background-color: #DDDDDD;
    margin: 0px 0 0px -16px;
  }
`

export { MeasureDataFieldEditor };