import React, { useState } from "react";
import { FieldInlineEdit } from "../FieldInlineEdit";
import { DataField } from "../DataField";
import { Snackbar } from "@mui/material";
import { Alert } from '@mui/material';
import { useFeathers } from "../../app/util";
import Typography from "@mui/material/Typography";
import moment from 'moment';

/* Used for manually editing the votes for a candidate, measure, or race (write-in votes for a race) */

const ManualVoteTally = ({
	candidateId,
	raceId,
	resultManual,
	writeInVotes,
	showDataFieldName = true,
	onSave,
	disableSaving = false
}) => {
	const feathers = useFeathers();
	const [ editingManualResults, setEditingManualResults ] = useState(false);
  const [ manualResultsSaving, setManualResultsSaving ] = useState(false);
	const [ error, setError ] = useState(null);

	const updatedAtRef = raceId ? null : resultManual?.updatedAt;
	const voteCountRef = raceId ? writeInVotes : resultManual?.totalVotes;
	
	const saveManualResults = async (val) => {
    if(manualResultsSaving) return;

		const valsMapped = val.replaceAll(',', '').split('+').map(v => v.trim()).filter(Boolean).map(v => parseInt(v));
    const valMapped = valsMapped.reduce((acc, val) => acc + val, 0);

    setManualResultsSaving(true)
    try {
			let res;
			if(disableSaving) {
				setEditingManualResults(false)
				onSave(valMapped)
			}
			else {
				if(candidateId) {
					res = await feathers.getService('election-results').patch(candidateId, {
						manual: { totalVotes: valMapped }
					})
				} else if(raceId) {
					res = await feathers.getService('races').patch(raceId, { writeInVotes: valMapped })
				}
				setEditingManualResults(false)
				if(onSave) onSave(res)
			}
    } catch(err) {
      console.log(err)
      setError(err)
    } finally {
      setManualResultsSaving(false)
    }
  }

	if(editingManualResults) {
		return (
			<>
				<FieldInlineEdit
					loading={manualResultsSaving}
					seedValue={voteCountRef ? `${voteCountRef}` : ''}
					style={{ width: 'calc(100% )'}}
					onCancel={() => setEditingManualResults(false)}
					onSave={saveManualResults}
					label='Manual votes'
				/>
				<Snackbar
					open={error != null}
					anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
					autoHideDuration={ 6000 }
					style={{position: 'absolute'}}
					onClose={() => setError(undefined)}
				>
					<Alert
						severity="error"
						onClose={() => setError(undefined)}
						elevation={6}
						variant="filled"
					>
						{error?.message}
					</Alert>
				</Snackbar>
			</>
		)
			
	}
	
	return (
		<DataField
			title={showDataFieldName ? 
				(raceId ? 'Write-in votes' : 'Manually entered votes')
				: ''
			}
			onEdit={() => setEditingManualResults(true)}
		>
			{
				typeof(voteCountRef) !== 'number'
					? <Typography variant='body1' style={{ fontSize: '14px' }}>No {raceId ? 'write-in' : 'manually entered'} votes</Typography>
					: (
						<div>
							<Typography variant='body1' style={{ fontSize: '14px' }}>{voteCountRef.toLocaleString()} votes</Typography>
							{updatedAtRef && <Typography variant='body1' style={{ fontSize: '12px' }}>Updated {moment.utc(updatedAtRef).fromNow()}</Typography>}
						</div>
					)
			}
		</DataField>
	);
}

export { ManualVoteTally };