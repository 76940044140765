import React, { useState, useEffect, useMemo } from "react";
import Typography from "@mui/material/Typography";
import { useFeathers, loadPaginatedFrontend } from "../../app/util";
import TextField from '@mui/material/TextField';
import {
  Modal,
  RaceCandidatePreviewTile,
  MeasurePreviewTile,
  CreateRaceModal,
  ChipMultiSelectMenu,
  HintTile
} from "../../components";
import styled from "styled-components";
import Button from "@mui/material/Button";
import { useFindIntersectingDistrictsFull  } from "../../app/hooks/useFindIntersectingDistrictsFull";
import { CheckCircle, OpenInNew as OpenInNewIcon } from "@mui/icons-material";
import { Checkbox, IconButton, Link, Tooltip } from "@mui/material";
import { Skeleton } from '@mui/material';
import AccordionDetails from '@mui/material/AccordionDetails';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Add from "@mui/icons-material/Add";
import Remove from "@mui/icons-material/Remove";
import moment from "moment";
import { CreateMeasureModal } from "../CreateMeasureModal";

const measuresEnabledForDistricts = ['city', 'county', 'state']

const ScoutRacesForArea = ({
	primaryDistrictId,
  style,
  className,
  onChangeContentTypes,
	election,
	onChangeCompletionData,
	disabled,
  requireContentType,
  allowDirectEdits = false
}) => {
	const feathers = useFeathers();
  const [error, setError] = useState(false);

	/* our backbone data */
  const [ electionFull, setElectionFull ] = useState(null);
  const [ electionImplementationFull, setElectionImplementationFull ] = useState(null);   // this is used to let us know if measure scouting is even enabled yet
  const [ primaryScoutingDistrict, setPrimaryScoutingDistrict ] = useState(null);
  const [ district, setDistrict ] = useState(null);
	const [ districtDescendants, setDistrictDescendants ] = useState([])
  const [ additionalDistricts, setAdditionalDistricts ] = useState([])
  const [ additionalDistrictsDescendants, setAdditionalDistrictsDescendants ] = useState({}) // format is { parentId: [districtDescendants] }
  const [ sectionsExpanded, setSectionsExpanded ] = useState({})
  const [ implementationDistrictRace, setImplementationDistrictRace ] = useState(null)        // the implementation district of the districtId passed into the component
  const [ implementationDistrictMeasure, setImplementationDistrictMeasure ] = useState(null)  // the implementation district of the districtId passed into the component

	const { districts: intersectingDistrictsOptions } = useFindIntersectingDistrictsFull(district, { type: ['city', 'county']  });
	
	/* Our input data, updated each time we edit the page */
  const [ measureScoutingEnabled, setMeasureScoutingEnabled ] = useState({});
  
  useEffect(() => {
    const enabledStatus = () => {
      const measureScoutingStartDate = electionImplementationFull?.measureScoutingStartDate;
      if(!measureScoutingStartDate) return { enabled: false , reason: 'Measure scouting has not started yet for this election' };
      if(new Date(measureScoutingStartDate) > new Date()) return { enabled: false , reason: 'Measure scouting has not started yet for this election' };

      // now also check that our primary scouting district includes measures
      if(!district) return { enabled: false, reason: 'No primary scouting district selected' };
      if(!measuresEnabledForDistricts.includes(district?.type)) return { enabled: false, reason: `Referendum scouting not enabled for district type ${district?.type}` };

      if(!implementationDistrictMeasure) return { enabled: false, reason: `Referendum scouting not found for this district` };
      return { enabled: true, reason: '' };
    }
    const enabled = enabledStatus();
    setMeasureScoutingEnabled(enabled)

    if(enabled.enabled) {
      setContentTypes(ct => ct.includes('measures') ? ct : [...ct, 'measures'])
    } else {
      setContentTypes(ct => ct.filter(c => c !== 'measures'))
    }
  }, [ electionImplementationFull, district, implementationDistrictMeasure ])

  const [ contentTypes, setContentTypes ] = useState([ 'races' ])
  const [ possibleDistrictTypes, setPossibleDistrictTypes ] = useState({}) // form { dId: [ districtTypes ]}
  const [ source, setSource ] = useState('');
  const [ races, setRaces ] = useState([]);
  const [ additionalRaces, setAdditionalRaces ] = useState({}) // format is { tier2Id: [races]}
  const [ measures, setMeasures ] = useState([]);
  const [ additionalMeasures, setAdditionalMeasures ] = useState({}) // format is { tier2Id: [measures]}

  useEffect(() => {
    if(onChangeContentTypes) onChangeContentTypes(contentTypes)
  }, [contentTypes])

  const [ queueLoadRaces, setQueueLoadRaces ] = useState(false);
  const [ addRaceModalOpen, setAddRaceModalOpen ] = useState(null); // null or district (full)
  const [ addMeasureModalOpen, setAddMeasureModalOpen ] = useState(null); // null or district (full)
  const [ sourceWasEdited, setSourceWasEdited ] = useState(false);
  const [ additionalDistrictsEdited, setAdditionalDistrictsEdited ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const [ savingUpdates, setSavingUpdates ] = useState(false);

  const isPrimaryDistrict = primaryScoutingDistrict === district?._id;

	/* Upon initial loading, load all of our backbone data */
  const loadData = async () => {
    setLoading(true)
    setAdditionalDistricts([])
    setSource('')
    setSourceWasEdited(false)
    setPrimaryScoutingDistrict(null)
    setAdditionalDistrictsEdited(false)
    await loadElection(election);
    await loadStartingData(election, primaryDistrictId);
    setQueueLoadRaces(true)
  }

	const loadElection = async (election) => {
    const electionFull = await feathers
      .getService("elections")
      .get(election);
    setElectionFull(electionFull);

    const electionImplementationFull = await feathers
      .getService("election-implementations")
      .get(electionFull?.key);
    setElectionImplementationFull(electionImplementationFull);
  };

  const loadStartingData = async (electionKey, district) => {
    const primaryDistrictData = await loadDistricts([district])

    // set the full district data for the district passed in
    setDistrict(primaryDistrictData.districts[0])
    setDistrictDescendants(Object.values(primaryDistrictData.districtDescendants)[0])
    setPossibleDistrictTypes(pdt => ({ ...pdt, ...primaryDistrictData.possibleDistrictTypes }))

    const primaryDistrictStatusOptions = await feathers.getService('implementation-districts').find({
      query: {
        election: electionKey,
        active: true,
        district: district,
        contentType: ['races', 'measures'],
        $limit: 200,
        $includeInternalFields: true
      },
    });

    const primaryDistrictStatusRace = primaryDistrictStatusOptions.data.find(ds => ds.district === district && ds.contentType === 'races');

    if (!primaryDistrictStatusRace) {
      throw new Error('No district status found for primary district')
    }

    if (primaryDistrictStatusRace.source) {
      setSource(primaryDistrictStatusRace.source)
    }

    setImplementationDistrictRace(primaryDistrictStatusRace)

    // set the scouted with districts
    if(primaryDistrictStatusRace.scoutedWithDistricts?.length > 0) {
      const additionalIds = primaryDistrictStatusRace.scoutedWithDistricts.filter(d => d !== district)
      console.log('loading additional districts', additionalIds)
      const additionalDistricts = await loadDistricts(additionalIds)

      console.log('additionalDistrictsretruend', additionalDistricts)
      setAdditionalDistricts(additionalDistricts.districts)
      setAdditionalDistrictsDescendants(additionalDistricts.districtDescendants)
      setPossibleDistrictTypes(pdt => ({ ...pdt, ...additionalDistricts.possibleDistrictTypes }))

      if(primaryDistrictStatusRace.isPrimaryDistrict) {
        setPrimaryScoutingDistrict(district)
      } else {
        const otherImplementationDistricts = await feathers.getService('implementation-districts').find({
          query: {
            election: electionKey,
            active: true,
            district: additionalIds,
            contentType: ['races', 'measures'],
            $limit: additionalDistricts.length,
            $includeInternalFields: true
          },
        });

        const primaryDistrict = otherImplementationDistricts.data.find(d => d.isPrimaryDistrict)
        if (primaryDistrict) {
          setPrimaryScoutingDistrict(primaryDistrict.district)
        } else {
          throw new Error('No primary district found in additional districts')
        }
      }
    } else {
      setPrimaryScoutingDistrict(district)
    }

    setImplementationDistrictMeasure(primaryDistrictStatusOptions.data.find(ds => ds.district === district && ds.contentType === 'measures'))
  }

  const loadDistricts = async (districtIds) => {
    // this function takes a district id and loads it and it's descendants and possible district types
    if (!feathers) return

    const districtsFull = (await feathers
      .getService("districts")
      .find({
        query: {
          _id: districtIds,
          parentDepth: 5,                 // figure out how to bring back district names as well
          $includeIntersectsWith: true,
          $limit: districtIds.length
        },
      })).data;

    // for the edge case of a state -- only allow research done at the state level , because there are too many descendants and the research for the counties should be done by county , not at the state level
    const stateDistrict = districtsFull.find(d => d.type === 'state')
    if (stateDistrict) {
      return {
        districts: districtsFull,
        districtDescendants: [],
        possibleDistrictTypes: { [stateDistrict._id]: 'state' }
      }
    }

    const descendantOfQuery =  {
      parentId: districtsFull.map(d => d._id)
    }

    const districtDescendants = await loadPaginatedFrontend('districts', descendantOfQuery, feathers, 50);

    const aggregatedUpdates = districtsFull.reduce((acc, d) => {
      const descendantsForDistrict = districtDescendants.filter(dd => dd.parentId === d._id)
      acc.districtDescendants[d._id] = descendantsForDistrict;
      const possibleTypes = [
        ...new Set([
          ...descendantsForDistrict.map(dd => dd.type),
          d.type
        ])
      ]
      acc.possibleDistrictTypes[d._id] = possibleTypes;
      return acc;
    }, { districtDescendants: {}, possibleDistrictTypes: {} })

    return {
      districts: districtsFull,
      districtDescendants: aggregatedUpdates.districtDescendants,
      possibleDistrictTypes:  aggregatedUpdates.possibleDistrictTypes
    }
  };

  const loadRaces = async () => {
    await loadExistingRaces()
    await loadAdditionalRaces()    
    setLoading(false)
  }

  const loadMeasures = async () => {
    await loadExistingMeasures()
    await loadAdditionalMeasures()
  }

  const loadExistingRaces = async () => {
    if (!primaryDistrictId || !election) return;
    const racesQuery = {
      election: election,
      district: [
        ...((districtDescendants || [])?.map((d) => d._id)),
        primaryDistrictId,
      ],
      $includeParentDistrict: true,
      $sort: { createdAt: 1 }
    }
    const numRacesInDb = await feathers.getService("races").find({
      query: {
        ...racesQuery,
        $limit: 0
      },
    });

    const chunkSize = 20;
    const numChunks = Math.ceil(numRacesInDb.total / chunkSize);

    const racesInDb = await Promise.all(
      Array.from({ length: numChunks }).map((_, i) => {
        return feathers.getService("races").find({
          query: {
            ...racesQuery,
            $limit: chunkSize,
            $skip: i * chunkSize
          },
        });
      })
    )

    setRaces(racesInDb.map(g => g.data).flat());
  }

  const loadExistingMeasures = async () => {
    if (!primaryDistrictId || !election) return;
    const measuresQuery = {
      election: election,
      district: [
        ...((districtDescendants || [])?.map((d) => d._id)),
        primaryDistrictId,
      ],
      $includeParentDistrict: true,
      $sort: { createdAt: 1 }
    }
    const numMeasuresInDb = await feathers.getService("measures").find({
      query: {
        ...measuresQuery,
        $limit: 0
      },
    });

    const chunkSize = 20;
    const numChunks = Math.ceil(numMeasuresInDb.total / chunkSize);

    const measuresInDn = await Promise.all(
      Array.from({ length: numChunks }).map((_, i) => {
        return feathers.getService("measures").find({
          query: {
            ...measuresQuery,
            $limit: chunkSize,
            $skip: i * chunkSize
          },
        });
      })
    )

    setMeasures(measuresInDn.map(g => g.data).flat());
  }

  const loadAdditionalRaces = async () => {
    if (!additionalDistricts.length) {
      setAdditionalRaces({})
      return
    }
    const additionalDistrictsIds = additionalDistricts.map(d => d._id)
    const additionalDistrictDescendantsIds = Object.values(additionalDistrictsDescendants).flat().map(d => d._id)

    const additionalRacesQuery ={
        district: [
          ...additionalDistrictsIds,
        ],
        election,
        $includeParentDistrict: true,
    }

    const additionalRaces = await loadPaginatedFrontend('races', additionalRacesQuery, feathers)
    const newAdditionalRaces = additionalRaces.reduce((acc, r) => {
      if (!acc[r.district._id]) {
        acc[r.district._id] = []
      }
      acc[r.district._id].push(r)
      return acc
    }, {})

    const additionalDescendantRacesQuery =  {
      district: [
        ...additionalDistrictDescendantsIds,
      ],
      election,
      $includeParentDistrict: true
    }

    const additionalDescendantRaces = await loadPaginatedFrontend('races', additionalDescendantRacesQuery, feathers)

    const newAdditionalDescendantRaces = additionalDescendantRaces.reduce((acc, r) => {
      if (!acc[r.district.parentId]) {
        acc[r.district.parentId] = []
      }
      acc[r.district.parentId].push(r)
      return acc
    }, newAdditionalRaces)

    setAdditionalRaces(newAdditionalDescendantRaces)
  }

  const loadAdditionalMeasures = async () => {
    if (!additionalDistricts.length) {
      setAdditionalMeasures({})
      return
    }
    const additionalDistrictsIds = additionalDistricts.map(d => d._id)
    const additionalDistrictDescendantsIds = Object.values(additionalDistrictsDescendants).flat().map(d => d._id)

    const additionalMeasuresQuery ={
      district: [
        ...additionalDistrictsIds,
      ],
      election,
      $includeParentDistrict: true,
    }

    const additionalMeasures = await loadPaginatedFrontend('measures', additionalMeasuresQuery, feathers)
    const newAdditionalMeasures = additionalMeasures.reduce((acc, r) => {
      if (!acc[r.district._id]) {
        acc[r.district._id] = []
      }
      acc[r.district._id].push(r)
      return acc
    }, {})

    const additionalDescendantMeasureQuery =  {
      district: [
        ...additionalDistrictDescendantsIds,
      ],
      election,
      $includeParentDistrict: true
    }

    const additionalDescendantMeasures = await loadPaginatedFrontend('measures', additionalDescendantMeasureQuery, feathers)

    const newAdditionalDescendantMeasures = additionalDescendantMeasures.reduce((acc, r) => {
      if (!acc[r.district.parentId]) {
        acc[r.district.parentId] = []
      }
      acc[r.district.parentId].push(r)
      return acc
    }, newAdditionalMeasures)

    setAdditionalMeasures(newAdditionalDescendantMeasures)
  }

	useEffect(() => {
		if(feathers && primaryDistrictId && election) loadData()
	}, [feathers, primaryDistrictId, election ])


  useEffect(() => {
    if (!feathers) return
    if(queueLoadRaces) {
      setQueueLoadRaces(false)
      loadRaces()
      loadMeasures()
    }
  }, [queueLoadRaces])
	/* End backbone data */
	
	/* managing all of our input data */
	const allDistricts = useMemo(() => {
    return [district, ...additionalDistricts].filter(d => d)
  }, [district, additionalDistricts])

  const allRaces = useMemo(() => {
    if (!district) return {}
    return { ...additionalRaces, [district._id]: races }
  }, [races, additionalRaces])

  const allMeasures = useMemo(() => {
    if(!district) return {};
    return { ...additionalMeasures, [district._id]: measures }
  }, [ measures, additionalMeasures ])

  const sourceIdentified = useMemo(() => source?.length > 7, [source]);

  const removeCandidateFromEditRaces = async (candidateId, d) => {
    try {
      await feathers.getService('candidates').remove(candidateId)
      if (d._id === district._id) {
      await loadExistingRaces()
      } else {
        await loadAdditionalRaces()
      }
    } catch (err) {
      setError(err)
    }
  };

  useEffect(() => {
    if(loading) return;
    if(onChangeCompletionData) onChangeCompletionData({ source, additionalDistricts: additionalDistricts.map(d => d._id ?? d) })
  }, [source, additionalDistricts, loading ])

  const onRaceCreated = (race) => {
    if (addRaceModalOpen._id === district._id) {
      setRaces(er => [...er, race])
    } else {
      const raceGroup = additionalRaces[addRaceModalOpen._id] || []
      const updatedRaces = [ ...raceGroup, race]
      setAdditionalRaces(er => ({ ...er, [addRaceModalOpen._id]: updatedRaces }))
    }
    setAddRaceModalOpen(null);
  }

  const onMeasureCreated = (measure) => {
    if (addMeasureModalOpen._id === district._id) {
      setMeasures(er => [...er, measure])
    } else {
      const measureGroup = additionalMeasures[addMeasureModalOpen._id] || []
      const updatedMeasures = [ ...measureGroup, measure]
      setAdditionalMeasures(er => ({ ...er, [addMeasureModalOpen._id]: updatedMeasures }))
    }
    setAddMeasureModalOpen(null);
  }

  const onAddCandidate = async (candidate, currentDistrict) => {
    const raceIndex = allRaces[currentDistrict._id].findIndex(r => r._id === (candidate?.race?._id || candidate?.race));

    const currentRace = allRaces[currentDistrict._id][raceIndex]
    const raceNew = {
      ...currentRace,
      candidates: [
        ...(currentRace.candidates || []),
        candidate
      ]
    };

    const mainDistrict = currentDistrict._id === district._id
    if (mainDistrict) {
      setRaces(existingRaces => {
        const newRaces = [...existingRaces];
        newRaces[raceIndex] = raceNew;
        return newRaces;
      })
    } else {
      const raceGroup = [...(additionalRaces[currentDistrict._id] || [])]
      raceGroup[raceIndex] = raceNew
      setAdditionalRaces(er => ({ ...er, [currentDistrict._id]: raceGroup }))
    }

    loadExistingRaces()
  }
	/* End manage all of our input data */
  const saveUpdates = async (saveSource, saveScoutedWith) => {
    const additionalDistrictIds = additionalDistricts.map(d => d._id || d)
    const districtIdsToUpdate = [primaryDistrictId, ...(additionalDistrictIds || [])];
    setSavingUpdates(true)

    const allUpdates = await Promise.all(districtIdsToUpdate.map(async (districtId) => {
      const implementationDistrictOptions = await feathers.getService('implementation-districts').find({
        query: {
          district: districtId,
          election,
          active: true,
          contentType: 'races',
          $includeInternalFields: true
        }
      });
  
      if(implementationDistrictOptions.total !== 1) {
        throw new Error(`No (or multiple) implementation district found for district: ${districtId} and election: ${election}`, { implementationDistrictOptions })
      } else {
        const implementationDistrictBeforeUpdate = implementationDistrictOptions.data[0];
        const otherDistricts = districtIdsToUpdate.filter(id => `${id}` !== `${districtId}`);
  
        const dataToPatch = {
          ...(saveSource ? { source } : {}),
          ...(
            saveScoutedWith ? {
              scoutedWithDistricts: otherDistricts,
              isPrimaryDistrict: `${districtId}` === `${primaryDistrictId}`,
            } : {}
          )
        };
  
        const updated = await feathers
          .getService('implementation-districts')
          .patch(implementationDistrictBeforeUpdate._id,
            dataToPatch,
            {
              query: { $includeInternalFields: true }
            }
          );
        return { beforeUpdate: implementationDistrictBeforeUpdate, afterUpdate: updated };
      }
    }))

    if(saveSource) {
      setSourceWasEdited(false)
    }
    
    console.log(`Updated ${allUpdates.length} districts`);
    if(!saveScoutedWith) {
      setSavingUpdates(false)
      return;
    }
    
    // we need to also onsider the case of removing a district. 
    // if a district was previously a part of this scoutedWithDistricts array, but is no longer,
    // it won't show up in the list.
    // we look at each implementationDistrictBeforeUpdate and use that to make the determination of 
    // what needs to be updated
    const allScoutedWithBeforeUpdate = [...new Set(allUpdates.map(d => d.beforeUpdate.scoutedWithDistricts).flat().map(d => `${d}`))];
    const allScoutedWithRemoved = allScoutedWithBeforeUpdate.filter(d => !districtIdsToUpdate.includes(`${d}`));
  
    const updatesSecondary = await Promise.all(allScoutedWithRemoved.map(async (districtId) => {
      const implementationDistrictOptions = await feathers.getService('implementation-districts').find({
        query: {
          district: districtId,
          election,
          active: true,
          contentType: 'races',
          $includeInternalFields: true
        }
      });
  
      if(implementationDistrictOptions.total !== 1) {
        throw new Error(`No (or multiple) implementation district found for district: ${districtId} and election: ${election}`, { implementationDistrictOptions })
      }
  
      const implementationDistrictBeforeUpdate = implementationDistrictOptions.data[0];
      const scoutedWithNew = implementationDistrictBeforeUpdate.scoutedWithDistricts.filter(d => !districtIdsToUpdate.includes(`${d}`) && `${d}` !== `${districtId}`);
  
      const updated = await feathers.getService('implementation-districts').patch(
        implementationDistrictBeforeUpdate._id,
        {
          scoutedWithDistricts: scoutedWithNew,
          status: 'incomplete'
        },
        {
          query: { $includeInternalFields: true }
        }
      );
    }));
    
    console.log(`Disassociated ${updatesSecondary.length} districts`);
    setAdditionalDistrictsEdited(false)
    setSavingUpdates(false)
  }

  const expandAllSections = () => {
    const newSectionsExpanded = allDistricts.reduce((acc, d) => {
      acc[d._id] = {
        races: true,
        measures: true
      }
      return acc
    }
    , {})
    setSectionsExpanded(newSectionsExpanded)
  }
  const collapseAllSections = () => {
    const newSectionsExpanded = allDistricts.reduce((acc, d) => {
      acc[d._id] = {
        races: false, 
        measures: false
      }
      return acc
    }, {})
    setSectionsExpanded(newSectionsExpanded)
  }


	return (
      (<div style={style} className={className}>
        <div style={{ display: 'grid', gridTemplateColumns: 'auto auto', justifyContent: 'start', alignItems: 'center', gridGap: '16px 12px',  marginBottom: '36px' }}>
            <Typography variant="body2">Area</Typography>
            <Typography variant="body1" style={{ fontSize: '18px'}}><b>{district?.originalDistrict?.longName || district?.longName}</b> in {district?.parentDistrict?.originalDistrict?.shortName || district?.parentDistrict?.shortName}</Typography>

            <Typography variant="body2">Election</Typography>
            <Typography variant="body1" style={{ fontSize: '18px'}}><b>{electionFull?.name}</b></Typography>
        </div>
        {
          !loading && !isPrimaryDistrict &&
          <HintTile style={{ marginBottom: '20px'}}>
            <Typography variant='body1' style={{ fontWeight: 'bold', marginBottom: '12px'}}>You cannot edit this district directly.</Typography>
            <Typography variant='body1'>This district is being scouted with another district. Please make changes to {additionalDistricts.find(ad => ad._id === primaryScoutingDistrict)?.longName}. If this is a mistake, you can dissociate these two districts by removing this district from the primary district.</Typography>
          </HintTile>
        }
        <div style={{ marginBottom: '20px', display: 'flex', flexDirection: 'column', gap: '0px' }}>
            <Typography variant="h4" style={{ marginBottom: '12px' }}>Where are you getting your information from?</Typography>
    <Typography variant='body1'>Make sure this is a URL to the page. If this is a document from a government website, link to the parent page where the document is listed, not the document directly.</Typography>
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '12px', marginTop: '12px' }}>
      <TextField
        label="Source"
        size='small'
        variant="outlined"
        onChange={(e) => {
          setSource(e.target.value)
          setSourceWasEdited(true)
        }}
        style={{ minWidth: '400px' }}
        value={source}
      />
      {
        sourceWasEdited && allowDirectEdits && isPrimaryDistrict && (
          <Button
            variant="contained"
            color="primary"
            size='small'
            disabled={savingUpdates}
            onClick={() => saveUpdates(true, false)}
          >
            {savingUpdates ? 'Saving...' : 'Save'}
          </Button>
        )
      }
      {
        !sourceWasEdited && source && (
          <Link href={source} target="_blank">
            <OpenInNewIcon />
          </Link>
        )
      }
    </div>
    {
      source && !source.startsWith('http') && (
        <Typography variant='body2' style={{ color: 'red' }}>Please make sure the source starts with http or https</Typography>
      )
    }
        </div>
        <Divider />
        <div style={{ marginBottom: '20px', display: 'flex', flexDirection: 'column', gap: '0px', padding: '36px 0' }}>
          <Typography variant="h4" style={{ marginBottom: '12px' }}>What type of content is included here?</Typography>
          <div style={{ display: 'grid', gridTemplateColumns: '1fr 1px 1fr' }}>
            <ContentTypeGroup
              contentType='races'
              allowChanging={requireContentType === 'races' ? false : true}
              enabled={contentTypes.includes('races')}
              onChange={(enabled) => {
                setContentTypes(enabled ? [...contentTypes, 'races'] : contentTypes.filter(ct => ct !== 'races'))
              }}
              implementationDistrict={implementationDistrictRace}
            />
            <DividerVertical />
            <ContentTypeGroup
              contentType='measures'
              allowChanging={requireContentType === 'measures' ? false : true}
              enabled={contentTypes.includes('measures')}
              onChange={(enabled) => {
                setContentTypes(enabled ? [...contentTypes, 'measures'] : contentTypes.filter(ct => ct !== 'measures'))
              }}
              implementationDistrict={implementationDistrictMeasure}
              {
                ...(
                  loading ? { disabled: true} : {
                    disabled: !measureScoutingEnabled.enabled,
                    disabledReason: measureScoutingEnabled.reason
                  }
                )
              }
            />
          </div>
        </div>
        <Divider />
        {
            ['city', 'county'].includes(district?.type) &&
            <>
                <AdditionalDistrictsWrapper>
        <div style={{ display: 'flex', gap: '30px', alignItems: 'center', marginBottom: '12px' }}>
          <Typography variant="h4" style={{  }}>Are there other districts in this source?</Typography>
          {
            additionalDistrictsEdited && allowDirectEdits && isPrimaryDistrict && (
              <Button
                variant="contained"
                color="primary"
                size='small'
                disabled={savingUpdates}
                onClick={() => saveUpdates(false, true)}
              >
                {savingUpdates ? 'Saving...' : 'Save'}
              </Button>
            )
          }
        </div>
        <ChipMultiSelectMenu
          selectedValues={additionalDistricts}
          options={intersectingDistrictsOptions}
          selectOptionFunction={e => {
            setAdditionalDistricts([...additionalDistricts, e])
            setAdditionalDistrictsEdited(true)
          }}
          deselectOptionFunction={e => {
            setAdditionalDistricts(additionalDistricts.filter(d => d._id !== e._id))
            setAdditionalDistrictsEdited(true)
          }}
          displayProp='longName'
          labelText='Additional Districts'
          verticalChips={true}
        />
                </AdditionalDistrictsWrapper>
                <Divider style={{ marginTop: '24px' }}/>
            </>
        }
        <div style={{ ...((sourceIdentified && !disabled) ? {} : { opacity: 0.4, pointerEvents: 'none' }), marginTop: '16px', maxWidth: '800px'}}>
    <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end', alignItems: 'center', gap: '8px', marginBottom: '12px' }}>
      <Tooltip title="Expand all sections">
        <IconButton onClick={expandAllSections} size='small'>
          <Add />
        </IconButton>
      </Tooltip>
      <Tooltip title="Collapse all sections">
        <IconButton onClick={collapseAllSections} size='small'>
          <Remove />
        </IconButton>
      </Tooltip>
    </div>
    {
      loading && (
        <div style={{ }}>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: "8px", marginBottom: '2px', gap: '12px' }}>
            <Skeleton variant="text" width={200} height={30} />
          </div>
          <Skeleton variant="rectangular" width={800} height={200} />
        </div>
      )
    }
            {
                !loading && allDistricts.map((d, i) => {
        const expansionDetails = sectionsExpanded[d._id];
        const isRaceExpanded = typeof(expansionDetails) === 'undefined' ? true : expansionDetails?.races;
        const isMeasuresExpanded = typeof(expansionDetails) === 'undefined' ? true : expansionDetails?.measures;

        const onChangeRace = (event, isExpanded) => {
          setSectionsExpanded(sectionsExpanded => ({
            ...sectionsExpanded,
            [d._id]: {
              ...sectionsExpanded[d._id],
              races: isExpanded
            }
          }))
        }

        const onChangeMeasures = (event, isExpanded) => {
          setSectionsExpanded(sectionsExpanded => ({
            ...sectionsExpanded,
            [d._id]: {
              ...sectionsExpanded[d._id],
              measures: isExpanded
            }
          }))
        }

                    return (
          <div style={{ marginBottom: '36px', border: 'solid 1px #eeeeee', backgroundColor: '#FFFFFF' }}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '16px', border: 'solid 1px #eeeeee', marginBottom: '2px', gap: '12px' }}>
              <Typography variant="h4" style={{}}>{ d.longName }</Typography>
            </div>
            {
              contentTypes.includes('races') &&
              <Accordion expanded={isRaceExpanded} onChange={onChangeRace} style={{ margin: 0, borderRadius: '0' , border: 'solid 1px #eeeeee'}}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  style={{ margin: 0 }}
                >
                  <Typography variant='body1' style={{ opacity: 0.8 }}>{(allRaces[d._id] || []).length} races</Typography>
                </AccordionSummary>
                <AccordionDetails style={{ backgroundColor: '#eeeeee' }}>
                  <div style={{ marginBottom: "24px" }} key={d?._id}>
                    {
                      allRaces[d._id]?.map((r) => (
                        <RaceCandidatePreviewTile
                          onAddCandidate={(cdNew) => onAddCandidate(cdNew, d)}
                          key={r._id}
                          race={r}
                          onError={setError} // send this error down create handle error function
                          removeCandidate={(cdId) => removeCandidateFromEditRaces(cdId, d)}
                        />
                      ))
                    }
                    <Button
                      variant="contained"
                      style={{ width: "150px", alignSelf: "flex-end", margin: "20px" }}
                      color="primary"
                      disabled={!isPrimaryDistrict}
                      onClick={() => setAddRaceModalOpen(d)}
                    >
                      Add race
                    </Button>
                  </div>
                </AccordionDetails>
              </Accordion>
            }
            {
              contentTypes.includes('measures') &&
              <Accordion expanded={isMeasuresExpanded} onChange={onChangeMeasures} style={{ margin: 0, borderRadius: '0', border: 'solid 1px #eeeeee' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  style={{ margin: 0 }}
                >
                  <Typography variant='body1' style={{ opacity: 0.8 }}>{(allMeasures[d._id] || []).length} referendums</Typography>
                </AccordionSummary>
                <AccordionDetails style={{ backgroundColor: '#eeeeee' }}>
                  <div style={{ marginBottom: "24px" }} key={d?._id}>
                    {
                      allMeasures[d._id]?.map((r) => (
                        <MeasurePreviewTile key={r?._id} measure={r} />
                      ))
                    }
                    <Button
                      variant="contained"
                      style={{ width: "150px", alignSelf: "flex-end", margin: "20px" }}
                      color="primary"
                      disabled={!isPrimaryDistrict}
                      onClick={() => setAddMeasureModalOpen(d)}
                    >
                      Add referendum
                    </Button>
                  </div>
                </AccordionDetails>
              </Accordion>
            }
          </div>
                    )
                })
            }
        </div>
        {
    addRaceModalOpen && (
    <Modal onClose={() => {
      setAddRaceModalOpen(null);
    }}>
      <CreateRaceModal
        onClose={() => {
          setAddRaceModalOpen(null);
        }}
        restrictToDistrict={addRaceModalOpen}
        restrictToOfficesWithDistrictTypes={possibleDistrictTypes[addRaceModalOpen._id]}
        election={electionFull}
        onSaveCreateRace={false}
        coveragePlan={'auto-coverage'}
        onFinish={(race) => onRaceCreated(race)}
      />
    </Modal>
  )}
        {
          addMeasureModalOpen && (
          <Modal onClose={() => {
            setAddMeasureModalOpen(null);
          }}>
            <CreateMeasureModal
              onClose={() => {
                setAddMeasureModalOpen(null);
              }}
              restrictToDistrict={addMeasureModalOpen}
              election={electionFull}
              onFinish={(measure) => onMeasureCreated(measure)}
            />
          </Modal>
        )}
      </div>)
    );
}

const AdditionalDistrictsWrapper = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
`;

const Divider = styled.hr`
  width: 400px;
  background-color: #e3e3e3;
  margin: 0;
  border: none;
  height: 1px;
`

const DividerVertical = styled.div`
  width: 1px;
  background-color: #e3e3e3;
  margin: 0;
  height: 100%;
`

const contentTypeGroups = {
  'races': {
    title: 'Races'
  },
  'measures': {
    title: 'Referendums'
  }
}

const ContentTypeGroup = ({
  contentType = 'races', // or measures
  enabled,
  allowChanging = true,
  onChange,
  implementationDistrict,     // allows us to share the status
  disabled = false,
  disabledReason = ''
}) => {

  console.log('implementationDistrict', implementationDistrict)
  return (
    <div style={{ padding: '8px 24px', opacity: disabled ? 0.6 : 1.0 }}>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '12px', marginBottom: '8px' }}>
        <Checkbox
          checked={enabled}
          disabled={disabled || !allowChanging}
          onChange={() => onChange(!enabled)}
          style={{ marginLeft: '-12px'}}
        />
        <Typography variant='body1' style={{ fontWeight: 'bold' }}>{contentTypeGroups[contentType].title}</Typography>
      </div>
      {
        implementationDistrict?.status === 'complete' && (
          <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
            <CheckCircle style={{ color: 'green' }} />
            <div>
              <Typography variant='body1'>Scouting completed on {moment.utc(implementationDistrict.checkedAt).format('LL')}</Typography>
              <Typography variant='body2'>By {implementationDistrict?.automatedService || implementationDistrict.user?.firstName || implementationDistrict?.user?.name || implementationDistrict?.user || '--'}</Typography>
            </div>
          </div>
        )
      }
      {
        implementationDistrict?.status === 'incomplete' && (
          <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
            <div>
              <Typography variant='body1'>Scouting not completed</Typography>
            </div>
          </div>
        )
      }
      {
        disabled && disabledReason && (
          <Typography variant='body2' style={{ marginTop: '12px' }}>{`Referendum scouting isn't an option because ${disabledReason}`}</Typography>
        )
      }
    </div>
  )
}


// const loadAdditionalDistrictDescendants = async () => {
//   if (!additionalDistricts.length) {
//     setAdditionalDistrictsDescendants({})
//     return
//   }

//   const descendantQuery ={
//     $descendantOf: additionalDistricts.map(d => d._id)
//   }

//   const additionalDistrictDescendants = await loadPaginatedFrontend('districts', descendantQuery, feathers, 10)

//   // hash map by parentId
//   const newAdditionalDistrictsDescendants = additionalDistrictDescendants.reduce((acc, d) => {
//     if (!acc[d.parent]) {
//       acc[d.parent] = []
//     }
//     acc[d.parent].push(d)
//     return acc
//   }, {})

//   const possibleTypesParents = additionalDistricts.reduce((acc, d) => {
//       acc[d._id] = [ d.type ]
//       return acc
//   }, {})

//   const allPossibleTypes = additionalDistrictDescendants.reduce((acc, d) => {
//     if (!acc[d.parentId]) {
//       acc[d.parentId] = []
//     }
//     acc[d.parentId] = [...new Set([
//       ...acc[d.parentId],
//       d.type
//     ])]
//     return acc
//   }, possibleTypesParents)

//   setAdditionalDistrictsDescendants(newAdditionalDistrictsDescendants)
//   setPossibleDistrictTypes({...possibleDistrictTypes, ...allPossibleTypes})
// }

export { ScoutRacesForArea }