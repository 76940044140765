import React, {useEffect, useState} from 'react';
import {isPermitted, useFeathers} from "../../app/util";
import styled from 'styled-components';
import {
  DataDetailToolbar,
  LoadingSpinner,
  AssignmentPreviewTable,
  DataField, ProgressBar,
  MyAssignedWork,
  LogPaymentModal,
  Modal,
  CopyText
} from "../../components";
import {useHistory, useLocation, useParams, useRouteMatch, Link} from "react-router-dom";
import {useSelector} from "react-redux";
import Typography from '@mui/material/Typography'
import ReactTooltip from "react-tooltip";
import Button from "@mui/material/Button";
import { IconButton } from '@mui/material';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { set } from 'lodash';

const TeamDetail = ({

}) => {
  const feathers = useFeathers();
  const { key: electionKey, userId } = useParams();
  const userViewing = useSelector(state => state?.user);
  const election = useSelector(state => state?.elections?.byKey[electionKey]);
  const workAssigningPerms = isPermitted(userViewing, ['editor', 'super-admin']);
  const canViewPayment = isPermitted(userViewing, [ 'researcher', 'editor', 'super-admin', 'reviewer']);
  const paymentPerms = isPermitted(userViewing, ['editor', 'super-admin']);
  const [ user, setUser ] = useState(null);
  const [ assignments, setAssignments ] = useState(null);
  const isReviewer = isPermitted(user || {}, ['reviewer'])
  const activeAssignment = (assignments || []).find(a => !a.closed);
  const [ userStats, setUserStats ] = useState({});
  const [ logPaymentModalVisible, setLogPaymentModalVisible ] = useState(false)
  const [ electionImplementations, setElectionImplementations ] = useState(null)
  const [ otherActiveAssignments, setOtherActiveAssignments ] = useState([]);

  const usersName = !user?.name && (user?.firstName || user?.lastName) ? (
    (user?.firstName && user?.lastName )
      ? `${user?.firstName} ${user?.lastName}`
      : (user?.firstName || user?.lastName)
  ) : user?.name;

  const loadUserDetail = async () => {
    // load user and their assignments
    const user = await feathers.getService('users').get(userId);
    setUser(user);

    try {
      const teamMemberOpts = await feathers.getService('team-members').get(null, {
        query: {
          user: userId,
          election: electionKey
        }
      })

      const teamMember = teamMemberOpts.data ? teamMemberOpts.data[0] : teamMemberOpts;

      const {
        profilesSubmitted,
        profilesAccepted,
        reprofilesAccepted,
        avgDaysToSubmit,
        avgDaysToAccept,
        percentageAssignmentsCompleted,
        amountPaid,
        hoursWorked,
        hoursPaid,
        profilesPaid,
        payments,
        profilesOwed,
        reprofilesOwed,
        firstTimeProfilesOwed,
        hoursOwed,
        amountEarned,
        amountOwed
      } = (teamMember || {});


      setUserStats({
        profilesSubmitted,
        profilesCompleted: profilesAccepted,
        reprofilesAccepted,
        firstTimeProfilesAccepted: profilesAccepted - reprofilesAccepted,
        avgDaysToSubmit,
        avgDaysToAccept,
        percentageAssignmentsCompleted,
        amountPaid,
        hoursWorked,
        hoursPaid,
        profilesPaid,
        payments,
        hoursOwed,
        profilesOwed,
        reprofilesOwed,
        firstTimeProfilesOwed,
        amountEarned,
        amountOwed
      })

      const { data: otherAssignments } = await feathers.getService('batch-assignments').find({ query: {
          user: userId,
          closed: false,
          election: {
            $ne: electionKey
          },
          $limit: 30
        }
      })

      setOtherActiveAssignments(otherAssignments)
    } catch (err) {
      console.log(err)
    }

    const assignments = await feathers.getService('batch-assignments').find({
      query: {
        $limit: 50,
        user: user._id,
        election: electionKey,
        $sort: { createdAt: -1 }
      }
    })

    setAssignments(assignments.data)

    ReactTooltip.rebuild()
  }

  const getElectionImplementationsData = async () => {
    try {
      const eiData = await feathers.getService('election-implementations').get(
        electionKey
      );
      setElectionImplementations(eiData)
    } catch(err) {
      console.error(err)
    }
  }

  useEffect(() => {
    if(feathers) {
      loadUserDetail()
      getElectionImplementationsData()
    }
  }, [ feathers ])


  return (
    (<Wrapper>
      <DataDetailToolbar
        onBack={{ to: {
            pathname: workAssigningPerms ? `/elections/${electionKey}/team` : `/elections/${electionKey}`
          }}}
        navTree={[
          {
            text: 'Elections',
            to: {
              pathname: `/elections`,
            }
          },
          {
            text: election?.name || 'Election',
            to: {
              pathname: `/elections/${electionKey}`,
            }
          },
          workAssigningPerms
          ? {
              text: 'Team',
              to: {
                pathname: `/elections/${electionKey}/team`
              }
            }
          : {},
          {
            text: usersName
          }
        ].filter(Boolean)}
        actionButtonsComponent={
          <div>
            {
              paymentPerms &&
              <IconButton onClick={() => setLogPaymentModalVisible(true)} size="large">
                <AttachMoneyIcon />
              </IconButton>
            }
            {
              !activeAssignment && workAssigningPerms && !otherActiveAssignments?.length &&
              <Link to={`/elections/${electionKey}/assignments/new/${userId}?role=${isReviewer ? 'reviewer' : 'researcher'}`}>
                <Button variant='contained' color='secondary'>
                  New assignment
                </Button>
              </Link>
            }
          </div>
        }
      />
      <WrapperInner>
        {
          user &&
          <>
            <Typography variant={'h2'} style={{ marginBottom: '18px' }}>{usersName}'s profile</Typography>
            <DataFieldWrapper style={{ marginBottom: '60px' }}>
              <DataField title='Email'>
                <Typography variant={'body1'} style={{ marginTop: '4px' }}>
                  {user?.email}
                </Typography>
              </DataField>
              <DataField title='# profiles submitted' data-tip={`The number of profiles you've submitted<br/>for initial review.`}>
                <Typography variant={'body1'} style={{ marginTop: '4px' }}>
                  {typeof(userStats.profilesSubmitted) !== 'undefined' ? `${userStats.profilesSubmitted} profiles` : '--'}
                </Typography>
              </DataField>
              <DataField title='# profiles completed' data-tip={`The number of profiles you've submitted<br/>that have been accepted. ${userStats.profilesCompleted ? `<br/>${userStats.firstTimeProfilesAccepted} are from scratch and ${userStats.reprofilesAccepted} are re-profiles.` : ``}`}>
                <Typography variant={'body1'} style={{ marginTop: '4px' }}>
                  {typeof(userStats.profilesCompleted) !== 'undefined' ? `${userStats.profilesCompleted} profiles` : '--'}
                </Typography>
              </DataField>
              <DataField title='% submissions accepted' data-tip={`The number of profiles you've submitted<br/>that have been accepted.`}>
                {
                  (typeof(userStats.profilesSubmitted) !== 'undefined' && typeof(userStats.profilesCompleted) !== 'undefined')
                  ?
                    <div style={{ marginTop: '8px'}}>
                      <ProgressBar percentage={(userStats.profilesCompleted / userStats.profilesSubmitted * 100)} backgroundColor={'#D8D8D8'}/>
                      <Typography variant={'body1'} style={{ marginTop: '2px', fontSize: '14px' }}>
                        {userStats.profilesCompleted}/{userStats.profilesSubmitted} profiles
                      </Typography>
                    </div>
                  : <Typography variant={'body1'} style={{ marginTop: '4px' }}>--</Typography>
                }
              </DataField>
              <DataField title='Average days to submit' data-tip={`The average time it's taken you to<br/>submit your assigned profiles.`}>
                <Typography variant={'body1'} style={{ marginTop: '4px' }}>
                  {typeof(userStats.avgDaysToSubmit) !== 'undefined' ? `${userStats.avgDaysToSubmit} days` : '--'}
                </Typography>
              </DataField>
              <DataField title='Average days to complete' data-tip={`The average time it's taken your profiles<br/>to be submitted and accepted.`}>
                <Typography variant={'body1'} style={{ marginTop: '4px' }}>
                  {typeof(userStats.avgDaysToAccept) !== 'undefined' ? `${userStats.avgDaysToAccept} days` : '--'}
                </Typography>
              </DataField>
              <DataField title='% assignments completed'>
                <Typography variant={'body1'} style={{ marginTop: '4px' }}  data-tip={`The number of assignments that<br/>you've finised before the deadline.`}>
                  {typeof(userStats.percentageAssignmentsCompleted) !== 'undefined' ? `${userStats.percentageAssignmentsCompleted}%` : '--'}
                </Typography>
              </DataField>
              <DataField title='Your election contribution'  data-tip={`The total number of candidate profiles<br/>that you've helped with this election.`}>
                <Typography variant={'body1'} style={{ marginTop: '4px' }}>
                  {
                    (typeof(userStats.profilesCompleted) !== 'undefined' && typeof(electionImplementations?.aggregatedInformation?.numProfiles) !== 'undefined')
                    ? `${userStats.profilesCompleted} out of ${electionImplementations?.aggregatedInformation.numProfiles} profiles`
                    : '--'
                  }
                </Typography>
              </DataField>
            </DataFieldWrapper>
            {
              canViewPayment &&
              <div style={{ marginTop: '-42px', marginBottom: '36px' }}>
                <Typography variant='body1'><b>Payment</b></Typography>
                <DataFieldWrapper>
                  <DataField title='# profiles completed' data-tip={`The number of profiles you've submitted<br/>that have been accepted.` + (userStats.profilesCompleted ? `<br/>${userStats.firstTimeProfilesAccepted} are from scratch and ${userStats.reprofilesAccepted} are re-profiles.` : ``)}>
                    <Typography variant={'body1'} style={{ marginTop: '4px' }}>
                      {typeof(userStats.profilesCompleted) !== 'undefined' ? `${userStats.profilesCompleted} profiles` : '--'}
                    </Typography>
                  </DataField>
                  <DataField title='Addtl. hours worked'  data-tip={`The total number of hours spent on trainings`}>
                    <Typography variant={'body1'} style={{ marginTop: '4px' }}>
                      {
                        typeof(userStats.hoursWorked) !== 'undefined'
                        ? `${userStats.hoursWorked} hours`
                        : '--'
                      }
                    </Typography>
                  </DataField>
                  <DataField title='Amount earned'  data-tip={`The total amount of money earned<br/>through trainings and research work`}>
                    <Typography variant={'body1'} style={{ marginTop: '4px' }}>
                      {
                        typeof(userStats.amountEarned) !== 'undefined'
                        ? `$${userStats.amountEarned}`
                        : '--'
                      }
                    </Typography>
                  </DataField>
                  <DataField title='Amount paid'  data-tip={`The total amount of money paid so far`}>
                    <Typography variant={'body1'} style={{ marginTop: '4px' }}>
                      {
                        typeof(userStats.amountPaid) !== 'undefined'
                        ? `$${userStats.amountPaid}`
                        : '--'
                      }
                    </Typography>
                  </DataField>
                  {
                    paymentPerms &&
                    <>
                     <DataField title='Profiles owed'  data-tip={`Profiles the researcher has yet to been paid for.` + ( typeof(userStats.profilesOwed) !== 'undefined' ? `<br/>${userStats.firstTimeProfilesOwed} are from scratch and ${userStats.reprofilesOwed} are re-profiles.` : '')}>
                        <Typography variant={'body1'} style={{ marginTop: '4px' }}>
                          {
                            typeof(userStats.profilesOwed) !== 'undefined'
                            ? `${userStats.profilesOwed} profiles`
                            : '--'
                          }
                        </Typography>
                      </DataField>
                      <DataField title='Hours owed'  data-tip={`The number of unpaid hours`}>
                        <Typography variant={'body1'} style={{ marginTop: '4px' }}>
                          {
                            typeof(userStats.hoursOwed) !== 'undefined'
                            ? `${userStats.hoursOwed} hours`
                            : '--'
                          }
                        </Typography>
                      </DataField>
                      <DataField title='Amount owed'  data-tip={`The total amount of money needing to be paid to the researcher`}>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                          <Typography variant={'body1'} style={{ marginTop: '4px' }}>
                            {
                              typeof(userStats.amountOwed) !== 'undefined'
                              ? `$${userStats.amountOwed}`
                              : '--'
                            }
                          </Typography>
                          {
                            typeof(userStats.amountOwed) !== 'undefined' && userStats.amountOwed > 0 &&
                            <CopyText
                              buttonOnly={true}
                              text={JSON.stringify({
                                payment: {
                                  amountPaid: userStats.amountOwed,
                                  hoursPaid: userStats.hoursOwed,
                                  reprofilesPaid: userStats.reprofilesOwed,
                                  firstTimeProfilesPaid: userStats.firstTimeProfilesOwed
                                }
                              }, null, 2)}
                            />
                          }
                        </div>
                      </DataField>
                    </>
                  }
                </DataFieldWrapper>
              </div>
            }
            {
              !assignments &&
              <EmptyStateWrapper>
                <LoadingSpinner />
              </EmptyStateWrapper>
            }
            {
              assignments &&
              <>
                <Typography variant='h4'  style={{ marginBottom: '0' }}>Active assignment</Typography>
                {
                  activeAssignment
                  ? <AssignmentPreviewTable
                      assignments={[activeAssignment]}
                      electionKey={electionKey}
                      role={isReviewer ? 'reviewer' : 'researcher'}
                    />
                  : <Typography variant='body1'>No active assignment.</Typography>
                }
                {
                  user?._id === userViewing?._id &&
                  <>
                    <Typography variant='h4'  style={{ marginBottom: '0', marginTop: '16px' }}>Work needing attention</Typography>
                    <div style={{ border: 'solid #EEEEEE 1px', borderRadius: '8px', backgroundColor: '#FFFFFF'}}>
                      <MyAssignedWork
                        election={electionKey}
                        showActiveAssignment={false}
                        style={{ overflowY: 'auto'}}
                      />
                    </div>
                  </>
                }
                <Typography variant='h4' style={{ marginTop: '36px', marginBottom: '0' }}>Previous Assignments</Typography>
                {
                  assignments.filter(a => a?.closed).length > 0
                  ?
                  <AssignmentPreviewTable
                    assignments={assignments.filter(a => a?.closed)}
                    electionKey={electionKey}
                  />
                  : <EmptyStateWrapper>
                      <Typography variant='overline'>No assignments yet</Typography>
                    </EmptyStateWrapper>
                }

                <Typography variant='h4' style={{ marginTop: '36px', marginBottom: '0' }}>Active Assignments on Other Teams</Typography>
                {
                  otherActiveAssignments.length > 0
                  ?
                  <AssignmentPreviewTable
                    assignments={otherActiveAssignments}
                  />
                  : <EmptyStateWrapper>
                      <Typography variant='overline'>No Active Assignments in other Teams</Typography>
                    </EmptyStateWrapper>
                }
              </>
            }
          </>
        }
        {
          !user &&
          <LoadingSpinner />
        }
        {
          logPaymentModalVisible &&
          (
            <Modal onClose={() => setLogPaymentModalVisible(false)}>
              <LogPaymentModal
                onClose={() => setLogPaymentModalVisible(false)}
                onSuccess={() => setLogPaymentModalVisible(false)}
              />
            </Modal>
          )
        }
        <ReactTooltip place='left' effect='solid' type='light' multiline/>
      </WrapperInner>
    </Wrapper>)
  );
}

const Wrapper = styled.div`
  width: calc(100% - 2 * 24px);
  min-height: calc(100% - 24px * 2);
  display: flex;
  flex-direction: column;
  padding: 24px;
  align-items: stretch;
`

const WrapperInner = styled.div`
  margin-top: 36px;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: stretch;
  gap: 6px;
`

const DataFieldWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 24px 24px;
  max-width: 1200px;
`

const EmptyStateWrapper = styled.div`
  width: 100%;
  min-height: 80px;
  margin: 8px 0 0;
  padding: 12px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FFFFFF;
  border-radius: 8px;
  border: solid 1px #EEEEEE;
  span {
    ${({ theme }) => theme.font.normal};
    opacity: 0.8;
    font-size: 16px;
  }
`

export default TeamDetail;