import React, {useEffect, useState} from 'react';
import {isPermitted, useFeathers} from "../../app/util";
import styled from 'styled-components';
import {
  CTAButton,
  DataDetailToolbar,
  LoadingSpinner,
  AssignmentPreviewTable,
  CandidatePreviewTile,
  DataField,
  ProgressBar,
  DueDate,
  Modal,
  RequestExtensionModal
} from "../../components";
import {useHistory, useLocation, useParams, useRouteMatch} from "react-router-dom";
import {useSelector} from "react-redux";
import Typography from '@mui/material/Typography'
import TextField from "@mui/material/TextField";
import moment from 'moment';
import ReactTooltip from "react-tooltip";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";


const AssignmentDetail = () => {
  const feathers = useFeathers();
  const { key: electionKey, id } = useParams();
  const route = useRouteMatch();
  const history = useHistory();
  const location = useLocation();
  const user = useSelector(state => state?.user);
  const election = useSelector(state => state?.elections?.byKey[electionKey]);
  const workAssigningPerms = isPermitted(user, ['editor', 'super-admin']);
  const [ assignment, setAssignment ] = useState(null);
  const [ candidatesCompleted, setCandidatesCompleted ] = useState(null);
  const [ candidatesStarted, setCandidatesStarted ] = useState(null);
  const [ candidatesNotStarted, setCandidatesNotStarted ] = useState(null);
  const [ closeDialogVisible, setCloseDialogVisible ] = useState(false);
  const [ requestExtensionDialogVisible, setRequestExtensionDialogVisible] = useState(false);
  const canExtendDeadline = true;

  const usersName = !assignment?.user?.name && (assignment?.user?.firstName || assignment?.user?.lastName) ? (
    (assignment?.user?.firstName && assignment?.user?.lastName )
      ? `${assignment?.user?.firstName} ${assignment?.user?.lastName}`
      : (assignment?.user?.firstName || assignment?.user?.lastName)
  ) : assignment?.user?.name;

  const loadAssignmentDetail = async () => {
    // load the assignments and the candidate
    const assignment = await feathers.getService('batch-assignments').get(id);
    setAssignment(assignment);

    const assignedUserId = assignment?.user?._id || assignment?.user;

    const candidates = await feathers.getService('candidates').find({
      query: {
        _id: assignment.candidates,
        $limit: assignment.candidates.length
      }
    })

    setCandidatesNotStarted(candidates.data.filter(cd => {
      const usersMadeEdits = [cd.stagingDraft.user, ...cd.previousDrafts.map(cd => cd.user)];
      return !usersMadeEdits.includes(assignedUserId);
    }));
    setCandidatesStarted(candidates.data.filter(cd => {
      const usersMadeEdits = [cd.stagingDraft.user, ...cd.previousDrafts.map(cd => cd.user)];
      return usersMadeEdits.includes(assignedUserId) && (
        cd.workflow.status === 'draft' ||
        (cd.workflow.status === 'researcher-submitted' && cd.workflow.rejectionStatus === true)
      );
    }));
    setCandidatesCompleted(candidates.data.filter(cd => {
      const usersMadeEdits = [cd.stagingDraft.user, ...cd.previousDrafts.map(cd => cd.user)];
      return usersMadeEdits.includes(assignedUserId) && !(
        cd.workflow.status === 'draft' ||
        (cd.workflow.status === 'researcher-submitted' && cd.workflow.rejectionStatus === true)
      );
    }));

    ReactTooltip.rebuild()
  }

  const closeAssignment = async () => {
    await feathers.getService('batch-assignments').patch(assignment._id, { closed: true });
    history.push(`/elections/${electionKey}/team/${assignment?.user?._id || assignment?.user}`)
  }

  const [ extensionReason, setExtensionReason ] = useState('');
  const requestExtension = async () => {
    await feathers.getService('batch-assignments').patch(assignment._id, {
      $extendDeadline: true,
      extensionReason
    });
    loadAssignmentDetail();
  }

  useEffect(() => {
    if(feathers) {
      loadAssignmentDetail()
    }
  }, [ feathers ])

  const renderCdTile = cd => (<CandidatePreviewTile
    candidate={cd}
    key={cd._id}
    onClick={{ to: {
        pathname: `/elections/${electionKey}/races/${cd?.race?._id || cd?.race}/candidates/${cd._id}`,
        state: {
          onBack: `/elections/${electionKey}/assignments/${id}`
        }
      }}}
  />)
  return (
    <Wrapper>
      <DataDetailToolbar
        onBack={{ to: {
            pathname: `/elections/${electionKey}/team/${assignment?.user?._id || assignment?.user}`
          }}}
        navTree={[
          {
            text: 'Elections',
            to: {
              pathname: `/elections`,
            }
          },
          {
            text: election?.name || 'Election',
            to: {
              pathname: `/elections/${electionKey}`,
            }
          },
          workAssigningPerms
          ? {
              text: 'Team',
              to: {
                pathname: `/elections/${electionKey}/team`
              }
            }
          : {},
          {
            text: usersName || '--',
            to: {
              pathname: `/elections/${electionKey}/team/${assignment?.user?._id || assignment?.user}`
            }
          },
          {
            text: 'Assignment'
          }
        ].filter(Boolean)}
        actionButtonsComponent={
          <div>
            { workAssigningPerms && assignment && !assignment?.closed &&
              <Button variant={'contained'} color={'primary'} onClick={() => setCloseDialogVisible(true)}>
                Close Assignment
              </Button>
            }
            { (
                assignment &&
                !assignment?.closed &&
                user._id === assignment?.user?._id
              )
            &&
              <Button
                variant={'contained'}
                color={'primary'}
                onClick={() => setRequestExtensionDialogVisible(true)}
                disabled={assignment?.extensionDueBy}
              >
                Request Extension
              </Button>
            }
          </div>
        }
      />
      <WrapperInner>
        {
          assignment &&
          <>
            <Typography variant={'h2'}>Assigned work: {assignment?.candidates?.length} profiles</Typography>
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', margin: '16px 0 36px', gridRowGap: '24px'}}>
              <DataField title='Assigned to'>
                <Typography variant={'body1'} style={{ marginTop: '4px' }}>
                  {Boolean(usersName) && `${usersName} <`}{assignment.user.email}{Boolean(usersName) && `>`}
                </Typography>
              </DataField>
              <DataField title='Assigned on'>
                <Typography variant={'body1'} style={{ marginTop: '4px' }}>
                  {moment(assignment.createdAt).format('MMMM DD')}
                </Typography>
              </DataField>
              <DataField title='Due by'>
                <DueDate
                  date={assignment.extensionDueBy || assignment.dueBy}
                  extensionGranted={!!assignment.extensionDueBy}
                  showColor={!assignment.closed}
                />
              </DataField>
              <DataField title='Status'>
                <Typography variant={'body1'} style={{ marginTop: '4px' }}>
                  {assignment?.closed ? 'Closed' : 'Open'}
                </Typography>
              </DataField>
              <DataField title='Submission progress'>
                <div style={{ padding: '6px 0' }}>
                  <ProgressBar percentage={assignment?.analytics?.percentageCandidatesSubmitted} backgroundColor={'#CCCCCC'}/>
                  <Typography variant={'body1'} style={{ marginTop: '2px' }}>
                    {assignment?.analytics?.numCandidatesSubmitted}/{assignment?.analytics?.numCandidates} submitted
                  </Typography>
                </div>
              </DataField>
              <DataField title='Approval progress'>
                <div style={{ padding: '6px 0' }}>
                  <ProgressBar percentage={assignment?.analytics?.percentageCandidatesAccepted} backgroundColor={'#CCCCCC'}/>
                  <Typography variant={'body1'} style={{ marginTop: '2px' }}>
                    {assignment?.analytics?.numCandidatesAccepted}/{assignment?.analytics?.numCandidates} approved
                  </Typography>
                </div>
              </DataField>
              {
                assignment?.extensionDueBy && workAssigningPerms &&
                <DataField title='Extension requested with reason:' style={{ gridColumn: '1 / -1'}}>
                  <Typography variant={'body1'} style={{ marginTop: '2px' }}>
                    {assignment.extensionReason}
                  </Typography>
                </DataField>
              }
              
            </div>
            <Typography variant={'h3'}>Not started {candidatesNotStarted ? `(${candidatesNotStarted.length})` : ''}</Typography>
            <Typography variant={'body1'} style={{ marginBottom: '-12px'}}>These are profiles that have not yet been edited by {assignment?.user?.name || assignment?.user?.email || 'the assigned user'}.</Typography>
            {
              candidatesNotStarted?.length > 0
              ?
                <CandidatesWrapper>
                  {candidatesNotStarted.map(renderCdTile)}
                </CandidatesWrapper>
              :
                <EmptyStateWrapper>
                  {
                    candidatesNotStarted?.length === 0
                    ? <span>No profiles in this status.</span>
                    : <LoadingSpinner />
                  }
                </EmptyStateWrapper>
            }
            <Typography variant={'h3'} style={{ marginTop: '24px' }}>In progress {candidatesStarted ? `(${candidatesStarted.length})` : ''}</Typography>
            <Typography variant={'body1'} style={{ marginBottom: '-12px'}}>These are profiles that have been started but are still in draft or need revisions.</Typography>
            {
              candidatesStarted?.length > 0
                ?
                <CandidatesWrapper>
                  {candidatesStarted.map(renderCdTile)}
                </CandidatesWrapper>
                :
                <EmptyStateWrapper>
                  {
                    candidatesStarted?.length === 0
                      ? <span>No profiles in this status.</span>
                      : <LoadingSpinner />
                  }
                </EmptyStateWrapper>
            }
            <Typography variant={'h3'} style={{ marginTop: '24px' }}>Complete {candidatesCompleted ? `(${candidatesCompleted.length})` : ''}</Typography>
            <Typography variant={'body1'} style={{ marginBottom: '-12px'}}>These are profiles that have been submitted.</Typography>
            {
              candidatesCompleted?.length > 0
                ?
                <CandidatesWrapper>
                  {candidatesCompleted.map(renderCdTile)}
                </CandidatesWrapper>
                :
                <EmptyStateWrapper>
                  {
                    candidatesCompleted?.length === 0
                      ? <span>No profiles in this status.</span>
                      : <LoadingSpinner />
                  }
                </EmptyStateWrapper>
            }
          </>
        }
        {
          !assignment &&
          <LoadingSpinner />
        }

        <ReactTooltip place='left' effect='solid' type='light' multiline/>
        <Dialog
          open={closeDialogVisible}
          onClose={() => setCloseDialogVisible(false)}
        >
          <DialogTitle id="alert-dialog-title">Do you want to close this researcher's assignment?</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              The researcher will be unassigned from all work, and will be unable to make any edits to those profiles.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setCloseDialogVisible(false)} color="primary">
              Cancel
            </Button>
            <Button
              onClick={() => {
                closeAssignment()
              }}
              color="primary"
            >
              Close Assignment
            </Button>
          </DialogActions>
        </Dialog>
        {
        requestExtensionDialogVisible &&
        <Modal
          onClose={() => {
            setRequestExtensionDialogVisible(false)
          }}
          style={{overflowY: 'auto'}}
        >
          <RequestExtensionModal
            extensionReason={extensionReason}
            setExtensionReason={setExtensionReason}
            submitExtension={requestExtension}
            assignment={assignment}
          />
        </Modal>
      }
      </WrapperInner>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: calc(100% - 2 * 24px);
  min-height: calc(100% - 24px * 2);
  display: flex;
  flex-direction: column;
  padding: 24px;
  align-items: stretch;
`

const WrapperInner = styled.div`
  margin-top: 36px;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: stretch;
  gap: 6px;
`

const EmptyStateWrapper = styled.div`
  width: 100%;
  min-height: 80px;
  margin: 8px 0 0;
  padding: 12px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FFFFFF;
  border-radius: 8px;
  border: solid 1px #EEEEEE;
  span {
    ${({ theme }) => theme.font.normal};
    opacity: 0.8;
    font-size: 16px;
  }
`

const CandidatesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: solid 1px #EEEEEE;
  border-radius: 8px;
  background-color: #FFFFFF;
  width: 100%;
  margin-top: 16px;
`

export default AssignmentDetail;