import React, { useEffect, useMemo } from "react"
import styled from "styled-components";
import { useFeathers } from "../../app/util";
import * as d3 from 'd3'
import { Skeleton } from '@mui/material';
import chroma from "chroma-js";



import { TypeDropdown } from "../TypeDropdown";

const margin = {
  top: 10,
  bottom: 10,
  left: 10,
  right: 10
}

const CoveragePlanMap = ({
  supportedArea,
  priorityArea,
  expectedReadershipForDistricts,
  state,
  onClickSupportedArea,
  className,
  style
}) => {
  const feathers = useFeathers();
  const [ loading, setLoading ] = React.useState(true);
  const [ stateGeoJSON, setStateGeoJSON ] = React.useState(null);
  const [ supportedAreaGeoJSON, setSupportedAreaGeoJSON ] = React.useState(null);
  const [ priorityAreaGeoJSON, setPriorityAreaGeoJSON ] = React.useState(null);
  const [ initialized, setInitialized ] = React.useState(false);
  const [ viewDistrictsByType, setViewDistrictsByType ] = React.useState('county');
  const [ maxReadership, setMaxReadership ] = React.useState(1);

  const pathGeneratorRef = React.useRef(null);
  const supportedAreaLayerRef = React.useRef(null);
  const stateLayerRef = React.useRef(null);
  const priorityAreaLayerRef = React.useRef(null);

  const loadGeoJSON = async (idList, onSave, properties = {}, filterToType) => {
    const chunkSize = 20;
    const numChunks = Math.ceil(idList.length / chunkSize);
    const chunks = Array.from({ length: numChunks }, (_, i) => idList.slice(i * chunkSize, (i + 1) * chunkSize));
    const districts = (await Promise.all(
      chunks.map(chunk => {
        return feathers.getService('districts').find({
          query: { _id: chunk, ...(filterToType ? { type: filterToType } : {}), $limit: chunk.length, $includeGeoDistrict: true }
        })
      })
    )).map(d => d.data).flat(1)

    let readershipScores = [];

    const areaDistrictsAsFeatures = districts.map(d => {
      const expectedReaders = expectedReadershipForDistricts.find(e => e.district === d._id);
      readershipScores.push(expectedReaders.expectedReadership)

      return formatGeoJSON(d.geoDistrict?.boundary || [], {
        "NAME": d?.longName,
        "_id": d._id,
        type: d.type,
        population: expectedReaders.population,
        expectedReadership: expectedReaders.expectedReadership,
        ...properties
      })
    })

    setMaxReadership(Math.max(...readershipScores))

    onSave({
      "type": "FeatureCollection",
      "features": areaDistrictsAsFeatures
    })
  }

  useEffect(() => {
    if(feathers) {
      if(state) loadGeoJSON([state], setStateGeoJSON)
    };
  }, [ state, feathers ])

  // useEffect(() => {
  //   if(feathers) {
  //     if(priorityArea) loadGeoJSON(priorityArea, setPriorityAreaGeoJSON, { isPriority: true })
  //   };
  // }, [ priorityArea, feathers ])

  useEffect(() => {
    if(feathers) {
      if(supportedArea?.length) {
        loadGeoJSON(supportedArea, setSupportedAreaGeoJSON, {}, viewDistrictsByType)
      }
    };
  }, [ supportedArea, feathers, viewDistrictsByType ])

  useEffect(() => {
    if(stateGeoJSON) setLoading(false)
  }, [ stateGeoJSON ])

  // A random color generator
  const colorGenerator = (d) => {
    const range = chroma.scale([ '#FFFFFF', '#036949' ]);

    return range(
      (Math.log((d.properties.expectedReadership / maxReadership) + 1.0) / Math.log(10) / 0.30102999566398114) * 5,
    ).hex();
  }

  useEffect(() => {
    if(loading || initialized) return;

    let width = parseInt(d3.select('.viz').style('width'));
    let height = parseInt(d3.select('.viz').style('height'));
    width = width - margin.left - margin.right;

    console.log({
      width,
      height
    })

    const svg = d3.select('.viz').append('svg')
      .attr('class', 'center-container')
      .attr('height', height + margin.top + margin.bottom)
      .attr('width', width + margin.left + margin.right);

    svg.append('rect')
      .attr('class', 'background center-container')
      .attr('height', height + margin.top + margin.bottom)
      .attr('width', width + margin.left + margin.right)
      
    // Not using a projection -- note: this means it will look a bit squished or stretched
    const projection = d3
      .geoIdentity()
      .reflectY(true)
      .fitExtent([[0, 0], [width, height]], stateGeoJSON);


    // Creating path generator fromt the projecttion created above.
    const pathGenerator = d3.geoPath()
      .projection(projection);

    pathGeneratorRef.current = pathGenerator;

    // Creating the container
    const g = svg.append("g")
      .attr('class', 'center-container center-items us-state')
      .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')')
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)

    // Creating state layer
    const stateLayer = g.append("g")
      .attr("id", "state-boundary");

    stateLayerRef.current = stateLayer

    // and the layer for the supported areas
    const supportedAreaLayer = g.append("g")
      .attr("id", "supported-areas");
    supportedAreaLayerRef.current = supportedAreaLayer;

    // and the layer for the supported areas
    const priorityAreaLayer = g.append("g")
      .attr("id", "priority-areas");
    priorityAreaLayerRef.current = priorityAreaLayer;

    setInitialized(true)
  }, [ loading, initialized ]);

  useEffect(() => {
    const pathGenerator = pathGeneratorRef.current;
    const stateLayer = stateLayerRef.current;
    const supportedAreaLayer = supportedAreaLayerRef.current;
    const priorityAreaLayer = priorityAreaLayerRef.current;

    if(!pathGenerator || !stateLayer || !supportedAreaLayer || !priorityAreaLayer) return;

    if(stateGeoJSON) {
      stateLayer
        .selectAll('path')
        .data(stateGeoJSON.features)
        .join('path')
        .attr("key", feature => {
          return feature.properties?.NAME
        })
        .attr("fill", '#FFFFFF')
        .style('stroke', '#888888')
        .style('stroke-width', '1')
        .attr('d', pathGenerator)
        .attr("class", "state-boundary")
        .exit().remove()
    }

    if(supportedAreaGeoJSON) {
      supportedAreaLayer
        .selectAll('path')
        .data(supportedAreaGeoJSON.features)
        .join('path')
        .attr("key", feature => {
          return feature.properties?.NAME
        })
        .attr("class", "supported-area")
        .attr("fill", colorGenerator)
        .style('stroke', '#eeeeee')
        .style('stroke-width', '1')
        .attr('d', pathGenerator)
        .attr("class", "supported-area")
        .on('click', (e, d) => {
          if(onClickSupportedArea) onClickSupportedArea(d?.properties?._id)
        })
        .exit().remove()
    }

    if(priorityAreaGeoJSON) {
      priorityAreaLayer
        .selectAll('path')
        .data(priorityAreaGeoJSON.features)
        .join('path')
        .attr("key", feature => {
          return feature.properties?.NAME
        })
        .attr("class", "priority-area")
        .attr("fill", colorGenerator)
        .style('stroke', '#888888')
        .style('stroke-width', '1')
        .attr('d', pathGenerator)
        .on('click', (e, d) => {
          if(onClickSupportedArea) onClickSupportedArea(d?.properties?._id)
        })
        .exit().remove()
    }
  }, [ stateGeoJSON, supportedAreaGeoJSON, priorityAreaGeoJSON, initialized ])

  return (
    (<Wrapper className={className} style={style}>
      {
        loading &&
        <Skeleton variant="rectangular" width={500} height={350} />
      }
      <div class="viz" style={style}>
      </div>
      {
        !loading &&
        <TypeDropdown
          value={viewDistrictsByType}
          options={[
            { type: 'county', title: 'County' },
            { type: 'city', title: 'City' },
            { type: 'congressional', title: 'Congressional' },
            { type: 'state-house', title: 'State House' },
            { type: 'state-senate', title: 'State Senate' },
          ]}
          onChange={setViewDistrictsByType}
        />
      }
    </Wrapper>)
  );
}





const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  max-width: 600px;
  width: 600px;

  .viz {
    width: 100%;
    height: 100%;
  }
  .background {
    fill: none;
    pointer-events: all;
  }

  #supported-areas, #priority-areas {
    fill: none;
    stroke: #fff;
    stroke-linejoin: round;
    stroke-width: 1.5px;
  }

  #supported-areas .active {
    display: none;
  }

  .state-boundary {
    stroke: #fff;
    stroke-width: 0.5px;
  }

  .supported-area:hover, .priority-area:hover {
    fill: #002b5b;
  }
`

const getArrayDepth = (array) => Array.isArray(array) ?
    1 + Math.max(0, ...array.map(item => getArrayDepth(item))) :
    0;

export const determineShapeFromCoordinates = (coordinates) => {
  if (coordinates === null) return null
  const depth = getArrayDepth(coordinates)
  let type;
  switch(depth) {
    case 1:
      type = "Point";
      break;
    case 2:
      type = "LineString";
      break;
    case 3:
      type = "Polygon";
      break;
    case 4:
      type = "MultiPolygon";
      break;
    default:
      throw new Error('Unsupported shape')
  }
  return type;
}

export const formatGeoJSON = (coordinates, properties) => {
  if ( coordinates === null) return null
  const geometry = {
    type: determineShapeFromCoordinates(coordinates),
    coordinates,
  }

  return {
    "type": "Feature",
    "geometry": geometry,
    "properties": (properties || {})
  }
}

export { CoveragePlanMap };