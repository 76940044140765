import React from 'react';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';

const TextLink = ({
  to,
  onClick,
  style,
  className,
  children,
  variant = 'small'
}) => {
  return (
    <Link {...(to ? { to } : { onClick })} style={style} className={className}>
      <Typography variant='body1' style={{ fontSize: '13px', textDecoration: 'underline', color: '#555555', marginTop: '4px' }}>
        {children}
      </Typography>
    </Link>
  )
}

export { TextLink }
