import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useSelector } from 'react-redux';
import { useFeathers } from '../../app/util';
import {
  DataDetailToolbar,
  ElectionResultsHookups,
  ElectionResultsHookupCount
 } from '../../components'
import styled from 'styled-components';
import {useParams} from "react-router-dom";
import Skeleton from '@mui/material/Skeleton';


const DataHookupsResults = () => {
  const electionKey = useParams()?.key;
  const election = useSelector(state => state?.elections?.byKey[electionKey]);

  if(!electionKey) return <div>Invalid url. Please go back to home.</div>;

  return (
    <Wrapper>
      <DataDetailToolbar
        onBack={{ to: {
            pathname: `/elections/${electionKey}/data`,
          }}}
        navTree={[
          {
            text: 'Elections',
            to: {
              pathname: `/elections`,
            }
          },
          {
            text: election?.name,
            to: {
              pathname: `/elections/${electionKey}`,
            }
          },
          { 
            text: 'Data Hook-ups',
            to: {
              pathname: `/elections/${electionKey}/data`,
            }
          },
          {
            text: 'Election results'
          }
        ]}
      />
      <Typography style={{ marginBottom: '16px', marginTop: '24px' }} variant={'h1'}>Election results</Typography>
      <ElectionResultsHookups electionKey={electionKey} mode='races' style={{ marginTop: '24px' }}/>
    </Wrapper>
  )
}


const Wrapper = styled.div`
  width: calc(100% - 24px - 36px);
  display: flex;
  flex-direction: column;
  padding: 36px 36px 36px 24px;
  align-items: stretch;
`;

export default DataHookupsResults;
