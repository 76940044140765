import React from 'react';
import styled from 'styled-components';
import Text from '@mui/material/Typography';
import moment from 'moment';
import CalendarIcon from '@mui/icons-material/CalendarToday';
import { Link } from '@mui/material';

export const ElectionTimeline = ({
  election,
  datesPassed = 'hide',   // can be 'hide', 'show', or 'vary'. If 'vary', the dates will be styled differently based on whether they have passed
  mode = 'calendar',         // can be 'nodes' or 'calendar'. For 'nodes', displays the dates in a connected line
	subtextEnabled = true,
  style,
  className
}) => {

  const faqLinks = [];

  const dates = [
    { 
      title: (`Registration end`), 
      date1: election?.voterRegistrationEnd,
      subtext: election?.voterRegistrationDisclaimer,
      faqLinkKey: 'voter-registration'
    },
    { 
      title: (`Early voting`), 
      date1: election?.earlyVotingStart, 
      date2: election?.earlyVotingEnd, 
      subtext: election?.earlyVotingDisclaimer,
      faqLinkKey: 'early-voting'
    },
    { 
      title: (`Absentee request deadline`), 
      date1: election?.absenteeEnd,
      subtext: election?.absenteeDisclaimer,
      faqLinkKey:  'absentee-voting'
    },
    { 
      title: ('Last day to vote'), 
      date1: election?.date ,
      faqLinkKey: 'election-day-voting'
    }
  ].map(d => {
    const faqLinksForKey = faqLinks.filter(f => f.key === d.faqLinkKey).filter(f => f.url).map(f => {
      return {
        title: (f.title || 'Read more'),
        url: f.url
      }
    })

		if(subtextEnabled) {
			return {
				...d,
				links: faqLinksForKey
			}
		} else {
			const { subtext, ...rest } = d;
			return {
				...rest,
				links: faqLinksForKey
			}
		}
  })

  const datesFiltered = dates.filter(d => {
    // if no date is provided, then just hide completely
    if(!Boolean(d.date1)) return false;
    const hasDatePassed = moment().isAfter(moment.utc(d.date1));
    if(datesPassed === 'hide') return hasDatePassed ? false : true;
    else return true;
  });
  if(datesFiltered.length === 0) return null;

  return (
    <Wrapper style={style} className={className} numRows={datesFiltered.length} mode={mode}>
    {
      datesFiltered.map((d, i) => (
        mode === 'nodes' 
        ?
          <Item
            key={i}
            title={d.title}
            date={`${moment.utc(d.date1).format('MMM D')}${d.date2 ? ` – ${moment.utc(d.date2).format('MMM D')}` : ''}`}
            datePassed={moment().isAfter(moment.utc(d.date2 || d.date1))}
            lastItem={i === (datesFiltered.length - 1)}
            varyWithDatesPassed={datesPassed === 'vary'}
            subtext={d.subtext}
            links={d.links}
          />
        : (
          <VotingDate 
            key={i}
            dateTitle={d.title}
            dateWritten={`${moment.utc(d.date1).format('MMM D')}${d.date2 ? ` – ${moment.utc(d.date2).format('MMM D')}` : ''}`}
            datePassed={moment().isAfter(moment.utc(d.date2 || d.date1))}
            varyWithDatesPassed={datesPassed === 'vary'}
            subtext={d.subtext}
            links={d.links}
          />
        )
      ))
    }
    </Wrapper>
  );
}

const Item = ({
  title,
  date,
  lastItem = false,
  datePassed = false,
  varyWithDatesPassed,
  subtext,
  links
}) => (
  <>
    <Separator>
      <Dot filledIn={datePassed ? 1 : 0}/>
      {
        !lastItem &&
        <Connector complete={datePassed ? 1 : 0}/>
      }
    </Separator>
    <TimelineContent>
      <Text
        variant='overline'
        style={{
          color: '#313639',
          fontSize: '11px',
          marginTop: '8px',
          fontWeight: 'bold',
          marginBottom: '2px',
          lineHeight: '16px',
          opacity: 0.7
        }}
      >
        {title}
      </Text>
      <Text
        variant='h3'
        color='textPrimary'
        style={
          varyWithDatesPassed ? (
            datePassed
            ? { fontSize: '20px', opacity: 0.7, color: '#313639' }
            : { fontSize: '20px' }
          ) : {}
        }
      >
        {date}
      </Text>
      {
        subtext &&
        <Text
          variant='body1'
          color='textPrimary'
          style={{
            fontSize: '14px',
            opacity: 0.7,
            marginTop: '4px'
          }}
        >
          {subtext}
        </Text>
      }
      {
        Boolean(links?.length > 0) &&
        <div style={{ display: 'flex', gap: '12px', marginTop: '8px', flexWrap: 'wrap' }}>
          {
            links.map((l, i) => (
              <Link onClick={{ pathname: l.url}} style={{ textDecoration: 'underline'}}>
                <Text variant='body2' color='textSecondary'>{l.title}</Text>
              </Link>
            )
          )}
        </div>
      }
    </TimelineContent>
  </>
)

export const VotingDate = ({
  dateTitle,
  dateWritten,
  subtext,
  links
}) => (
  <>
    <CalendarIcon color='textPrimary' style={{ marginRight: '0px', height: '20px', width: '20px', marginTop: '2px' }}/>
    <div>
      <Text variant='body1' color='textPrimary' style={{ marginBottom: '-4px', marginRight: '8px', fontSize: '17px', flex: 1 }}>
        <b>{dateTitle}</b>
      </Text>
      {
        subtext &&
        <Text
          variant='body1'
          color='textPrimary'
          style={{
            fontSize: '14px',
            opacity: 0.7,
            marginTop: '4px'
          }}
        >
          {subtext}
        </Text>
      }
      {
        Boolean(links?.length > 0) &&
        <div style={{ display: 'flex', gap: '12px', marginTop: '8px', flexWrap: 'wrap' }}>
          {
            links.map((l, i) => (
              <Link onClick={{ pathname: l.url }} style={{ textDecoration: 'underline'}}>
                <Text variant='body2' color='textSecondary'>{l.title}</Text>
              </Link>
            )
          )}
        </div>
      }
    </div>
    <Text variant='body1' color='textPrimary' style={{ marginBottom: '-4px', fontSize: '17px', whiteSpace: 'nowrap' }}>
      {dateWritten}
    </Text>
  </>
)

const Wrapper = styled.div`
  ${
    props => props.mode === 'calendar' ?
      `display: grid; 
      grid-template-columns: auto 1fr auto;
      grid-gap: 8px 8px;
      grid-template-rows: repeat(${props.numRows}, auto);
      align-items: flex-start;
      `
    : ``
  }
  ${
    props => props.mode === 'nodes' ?
    `display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: repeat(${props.numRows}, 1fr);`
    : ``
  }
  
`

const Separator = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`

const Dot = styled.div`
  margin: 16px 0;
  border-radius: 20px;
  width: 10px;
  height: 10px;
  border: solid 2px #C4C4C4;
  ${props => props.filledIn ? `background-color: #C4C4C4;` : ``}
`

const Connector = styled.div`
  width: 1px;
  min-height: ${props => props.complete ? `15px;` : `30px;`}
  background-color: #C4C4C4;
  flex-grow: 1;
`

const TimelineContent = styled.div`
  padding: 0 16px;
  display: flex;
  flex-direction: column;
`
