import React from 'react';
import styled, { useTheme } from 'styled-components';
import ChevronRightLine from '@mui/icons-material/ChevronRight';


const ChevronRightButton = ({
  onClick,
  style = {},
  variant = 'contained',
  className = ''
}) => {
  const theme = useTheme();

  if(variant === 'contained') {
    return (
      <svg width="22" height="22" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" style={style} onClick={onClick} className={className}>
      <circle cx="10" cy="10" r="10" transform="rotate(-90 10 10)" fill={theme.palette.primary}/>
      <path d="M8.08485 15.0088L13.0935 10.0001L8.08485 4.99144L6.90634 6.16996L10.7365 10.0001L6.90634 13.8303L8.08485 15.0088Z" fill="white"/>
      </svg>
    )
  } else{
    return <ChevronRightLine style={{ color: theme.palette.primary, height: '22px', width: '22px', ...style }} onClick={onClick} className={className}/>
  }
}


export { ChevronRightButton };
