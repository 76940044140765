import React from 'react';
import { Typography } from '@mui/material';
import styled from 'styled-components';
import { onClickOrLink } from '../../app/util';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { NotificationBadge } from '../NotificationBadge';
import moment from 'moment';

const FeedbackPreviewTile = ({
  feedback,
  style,
  disabled,
  className
}) => {
  const history = useHistory();

  const toFullFeedback = feedback.type === 'candidate'
    ? `/elections/${feedback?.candidate?.election}/races/${feedback.candidate?.race?._id || feedback?.candidate?.race}/candidates/${feedback?.candidate?._id}/feedback/${feedback._id}`
    : `/elections/${feedback?.measure?.election?.key || feedback?.measure?.election}/measures/${feedback?.measure?._id}/feedback/${feedback._id}`;

  const {
    generatedBy,
    candidate,
    measure,
    acknowledged,
    createdAt,
    note,
    diff
  } = feedback;

  let numEditsMade;
  if(feedback.type === 'candidate') {
    const numEditsMadeToIssues = (diff?.issues || []).map(issue => {
      const numChanges = (issue.stances || []).map(stance => stance.diff).flat()
        .reduce((acc, diff) => {
          if(diff?.added || diff?.removed) {
            return acc + (diff?.count ?? 0);
          } else return acc;
        }, 0)
      return numChanges;
    })
    const numEditsMadeToBios = ['bioPersonal', 'bioProfessional', 'bioPolitical'].map(bioKey => {
      const diffForBio = diff[bioKey];
      return (diffForBio || []).reduce((acc, innerDiff) => {
        if(innerDiff?.added || innerDiff?.removed) {
          return acc + (innerDiff?.count ?? 0);
        } else return acc;
      }, 0);
    })

    numEditsMade = numEditsMadeToIssues.reduce((acc, num) => acc + num, 0) + numEditsMadeToBios.reduce((acc, num) => acc + num, 0);
  } else {
    const fieldsToCount = [
      'title',
      'descriptionShort',
      'ballotText',
      'actNumbers',
      'whatItMeans',
      'supportersSay',
      'opponentsSay',
    ]
    
    const numEditsMadeToFields = fieldsToCount.map(field => {
      const diffForField = diff[field];
      return (diffForField || []).reduce((acc, innerDiff) => {
        if(innerDiff?.added || innerDiff?.removed) {
          return acc + (innerDiff?.count ?? 0);
        } else return acc;
      }, 0);
    })

    numEditsMade = numEditsMadeToFields.reduce((acc, num) => acc + num, 0);
  }

  const displayGeneratedBy = generatedBy?.name || generatedBy?.firstName || 'Someone';

  const displayNoteAs =  note || `Made ${numEditsMade} edits`;

  return (
    <Tile
      disabled={disabled ? 1 : 0}
      onClick={disabled ? () => {} : () => history.push(toFullFeedback)}
      style={{ ...style, opacity: disabled ? 0.7 : 1.0 }}
    >
      <div style={{ display: 'flex', alignItems: 'stretch', flexDirection: 'column', gap: '12px', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', gap: '4px' }}>
          <Typography variant={'body2'} style={{ alignSelf: 'start'}}>{displayGeneratedBy} left feedback on {candidate?.name || measure?.title}</Typography>
          {
            !acknowledged && <NotificationBadge style={{ marginTop: '8px' }}/>
          }
        </div>
        <Typography variant={'body1'} style={{ fontSize: '20px', fontWeight: acknowledged ? 'normal' : 'bold', alignSelf: 'start'}}>
          {
            note ? <>
              "{displayNoteAs.slice(0,100)}{displayNoteAs?.length >= 100 && '...'}"
            </> : displayNoteAs
          }
        </Typography>
        <Typography variant={'body2'} style={{ alignSelf: 'start'}}>{moment.utc(createdAt).fromNow()}</Typography>
      </div>
    </Tile>
  );
};

const Tile = styled.div`
  height: calc(250px - 32px * 2);
  width: calc(280px - 24px * 2);
  min-height: calc(250px - 32px * 2);
  min-width: calc(280px - 24px * 2);
  background-color: linear-gradient(343deg, rgba(255,255,255,1) 56%, rgba(230,230,230,1) 100%)
  display: grid;
  cursor: ${props => props.disabled ? '' : 'pointer'};
  border: solid 1px #00000020;
  grid-template-rows: 100px 1fr;
  align-items: center;
  box-shadow: ${props => props.disabled ? 'none' : '0px 8px 17px #00000020'};
  border-radius: 8px;
  padding: 32px 24px;
  gap: 12px;
`

export { FeedbackPreviewTile };
