import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useLoadPaginatedFrontend } from '../../app/hooks/useLoadPaginatedFrontend';
import {
  Button,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 12px 0;

    h1 {
        font-size: 28px;
        margin: 0;
    }

    h2 {
        font-size: 18px;
        opacity: 0.6;
        letter-spacing: 2px;
        margin: 0;
    }
`;

const States = () => {
  const states = useLoadPaginatedFrontend(
    'districts',
    { type: 'state' },
    [],
    50,
  );

  const loading = !states?.entity?.length;
  const rows = loading
    ? Array.from({ length: 12 })
    : states.entity.sort((a, b) => a.name.localeCompare(b.name));

  return (
    <Wrapper>
      <Row>
        <h1>States</h1>
      </Row>
      <TableContainer component={Paper}>
        <Table size="medium">
          <TableHead>
            <TableRow
              sx={{
                'th': {
                  fontWeight: 'bold',
                  fontSize: '20px',
                },
              }}
            >
              <TableCell>State</TableCell>
              <TableCell style={{ textAlign: 'center' }}>
                District Configuration
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow
                key={`${index}`}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                  'textDecoration': 'none',
                }}
                component={Link}
                hover
                to={`/states/${row?.matchName}`}
              >
                <TableCell>
                  {
                    row
                      ? (
                        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                          {row.name}
                        </Typography>
                      )
                      : <Skeleton variant="text" width={150} />
                  }
                </TableCell>
                <TableCell style={{ textAlign: 'center' }}>
                  {
                    row
                      ?
                      <Link to={`/states/${row.matchName}/state-config`}>
                        <Button
                          variant="contained"
                          type="button"
                        >
                          Edit
                        </Button>
                      </Link>
                      :
                      <Skeleton variant="text" width={150} />
                  }
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Wrapper>
  );
};


const Wrapper = styled.div`
    width: calc(100% - 2 * 24px);
    display: flex;
    flex-direction: column;
    padding: 24px;
    align-items: stretch;
`;

export default States;
