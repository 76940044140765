import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { useFeathers } from '../../app/util';
import {
  CopyText,
  ReferenceEditor,
  TaskPage
 } from '../../components'
import styled from 'styled-components';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Link } from 'react-router-dom';


const FindWebsites = () => {
  const feathers = useFeathers();

  const [ task, setTask ] = useState(null);
  const [ error, setError ] = useState(false);
  const [ candidate, setCandidate ] = useState(null);
  const [ race, setRace ] = useState(null);
  const [ saving, setSaving ] = useState(false);
  const [ referencesInput, setReferencesInput ] = useState({});
  const [ completionData, setCompletionData ] = useState(null)

  const onCheckoutTask = async (task, loadNextTask) => {
    setReferencesInput({})
    setCandidate(null)
    setRace(null)
    
    setTask(task);

    let candidate; 
    try {
      candidate = await feathers.getService('candidates').get(task.details.candidate);
    } catch(err) {
      if(err.name === 'NotFound') {
        // bad candidate, we need to cancel this task
        console.log('Candidate not found')
        await feathers.getService('research-tasks').patch(task._id, {
          $cancelTask: true
        });
        setTask(null);
        loadNextTask()
        return
      } else {
        throw err;
      }
    }
    console.log(candidate)
    setCandidate(candidate);
    // seed inputs with most recently updated data from holdingData or candidate record
    if (task?.holdingData && task?.holdingData?.updatedAt > candidate?.stagingDraft?.updatedAt) setReferencesInput(task.holdingData)
    else if(candidate?.stagingDraft?.references) setReferencesInput(candidate.stagingDraft.references);

    // get race separately since the aggregated race object attached to candidate is missing some fields
    const raceToRef = candidate.race || candidate?.races?.[0];
    const race = await feathers.getService('races').get(raceToRef?._id);
    setRace(race)
  }

  useEffect(() => {
    setCompletionData(
      {
        categories: (referencesInput.categories || []).map(cat => ({
          ...cat,
          sources: cat.sources.filter(src => (src.url || (typeof src !== 'object' ? src : '') || '').trim().length > 0)
        }))
      }
    )
  }, [referencesInput])


  return (
    <TaskPage
      taskType='find-website'
      errorMessage={error?.message}
      onCloseErrorMessage={setError}
      onCheckoutTask={onCheckoutTask}
      completionData={completionData}
      holdingData={referencesInput}
      noMoreTasksMessage={'There are currently no more candidates to find websites for. Try back later.'}
      task={task}
      onChangeSaving={setSaving}
    >
      <WrapperInner>
        <div style={{ display: 'flex', alignItems: 'flex-end', gap: '8px'}}>
          <Typography variant='h3'>{candidate?.name}</Typography>
          <Link to={`/elections/${candidate?.election?.key || candidate?.election}/races/${candidate?.race?._id}/candidates/${candidate?._id}`} target='_blank'>
            <div style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}>
              <Typography variant='body2'>Full page</Typography>
              <OpenInNewIcon style={{ width: '15px', height: '15px' }}/>
            </div>
          </Link>
        </div>
        <Typography variant='body1' style={{ marginTop: '8px'}}>
            Priority: <b>{candidate?.priorityLevel || '--'}</b>
        </Typography>
        <Typography variant='body1' style={{ marginTop: '8px'}}>
          Running for {race?.office.name || ''} in {race?.district?.longName || ''}
        </Typography>
        <div style={{ marginTop: '8px' }}>
          <CopyText
            multiline
            text={`${candidate?.name} ${race?.longName || ''}`}
          />
        </div>
        <div style={{ padding: '36px', marginTop: '36px', borderRadius: '8px', backgroundColor: '#C3CEEC'}}>
          <ReferenceEditor
            priorityLevel={candidate?.priorityLevel}
            references={referencesInput}
            onChangeReferences={setReferencesInput}
            initialEditMode
            disabled={saving}
            candidateName={candidate?.name}
            autoScrapeSocials
            questionnaireEnabled={false}
            hideSubmitButton={true}
          />
        </div>
      </WrapperInner>
    </TaskPage>
  )
}

const WrapperInner = styled.div`
  max-width: 700px;
  display: flex;
  flex-direction: column;
  padding: 24px 24px 0;

  align-items: stretch;
`

export default FindWebsites;
