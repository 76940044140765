import React from 'react';
import styled from 'styled-components';
import { Tile } from '../lower-order';
import Typography from "@mui/material/Typography";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import CircularProgress from '@mui/material/CircularProgress';
import { green, red } from '@mui/material/colors';
import BlockIcon from '@mui/icons-material/Block';

const StatusTile = ({
  title,
  displayDetailLabel,
  statusOptions = [], // expect [{ label: string, status: { success/failure/error }}]
}) => {
  return (
    <Wrapper
    >
      <Inner>
        <div className='statusRow'>
          <Typography variant={'h3'} style={{ paddingBottom: '10px' }}>
            { title }
          </Typography>
        </div>
        {
          (statusOptions || []).map((option, i) => (
            <div className='statusRow' key={option.label}>
              <div style={{ display: 'flex', flexDirection: 'column', }}>
                <Typography variant={'body1'} style={displayDetailLabel ? { fontWeight: 'bold'} : {}}>
                  { option.primaryLabel }
                </Typography>
                {
                  displayDetailLabel &&
                  <Typography variant={'body2'}>
                    { option.label }
                  </Typography>
                }
              </div>
              <Status status={option.status}/>
            </div>
          ))
        }
      </Inner>
    </Wrapper>
  )
}

const Status = ({ status }) => {
  if (!status) {
    return <CircularProgress size={20} color='secondary' />
  } else if (status.success) {
    return <CheckCircleIcon style={{ color: green[500] }} />
  } else if (status.failure) {
    return <ErrorIcon style={{ color: red[800] }} />
  } else if (status.error) {
    return <BlockIcon style={{ color: red[800] }} />
  }
}


const Inner = styled.div`
  width: calc(100%);
  display: flex;
  flex-direction: column;
  padding: 0px
  justify-content: space-between;
  height: calc(100%);


  .statusRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;

const Wrapper = styled.div``

export { StatusTile };
