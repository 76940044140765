import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import moment from 'moment';
import { ReactComponent as GlobeIcon } from './globe-icon-purple.svg';
import { LanguageSelectModal } from '../LanguageSelectModal';
import Modal from '@mui/material/Modal';

const LanguageSelectButton = ({
  onSelect,
  selected,
  style,
  className
}) => {
  const [ modalOpen, setModalOpen ] = useState(false);

  return (<>
    <IconButton
      style={style}
      className={className}
      data-tip='Translate'
      onClick={() => setModalOpen(true)}
      size="large">
      <GlobeIcon style={{ width: '24px', height: '24px' }}/>
    </IconButton>
    <Modal
      open={modalOpen}
      onClose={() => setModalOpen(false)}
    >
      <LanguageSelectModal
        selected={selected}
        onSelect={(ln) => {
          onSelect(ln)
          setModalOpen(false)
        }}
        onClose={() => setModalOpen(false)}
      />
    </Modal>
  </>);
}

export { LanguageSelectButton };
