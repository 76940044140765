import React, { useState , useMemo, useEffect } from 'react'
import styled from 'styled-components';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { EditStance } from '../EditStance';
import AddIcon from '@mui/icons-material/Add';
import Visibility from '@mui/icons-material/Visibility';
import { Button } from '@mui/material';
import { Skeleton } from '@mui/material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { DragIndicator } from '@mui/icons-material';


const noDataOptionsStatic = {
  'no-public-info': 'There is little to no public information on this candidate.',
  'no-response': 'Candidate has no website and has not responded to the questionnaire.',
  'issue-specific': 'Website does not mention this specific information.'
}

const issueSkipRerender = (prevProps, nextProps) => {
  const activeStanceId = [nextProps.activeStanceId, prevProps.activeStanceId];
  const allStanceIds = nextProps.value?.stances?.map(stance => stance._id) || [];
  const isActive = activeStanceId.some(id => allStanceIds.includes(id));

  if(!isActive) {
    // Generally, we want to avoid all rerendering of issues that are not active
    // except for a few key cases

    // if we recategorize a stance from one issue category to another, we want to render
    if(prevProps.value?.stances?.length !== nextProps.value?.stances?.length) {
      return false;
    }

    // if our sources change, we want that to have a visible effect on everything.
    if(prevProps.possibleSources?.length !== nextProps.possibleSources?.length) {
      return false;
    }
    if(prevProps.hasWebsite !== nextProps.hasWebsite) {
      return false;
    }

    // in the event of an error, we want to rerender the stances.
    if(nextProps.value?.stances?.some(s => s.hasError)) {
      return false;
    }

    // in the event that the missing info has changed, we want to rerender
    if(prevProps.value?.missingData !== nextProps.value?.missingData) {
      return false;
    }

    return true;
  }

  // todo: probably want to optimize this to only rerender active issue if props have changed (default behavior)
  return false;
}

const EditIssue = React.forwardRef(({
  issueName,
  value,              // expected to be an object containing properties: text, sources, and missingData
  onChange,
  onReassignStance,
  candidateName,

  activeStanceId,
  onFocusStance,
  enableScrollToActive,
  detailedIssue,        // the data from the issues service

  possibleSources,
  issueOptions,
  disabled,
  style,
  className,
  hasWebsite
}, inputRef) => {

  const [ uselessStancesShowing, setUselessStancesShowing ] = useState(false);

  const noDataOptions = useMemo(() => {
    const keys = hasWebsite ? ['issue-specific'] : ['no-response', 'no-public-info'];
    return keys.reduce((acc, key) => {
      acc[key] = noDataOptionsStatic[key];
      return acc;
    }, {})
  }, [ hasWebsite ])

  const checkBox = (e) => {
    onChange({ ...value, missingData: e.target.checked ? null : Object.keys(noDataOptions)[0] })
  }

  const onChangeMissingReason = (e) => {
    onChange({ ...value, missingData: e.target.value })
  }

  useEffect(() => {
    if(value.missingData) {
      if(!noDataOptions[value.missingData]) {
        onChange({ ...value, missingData: Object.keys(noDataOptions)[0] })
      }
    }
  }, [ hasWebsite, value?.missingData, noDataOptions ])

  const onChangeStance = (newStance) => {
    const stancesWithNewStance = value.stances.map((stance) => {
      if (stance._id === newStance._id) {
        return newStance
      }
      return stance
    })

    // Do some reording, putting usefulness of 0's at the end and hiding them
    const stancesWithNewStanceSorted = stancesWithNewStance.sort((a, b) => {
      if (a.usefulness === 0 && b.usefulness !== 0) {
        return -1
      } else if (a.usefulness !== 0 && b.usefulness === 0) {
        return 0
      } else return 0;
    })
    onChange({ ...value, stances: stancesWithNewStanceSorted, hasError: false })
  }
  const onAddStance = () => {
    // For adding a custom stance, not from the profiler
    const idNew = `NEW-${Date.now()}`;
    onChange({ ...value, stances: [...value.stances, { _id: idNew, text: '', sources: [], autoSummary: false }] })
    onFocusStance(null, idNew)
  }

  const [ delayTrigger, setDelayTrigger ] = useState(false);
  useEffect(() => {
    if(delayTrigger) {
      setDelayTrigger(false);
      onFocusStance(null, delayTrigger)
    }
  }, [delayTrigger])
  const onCopyStance = (existingStance) => {
    const idNew = `NEW-${Date.now()}`;
    onChange({ ...value, stances: [...value.stances, { ...existingStance, _id: idNew, copiedStance: true }] })
    setDelayTrigger(idNew)
  }

  const uselessStances = useMemo(() => value.stances.filter(stance => stanceIsUsless(stance)), [value])
  const usefulStances = useMemo(() => value.stances.filter(stance => !stanceIsUsless(stance)), [value])

  const stancesDisplay = useMemo(() => {
    return [...usefulStances, ...(uselessStancesShowing ? uselessStances : []) ]
  }, [uselessStances, usefulStances, uselessStancesShowing])


  const handleOnDragEnd = (result) => {
    if(result?.reason !== 'DROP' || !result?.destination) return;
    let source = result.source.index;
    let target = result.destination.index;

    // newStances array is reordered in place to move the source to the target
    const newStances = [...stancesDisplay];

    // remove the source
    const removed = newStances.splice(source, 1);
    // splice the source to the target location
    newStances.splice(target, 0, removed[0]);

    onChange({ ...value, stances: [...newStances] })
  }
  
  return (
    <Wrapper style={style} className={className}>
      <div ref={inputRef} style={{ scrollMarginTop: '60px', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
        <Typography variant={'h2'} >{issueName}</Typography>
        <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
          <Typography variant='body2'>Has information</Typography>
          <Checkbox
            size='small'
            checked={!Boolean(value.missingData)}
            onChange={checkBox}
            inputProps={{ 'aria-label': 'primary checkbox' }}
            style={{ marginRight: '-12px'}}
          />
        </div>
      </div>
      {
        (detailedIssue && !detailedIssue.error) &&
        <div style={{ marginTop: '-24px'}}>
          {detailedIssue?.loading && <Skeleton variant='text' width={200} height={24} />}
          {detailedIssue?.subtopics && <Typography variant='body2'>Includes {detailedIssue.subtopics}</Typography>}
        </div>
      }
      {
        value.missingData &&
        <FormControl>
          <InputLabel>Reason</InputLabel>
          <Select
            variant='outlined'
            size='small'
            value={value?.missingData}
            onChange={onChangeMissingReason}
          >
            {
              Object.keys(noDataOptions).map(option => (
                <MenuItem key={option} value={option}>{noDataOptions[option]}</MenuItem>
              ))
            }
          </Select>
        </FormControl>
      }
      {
        !value.missingData &&
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId='inputListItem'>
            { (provided) => (
              <StanceWrapper {...provided.droppableProps} ref={provided.innerRef} >
                {
                  stancesDisplay.map((stance, i) => (
                    <div key={`issue-${issueName}-stance-${i}`}>
                      <Draggable
                          draggableId={`issue-${issueName}-stance-${i}`}
                          index={i}
                          isDragDisabled={false}
                        >
                          { (provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              isDragging={snapshot.isDragging}
                            >
                              <div style={{ display: 'flex', gap: '8px', alignItems: 'center', width: 'calc(100% + 24px)'}}>
                                <div 
                                  style={{ 
                                    width: '30px', 
                                    height: '30px', 
                                    ...((activeStanceId === stance._id && !snapshot.isDragging) ? { marginLeft: '-52px', marginRight: '30px' } : { marginLeft: '-40px', marginRight: '8px' }),
                                  }} 
                                  {...provided.dragHandleProps}
                                >
                                  <DragIndicator style={{ color: '#CCCCCC', width: '100%', height: '100%'}}/>
                                </div>
                                <EditStance
                                  candidateName={candidateName}
                                  key={`issue-${issueName}-stance-${i}`}
                                  value={stance}
                                  onChange={(newStance) => {
                                    onChangeStance(newStance)
                                  }}
                                  issueKey={value?.key}
                                  issueOptions={issueOptions}
                                  isFromProfiler={stance.autoSummary?.isAutoSummary}
                                  onReassignIssue={(issueKeyNew) => onReassignStance(issueKeyNew, stance)}
                                  onCopyStance={onCopyStance}
                                  onFocus={(e) => onFocusStance(e, stance._id)}
                                  active={snapshot.isDragging ? false : activeStanceId === stance._id}
                                  onRemoveStance={() => onChange({ ...value, stances: value.stances.filter((stanceToRemove, j) => stanceToRemove._id !== stance._id) })}
                                  shouldAutoFocus={activeStanceId === stance._id && !Boolean(stance.autoSummary)}
                                  possibleSources={possibleSources}
                                  scrollToOnActive={enableScrollToActive}
                                  style={snapshot.isDragging ? { backgroundColor: '#FFFFFF', opacity: 1.0} : {}}
                                />
                              </div>
                            </div>
                          )}
                        </Draggable>
                    </div>
                  ))
                }
              </StanceWrapper>
            )}
          </Droppable>
        </DragDropContext>
      }
      {
        !value.missingData &&
        <div style={{ display: 'flex', alignItems: 'center', gap: '24px'}}>
          <Button
            variant='text'
            size='small'
            startIcon={<AddIcon style={{ marginTop: '-4px'}}/>}
            onClick={onAddStance}
          >
            Add stance
          </Button>
          {
            uselessStances.length > 0 &&
            <Button
              variant='text'
              size='small'
              startIcon={<Visibility style={{ marginTop: '-4px'}}/>}
              onClick={() => setUselessStancesShowing(!uselessStancesShowing)}
            >
              { uselessStancesShowing ? 'Hide' : 'Show' } useless stances
            </Button>
          }
        </div>
      }
    </Wrapper>
  )
})

const stanceIsUsless = (stance) => {
  return stance.usefulness === 0 || (stance.redundant && typeof(stance.usefulness) !== 'undefined')
}

const EditIssueMemoized = React.memo(EditIssue, issueSkipRerender)


export { EditIssue, EditIssueMemoized };


const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  gap: 24px;
  scroll-margin-top: 56px;
`

const StanceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 24px;
  width: 100%;
`