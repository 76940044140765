import React, { Component, useEffect, useState } from 'react';
import { useFeathers, isPermitted } from '../../app/util';
import { ReactComponent as GreenCheckSvg } from '../../images/green-check.svg';
import styled from 'styled-components';
import { TextField,
   Typography,
   Button
  } from '@mui/material';

import useKeypress from "react-use-keypress";

import SelectPermissions from './SelectPermissions';


const SendInvitationModal = ( { invitationUser, refreshUsers }) => {
  const feathers = useFeathers()
  const [ userEmail, setUserEmail ] = useState(invitationUser?.email || '')
  const [ userPermission, setUserPermission ] = useState(invitationUser?.permissions[0] || 'researcher')
  const [ sending, setSending ] = useState(false)
  const [ sent, setSent ] = useState(false)
  const [ error, setError ] = useState('');
  const submittable = userEmail?.length > 5;



  const sendInvitation = async() => {
    if(!submittable) return;
    try {
      setSending(true)
      await feathers.getService('invite-links').create({
        email: userEmail,
        starterPermissions: [ userPermission ]
      })
      setSending(false)
      setSent(true)
      refreshUsers()
    } catch (err) {
      setError(err.message )
    }
  }

  const handlePermissionsChange = (e) => {
    setUserPermission(e.target.value)
  }



  const reset = () => {
    setUserEmail('')
    setUserPermission('researcher')
    setError('')
    setSent(false)
  }

  useKeypress('Enter', () => {
    if(submittable && !sending && !sent) sendInvitation()
    if(sent) reset()
  });

  if(sent) {
    return (
      <SendInvitationWrapper>
        <SavedWrapper>
          <GreenCheck/>
          <Typography>Invitation sent to { userEmail }!</Typography>
        </SavedWrapper>
        {
          !invitationUser &&
          <Button onClick={reset} variant={'contained'} color={'primary'}>
            Send another
          </Button>
        }
      </SendInvitationWrapper>
    )
  }

  return (
    <>
        <SendInvitationWrapper>
          <Typography variant="h2">{invitationUser ? 'Resend Invite' :'Invite User'}</Typography>
          {
            !invitationUser
            ?
            <>
              <TextField
                label='Email'
                value={ userEmail }
                onChange={ (e) => setUserEmail(e.target.value) }
              ></TextField>
              <Typography variant="h4">Access Level</Typography>
              <SelectPermissions
                handlePermissionsChange={handlePermissionsChange}
                userPermission={userPermission}
              />
            </>
           :
           <>
            <Typography >Resend Invitation to { userEmail }?</Typography>
            <Typography >Access Level:  { userPermission}</Typography>
           </>
          }
          {
            !sending
              ?
              <Button
                  variant={'contained'}
                  color={'primary'}
                  disabled={!submittable || sending}
                  onClick={sendInvitation}
                >Send Invitation
              </Button>
              :
              <Typography style={{color: error ? 'red' : '' }}>
                {error ? error : 'Sending....'}
              </Typography>
          }
        </SendInvitationWrapper>

    </>
  )
}

const SendInvitationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 60px;
  justify-content: space-between;
  gap: 30px;
  width: 350px;

  .MuiInput-root {
    border-bottom: 1px solid #22034F !important;
    color: #313639
  }
`

const GreenCheck = styled(GreenCheckSvg)`
  width: 25px;
  height: auto;
`

const SavedWrapper = styled.div`
  display: flex;
  gap: 10%;
  justify-content: center;
  align-items: center;
`

  export default SendInvitationModal;
