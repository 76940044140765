import React, { useState, useEffect, useCallback } from 'react';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import { Typography, Button } from '@mui/material';
import { Alert } from '@mui/material';
import { TextInput } from '../TextInput';
import { useFormInputs } from '../../app/util';

import {Modal} from "../Modal";


const AddMeasureSource = ({
  onAdd,
  style, 
  className
}) => {
  const [ showAddSourceModal, setShowAddSourceModal ] = useState(false);
  const [ error, setError ] = useState(false);

  const [ formInputs, onChangeFormInputs, onReset ] = useFormInputs({
    url: '',
    title: '',
  })

  const onAddInternal = ({ url, title }) => {
    const convertedToSource = {
        url,
        title,
        sourceType: 'other'
    }
    onAdd(convertedToSource)
    setShowAddSourceModal(false)
    onReset()
  }

  return (
    <div style={style} className={className}>
        <Button startIcon={<AddIcon/>} onClick={() => setShowAddSourceModal(true)}>Add source</Button>
        {
            showAddSourceModal &&
            <Modal
            onClose={() => setShowAddSourceModal(null)}
            open={showAddSourceModal}
            style={{
                minHeight: 'auto',
                width: '400px'
            }}
            >
                <div style={{ display: 'grid', gridTemplateColumns: '1fr', gridGap: '24px' }}>
                    <Typography variant={'h2'}>New source</Typography>
                    {
                        error &&
                        <Alert severity="error">{error}</Alert>
                    }
                    <TextInput
                            onChange={onChangeFormInputs}
                            value={formInputs.title}
                            name={'title'}
                            placeholder='Source Title'
                            mode='condensed'
                            style={{
                                fontSize: '16px',
                                width: 'calc(100% - 15px * 2)'
                            }}
                        />
                    <TextInput
                        onChange={onChangeFormInputs}
                        value={formInputs.url}
                        name={'url'}
                        placeholder='URL (https://example.com)'
                        mode='condensed'
                        style={{
                        fontSize: '16px',
                        width: 'calc(100% - 15px * 2)'
                        }}
                    />
                    <Button
                        variant={'contained'}
                        color={'primary'}
                        onClick={() => onAddInternal(formInputs)}
                    >
                        Add source
                    </Button>
                </div>
            </Modal>
        }
    </div>
  )
}

export { AddMeasureSource };
