import React from 'react';
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";

const SwitchControl = ({
  style,
  missing,
  displayName,
  setMissingTo,
  children,
}) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch', ...(style || {})}}>
      <RadioGroup
        name="missing-info"
        value={missing ? 'no-data' : 'data'}
        onChange={(e) => setMissingTo(e?.target?.value === 'no-data')}
        row={true}
        color={'secondary'}
        style={{ marginBottom: '8px', display: 'flex', alignItems: 'center' }}
      >
        <FormControlLabel value="data" control={<Radio />} label={`${displayName} available`}  />
        <FormControlLabel value="no-data" control={<Radio style={{ marginLeft: '24px' }}/>} label={`No ${displayName}`} />
      </RadioGroup>
      {
        !missing &&
        children
      }
    </div>
  )
}

export { SwitchControl };