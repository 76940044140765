import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button, Checkbox, IconButton, Modal, Select, Skeleton, TextField, Tooltip, Typography } from '@mui/material';
import moment from 'moment';
import {
  Add as AddIcon,
  Check as CheckIcon,
  Edit as EditIcon,
  InfoOutlined,
  DeleteOutline as DeleteIcon,
} from '@mui/icons-material';
import MenuItem from '@mui/material/MenuItem';
import { useFeathers } from '../../app/util';
import { DataDetailToolbar, ModalInner } from '../../components';
import a from 'superagent/lib/utils';

const StateConfig = () => {
  const matchName = useParams().matchName;
  const feathers = useFeathers();
  const history = useHistory();
  const [stateConfig, setStateConfig] = useState(null);
  const [districtTypesByTier, setDistrictTypesByTier] = useState(null);
  const [loading, setLoading] = useState(true);
  const [editing, setEditing] = useState(false);
  const [addingSubtype, setAddingSubtype] = useState(false);
  const [lastEditedByUserName, setLastEditedByUserName] = useState(null);

  const loadStateConfig = async () => {
    const stateCode = matchName.split('-')[0];
    try {
      const [stateConfig, districtConfigs] = await Promise.all([
        feathers.getService('state-configurations').get(stateCode),
        feathers.getService('district-configurations').find(),
      ]);
      if (stateConfig.editedByUser) {
        try {
          const lastEditedByUser = await feathers.getService('users')
            .get(stateConfig.editedByUser);
          setLastEditedByUserName(lastEditedByUser.name);
        } catch {
        }
      }

      setStateConfig(stateConfig);
      setDistrictTypesByTier(districtConfigs);

      const hasSubtypesConfigured = stateConfig.typeConfigurations.length > 2;
      // Default to editing if nothing is configured yet
      setEditing(!hasSubtypesConfigured);
      setLoading(false);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (feathers) {
      loadStateConfig();
    }
  }, [feathers, useParams]);

  // input functions
  const onChangePrettyName = (type, prettyName) => {
    onChangeRowValue(type, 'prettyName', prettyName);
  };

  const onChangeRowValue = (type, key, value) => {
    const newTypeConfigurations = stateConfig.typeConfigurations.map(t => {
      if (t.type === type) {
        return {
          ...t,
          [key]: value,
        };
      }
      return t;
    });
    setStateConfig({
      ...stateConfig,
      typeConfigurations: newTypeConfigurations,
    });
  };

  const onSave = async () => {
    await feathers.getService('state-configurations').patch(
      stateConfig.stateCode,
      {
        typeConfigurations: stateConfig.typeConfigurations,
        typeStructure: stateConfig.typeStructure,
      },
    );
    setEditing(false);
  };

  const addSubtype = (parentType, subtype) => {
    // called once we have  type
    // add a new subtype to the parent type
    const newTypeHierarchy = {
      ...stateConfig.typeStructure,
      [parentType]: [
        ...(stateConfig.typeStructure?.[parentType] || []),
        subtype,
      ],
    };

    const defaultConfigForType = {
      type: subtype,
      tierNumber: parentType === 'state' ? 2 : 3,
      optionalForStructure: false,
      requiredForFullMatch: 'yes',
      supportsMeasures: ['city', 'county'].includes(subtype),
    };

    const newTypeConfigurations = [
      ...stateConfig.typeConfigurations,
      defaultConfigForType,
    ];

    setStateConfig({
      ...stateConfig,
      typeStructure: newTypeHierarchy,
      typeConfigurations: newTypeConfigurations,
    });
    setAddingSubtype(false);
  };

  const rows = loading
    ? Array.from({ length: 6 })
    : (stateConfig?.typeConfigurations || [])
      .filter(t => {
        if(!districtTypesByTier) return false;
        const type = t.type;
        const isTier2 = districtTypesByTier['state'].includes(type);
        return isTier2;
      })
      .sort((a, b) => a.type.localeCompare(b.type));

  const subtypeOptions = addingSubtype
    ? districtTypesByTier[addingSubtype]
      .filter(t => !(stateConfig.typeStructure?.[addingSubtype] || []).includes(t))
      .sort()
    : null;

  let logicalRowIndex = 0;
  const backgroundColorsAlternating = ['#F9F9F9', '#FFFFFF'];

  const humanizeType = (type) => {
    return type
      .replaceAll('us-', 'US-')
      .replaceAll('-', ' ');
  };

  const cancelEdit = () => {
    loadStateConfig();
  };


  const deleteType = (typeConfig) => {
    const typesToRemove = [
      typeConfig.type,
      ...(stateConfig.typeStructure?.[typeConfig.type] || []),
    ];
    const typeConfigurations = stateConfig.typeConfigurations
      .filter(({ type }) => !typesToRemove.includes(type));
    const typeStructure = Object.fromEntries(
      Object.entries(stateConfig.typeStructure)
        .filter(([type,]) => !typesToRemove.includes(type))
        .map(([type, subTypes]) => ([
          type,
          subTypes.filter(subType => !typesToRemove.includes(subType)),
        ])),
    );
    setStateConfig({
      ...stateConfig,
      typeConfigurations,
      typeStructure,
    });
  };

  return (
    <Wrapper>
      <DataDetailToolbar
        navTree={[
          { text: 'Districts', to: '/states' },
          { text: stateConfig?.stateName || '--', to: `/states/${matchName}` },
          { text: 'Edit configuration' },
        ]}
        onBack={() => history.push(`/states/${matchName}`)}
        actionButtonsComponent={
          editing
            ?
            <>
              <Button
                variant="text"
                sx={{ marginRight: '12px' }}
                onClick={() => cancelEdit()}>
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={onSave}>
                Save
              </Button>
            </>
            : <IconButton
              onClick={() => setEditing(true)}
            >
              <EditIcon />
            </IconButton>
        }
      />
      <div style={{ height: '36px' }} />
      {
        !editing &&
        <Typography variant="body1" style={{ marginBottom: '12px' }}>
          Last edited
          {
            lastEditedByUserName ? ` by ${lastEditedByUserName}` : ''
          } on {
          stateConfig && moment(stateConfig.updatedAt).format('LLL')
        }
        </Typography>
      }
      <TableContainer
        component={Paper}
        style={{ maxWidth: '1000px' }}
      >
        <Table size="small">
          <TableHead>
            <TableRow
              sx={{
                '*': {
                  fontWeight: 'bold',
                  whiteSpace: 'nowrap',
                },
              }}>
              <TableCell style={{ width: '50%' }}>
                <div>
                  Type
                </div>
              </TableCell>
              <TableCell style={{ width: '50%' }}>
                <div>
                  Pretty name
                </div>
              </TableCell>
              <TableCell>
                <div
                  style={{ display: 'flex', alignItems: 'center', gap: '4px' }}
                >
                  Standard
                  <Tooltip
                    title={`Determines whether this should be researched for
                     every parent district. If this is a rare or infrequent
                      type, leave this unchecked.`}>
                    <InfoOutlined style={{ fontSize: '16px' }} />
                  </Tooltip>
                </div>
              </TableCell>
              <TableCell>
                <div
                  style={{ display: 'flex', alignItems: 'center', gap: '4px' }}
                >
                  Required for match
                  <Tooltip
                    title={`Is every person living in the state expected to 
                    have at least one of this district type?`}>
                    <InfoOutlined style={{ fontSize: '16px' }} />
                  </Tooltip>
                </div>
              </TableCell>
              <TableCell>
                <div
                  style={{ display: 'flex', alignItems: 'center', gap: '4px' }}
                >
                  Referendums
                  <Tooltip
                    title={`Determined whether referendums can be added for 
                    this district type.`}>
                    <InfoOutlined style={{ fontSize: '16px' }} />
                  </Tooltip>
                </div>
              </TableCell>
              {
                editing && <TableCell style={{ fontWeight: 'bold' }}>
                  Actions
                </TableCell>
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => {
              if (!row) {
                return (
                  <TableRow
                    key={`${index}`}
                    style={{
                      backgroundColor: backgroundColorsAlternating[+(index % 4 > 1)],
                    }}
                  >
                    {
                      Array.from({ length: 5 }).map((_, column) => (
                        <TableCell
                          key={column}
                          style={{
                            border: index % 2 ? null : 'none',
                            paddingTop: index % 2 ? 0 : null,
                            paddingBottom: index % 2 ? null : 0,
                            paddingLeft: !column && index % 2 ? '48px' : '16px',
                          }}
                        >
                          <Skeleton
                            variant="text"
                            height={64}
                            width={column < 2 ? 96 : 36}
                            style={{
                              marginLeft: column > 1 ? 'auto' : null,
                              marginRight: column > 1 ? 'auto' : null,
                            }}
                          />
                        </TableCell>
                      ))
                    }
                  </TableRow>
                );
              }

              const childrenTypes = districtTypesByTier[row.type] || [];
              const childrenConfigs = stateConfig?.typeConfigurations
                ?.filter(t => childrenTypes.includes(t.type)) || [];
              const childrenTypesNotAdded = childrenTypes
                .filter(t => !childrenConfigs.map(c => c.type).includes(t));
              const displayNoChildrenMessage = (
                !editing
                && childrenTypes.length > 0
                && childrenConfigs.length === 0
              );
              const displayAddChildButton = (
                editing
                && childrenTypesNotAdded?.length > 0
              );
              const hasFooterSection = (
                displayNoChildrenMessage
                || displayAddChildButton
              );

              return (
                <React.Fragment key={`${index}`}>
                  {
                    [row, ...childrenConfigs].map((row, i, arr) => {
                      const isChildRow = childrenTypes.includes(row.type);
                      if (!isChildRow) {
                        logicalRowIndex++;
                      }
                      return (
                        <TableRow
                          key={`${index}`}
                          sx={{
                            '& > *': {
                              backgroundColor: backgroundColorsAlternating[logicalRowIndex % 2],
                              paddingBottom: '18px',
                              paddingTop: i === 0 ? '18px' : null,
                              // control the border display, only display at the end of each group
                              ...(
                                !(i === (arr.length - 1) && !hasFooterSection) ? {
                                  borderBottom: 'unset',
                                  paddingBottom: '18px',
                                } : {}
                              ),
                            },
                          }}
                        >
                          <TableCell
                            scope="row"
                            style={{ paddingLeft: isChildRow ? '48px' : '16px' }}
                          >
                            <Typography
                              variant="body1"
                              sx={{ fontWeight: 'bold' }}
                              style={{ textTransform: 'capitalize' }}
                            >
                              {humanizeType(row.type)}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            {
                              editing
                                ? (
                                  <TextField
                                    variant="standard"
                                    fullWidth={true}
                                    value={row.prettyName || ''}
                                    size="small"
                                    onChange={(e) => {
                                      onChangePrettyName(row.type, e.target.value);
                                    }}
                                  />
                                )
                                : (row.prettyName || '')
                            }
                          </TableCell>
                          <TableCell style={{ textAlign: 'center' }}>
                            {
                              childrenTypes.includes(row.type) ? (
                                editing
                                  ?
                                  <Checkbox
                                    size="small"
                                    checked={!row.optionalForStructure}
                                    style={{ padding: 0 }}
                                    onChange={(e) => {
                                      onChangeRowValue(
                                        row.type,
                                        'optionalForStructure',
                                        !row.optionalForStructure,
                                      );
                                    }}
                                  />
                                  : (
                                    !row.optionalForStructure
                                      ? <CheckIcon />
                                      : null
                                  )
                              ) : null
                            }
                          </TableCell>
                          <TableCell style={{ textAlign: 'center' }}>
                            {
                              editing
                                ?
                                <Select
                                  size="small"
                                  value={row.requiredForFullMatch}
                                  onChange={(e) => onChangeRowValue(
                                    row.type,
                                    'requiredForFullMatch',
                                    e.target.value,
                                  )}
                                >
                                  <MenuItem value="yes">Yes</MenuItem>
                                  <MenuItem value="no">No</MenuItem>
                                </Select>
                                : (
                                  <>
                                    {
                                      row.requiredForFullMatch === 'no'
                                        ? null
                                        : <CheckIcon />
                                    }
                                    {
                                      row.requiredForFullMatch
                                        ?.startsWith('requiredWithout')
                                        ? (
                                          <Typography variant="body2">
                                            At least {row.requiredForFullMatch.split(':')[1]} or {row.type} is required.
                                          </Typography>
                                        )
                                        : null
                                    }
                                  </>
                                )
                            }
                          </TableCell>
                          <TableCell style={{ textAlign: 'center' }}>
                            {
                              editing
                                ?
                                <Checkbox
                                  size="small"
                                  checked={row.supportsMeasures}
                                  style={{ padding: 0 }}
                                  onChange={(e) => {
                                    onChangeRowValue(
                                      row.type,
                                      'supportsMeasures',
                                      !row.supportsMeasures,
                                    );
                                  }}
                                />
                                : (
                                  row.supportsMeasures
                                    ? <CheckIcon />
                                    : null
                                )
                            }
                          </TableCell>
                          {
                            editing &&
                            <TableCell>
                              <IconButton
                                color="error"
                                onClick={() => deleteType(row)}
                              >
                                <DeleteIcon style={{ marginBottom: '2px' }} />
                              </IconButton>
                            </TableCell>
                          }
                        </TableRow>
                      );
                    })
                  }
                  {
                    hasFooterSection &&
                    <TableRow key={`${index}-subtype`}>
                      <TableCell
                        style={{
                          paddingTop: '16px',
                          paddingBottom: '16px',
                          paddingLeft: '48px',
                        }}
                        colSpan={5}
                      >
                        <div style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '12px',
                        }}>
                          {
                            displayNoChildrenMessage &&
                            <Typography variant="body2">
                              No subtypes added.
                            </Typography>
                          }
                          {
                            childrenTypesNotAdded?.length > 0 && editing &&
                            <Button
                              variant="contained"
                              size="small"
                              onClick={() => setAddingSubtype(row.type)}
                              startIcon={
                                <AddIcon style={{ marginBottom: '2px' }} />
                              }
                            >
                              Add subtype
                            </Button>
                          }
                        </div>
                      </TableCell>
                    </TableRow>
                  }
                </React.Fragment>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {
        editing &&
        <div style={{ marginTop: '24px' }}>
          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={() => setAddingSubtype('state')}
            startIcon={
              <AddIcon style={{ marginBottom: '2px' }} />
            }
          >
            Add type
          </Button>
        </div>
      }
      <Modal
        open={Boolean(addingSubtype)}
        onClose={() => setAddingSubtype(false)}
      >
        <ModalInner onClose={() => setAddingSubtype(false)}>
          <Typography variant="h3">Add subtype</Typography>
          <div style={{ height: '24px' }} />
          <Select
            variant="outlined"
            label="Select subtype"
            value={''}
            onChange={(e) => addSubtype(addingSubtype, e.target.value)}
          >
            {
              (subtypeOptions || []).map(t => (
                <MenuItem
                  key={t}
                  value={t}
                  style={{ textTransform: 'capitalize' }}
                >
                  {humanizeType(t)}
                </MenuItem>
              ))
            }
          </Select>
          <div style={{ height: '24px' }} />
        </ModalInner>
      </Modal>
    </Wrapper>
  );
};


const Wrapper = styled.div`
    width: calc(100% - 2 * 24px);
    display: flex;
    flex-direction: column;
    padding: 24px;
    align-items: stretch;
`;

export default StateConfig;
