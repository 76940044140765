import React from 'react';
// import styles from './ElectionResults.css';
import styled from 'styled-components';
import moment from 'moment';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { CirclePhoto } from '../CirclePhoto';
import OpponentsX from '../../images/opponents-x.png';
import SupportersCheck from '../../images/supporters-check.png';

const ElectionResultsGraph = ({
  result,
  candidates,     // don't need to provide if this is a measure
  textResults,
  notifications = true,
  detailed
}) => {
  const { ballotCreated, raceChoices } = useSelector(state => (state.ballot || {}));
  const accountCreated = useSelector(state => state.user?.accountCreated);
  const raceMode = !result?.yes;
  if(!result || !result?.lastUpdated || (raceMode && !result?.byCandidate)) return null;

  const {
    byCandidate,
    lastUpdated,
    yes,
    no
  } = result;

  let mappedResults;
  if(raceMode) {
    mappedResults = Object.entries(byCandidate || {}).map(([ key, value ]) => {
      const { votingShare, updatedAt, totalVotes, status } = value;
      const cdOpts = (candidates || []).filter(cd => cd?._id === key);
      const chosen = (raceChoices || []).some(choice => (choice?.candidate || []).includes(key))
      return {
        candidate: cdOpts?.length ? cdOpts[0] : undefined,
        chosen,
        votingShare,
        updatedAt,
        status,
        totalVotes
      }
    })
  } else {
    mappedResults = [
      {
        measureOption: 'yes',
        chosen: false,
        votingShare: yes.votingShare || -1,
        updatedAt: yes.updatedAt,
        totalVotes: yes.totalVotes
      },
      {
        measureOption: 'no',
        chosen: false,
        votingShare: no.votingShare || -1,
        updatedAt: no.updatedAt,
        totalVotes: no.totalVotes
      }
    ]
  }
  mappedResults.sort((a, b) => (b?.votingShare || 0) - (a?.votingShare || 0))

  const maxResult = Math.max(...mappedResults.map(r => r.votingShare))
  const resultsToPrint = mappedResults.filter(r => Boolean(r?.candidate?.name));

  return (
    <Wrapper>
      {
        (result?.goingToRunoff) &&
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', marginTop: '12px'}}>
          <Typography variant='h4' style={{ fontWeight: 'bold', letterSpacing: '0.5px' }}>Runoff election – November 30th</Typography>
          {
            notifications && !accountCreated &&
            <Link to='/create-account'>
              <u>
                <Typography variant='body1' style={{ marginLeft: '6px', fontSize: '13px', marginBottom: '-1px' }}>Get notified</Typography>
              </u>
            </Link>
          }
        </div>

      }
      <BarWrapper statusenabled={false ? 1 : 0} photoenabled={1} numRows={mappedResults.length}>
        {mappedResults.map((r, idx) => (<Bar
          key={idx}
          index={idx}
          measureOption={r?.measureOption}
          candidate={r.candidate}
          result={r?.votingShare}
          chosen={r?.chosen}
          status={r?.status}
          totalVotes={r?.totalVotes}
          maxResult={maxResult}
          detailed={detailed}
        />))}
      </BarWrapper>
      {
        textResults && raceMode &&
        <Typography variant='body1' color='textPrimary' style={{ marginBottom: '8px'}}>
          Currently, {resultsToPrint.filter(r => r?.votingShare >= 0).map(r => `${r.candidate.name} has ${r?.votingShare}% of the votes`).join(', ')}.
          {` `}{resultsToPrint.filter(r => r?.votingShare < 0).map(r => r.candidate.name).join(', ')} do not have any results published.
        </Typography>
      }
      {
        lastUpdated &&
        <Typography variant='subtitle2' color='textPrimary'>
          {`Last updated ${moment(lastUpdated).fromNow()}. Source: `}
          <u><a href='https://results.enr.clarityelections.com/GA/105369/web.264614/#/summary' target='_blank'>clarityelections.com</a></u>.
          {` `}Election results are still unofficial.
        </Typography>
      }
    </Wrapper>
  )
}

const Bar = ({
  candidate,
  result,
  maxResult,
  totalVotes,
  chosen,
  status,
  detailed,
  measureOption,
  index
}) => {
  const denominator = maxResult >= 1 ? maxResult : 50;
  const adjustedWidth = result/denominator * 100;
  let statusColor, statusBackground;
  if(status === 'winner') {
    statusBackground = '#99D266';
    statusColor = '#0A584C';
  } else if(status === 'runoff') {
    statusBackground = '#EFF1B6';
    statusColor = '#22034F';
  }
  const rows = {
    gridRowStart: index + 1, gridRowEnd: index + 2,
    zIndex: 1,
  }

  return (<>
    {
      status &&
      <BackgroundBar color={statusBackground} index={index}/>
    }
    <CirclePhoto
      size='mini'
      style={{ marginRight: '-8px', marginTop: '-4px', marginLeft: '-4px', marginBottom: '-4px', gridColumnStart: 1, gridColumnEnd: 2, ...rows }}
      {...(
        measureOption
        ? { src: measureOption === 'yes' ? SupportersCheck : OpponentsX }
        : { photoPath: candidate?.photoPathFace }
      )}
      checkmark={chosen}
    />
    <div style={{ gridColumnStart: 2, gridColumnEnd: 3, ...rows, margin: status ? '4px 0 4px' : 0 }}>
      {
        status &&
        <Typography variant='body1' color='textPrimary' style={{ marginTop: '3px', fontSize: '12px', fontWeight: 'bold', letterSpacing: '1.5px', color: statusColor, marginBottom: '-2px' }}>
          {status.toUpperCase()}
        </Typography>
      }
      <Typography variant='body1' color='textPrimary' style={{ marginTop: status ? 0 : '3px' }}>
        {
          measureOption
          ? (measureOption === 'yes' ? 'Yes' : 'No')
          : (candidate?.name || '')
        }
      </Typography>
    </div>
    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', gridColumnStart: 3, gridColumnEnd: 4, ...rows }}>
      {
        (result >= 1) && <ProgressBar style={{ width: adjustedWidth + '%' }}/>
      }
    </div>
    <div style={{ display: 'flex', flexDirection: 'column', gridColumnStart: 4, gridColumnEnd: 5, ...rows }}>
      <Typography variant='body1' color='textPrimary' style={{ fontWeight: (totalVotes && detailed) ? 'bold' : 'normal', letterSpacing: '0.5px'}}>
        {(result === -1) ? '–' : `${result}%`}
      </Typography>
      {
        totalVotes > 0 && detailed &&
        <Typography variant='body1' color='textPrimary' style={{ fontSize: '12px' }}>
          {`${totalVotes}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")} votes
        </Typography>
      }
    </div>
  </>);
}

const Wrapper = styled.div`
  width: 100%;
`

const BarWrapper = styled.div`
  margin: 12px 0 12px;
  width: calc(100% - 4px - 8px);
  display: grid;
  grid-template-rows: repeat(${props => props.numRows}, 1fr);
  grid-template-columns: ${props => props.photoenabled ? `auto` : ``} auto ${props => props.statusenabled ? `auto` : ``} 1fr auto;
  gap: 6px 10px;
  padding: 0 8px 0 4px;
  align-items: center;
`
const ProgressBar = styled.div`
  height: 5px;
  background-color: ${props => props.theme.palette.primary};
  border-top-right-radius: 2.5px;
  border-bottom-right-radius: 2.5px;
`

const BackgroundBar = styled.div`
  background-color: ${props => props.color};
  height: 100%;
  margin-left: -4px;
  margin-right: -8px;
  border-radius: 8px;
  grid-column-start: 1;
  grid-column-end: -1;
  grid-row-start: ${props => props.index + 1};
  grid-row-end: ${props => props.index + 2};
`

export { ElectionResultsGraph };
