import React from 'react';
import {Link, useLocation} from 'react-router-dom';
import { ReactComponent as DefaultIcon } from '../../images/defaultNav.svg';
import styled from 'styled-components';
import { onClickOrLink } from '../../app/util';

const MenuLink = styled(Link)`
	height: 50px;
	display: flex;
	align-items: center;
`;

const NavLink = styled.span`
	font-size: 16pt;
	color: #FFFFFF;
`;

const Icon = styled.span`
	svg {
		display: inline-flex;
		transform: translateY(2px);
		padding: 0 32px 0 20px;
		width: 18px;
		height: 18px;
	}
`;

const MenuItem = ({
	onClick,
	icon,
	text,
	level = 1
}) => {
	return (
		<MenuLink {...onClickOrLink(onClick)} level={level} key={text}>
			<Icon>
				{ (icon) ? ( icon ): <DefaultIcon /> }
			</Icon>
			<NavLink>{ text }</NavLink>
		</MenuLink>
	)
}
export default MenuItem;
