import React, {useEffect, useState} from 'react';
import {isPermitted, useFeathers} from "../../app/util";
import styled from 'styled-components';
import {
  LoadingSpinner,
  CandidatePreviewTile, DueDate,
} from "../../components";
import {Link, useHistory, useLocation, useParams, useRouteMatch} from "react-router-dom";
import {useSelector} from "react-redux";
import Typography from '@mui/material/Typography'
import TextField from "@mui/material/TextField";
import moment from 'moment';
import ReactTooltip from "react-tooltip";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";


const OfficesList = ({
  style,
  className,
  loading: loadingGiven,
  aggregatedInformation,
	showDetailedInfo = true
}) => {
  const feathers = useFeathers();
  const user = useSelector(state => state?.user);
 
  const [ loading, setLoading ] = useState(loadingGiven);
  const [ officeInfo, setOfficeInfo ] = useState(aggregatedInformation);

  const loadOfficeInfo = async () => {
    setLoading(true)

		const officesPresent = aggregatedInformation?.officesPresent || [];
		const officeKeys = officesPresent.map(office => office.key);

		const chunkSize = 19;
    const numChunks = Math.ceil(officeKeys.length / chunkSize);
    const chunks = Array.from({ length: numChunks }, (_, i) => officeKeys.slice(i * chunkSize, (i + 1) * chunkSize));


		try {
			const officesFull = (await Promise.all(
				chunks.map(chunk => 
					feathers.getService('offices').find({ query: { key: chunk, $limit: chunk.length} })
				)
			)).map(d => d.data).flat(1)

			const officeInfoZipped = officesPresent.map(office => {
				const officeFull = officesFull.find(officeFull => officeFull.key === office.key);

				const officeStatus = {
					'has-issues': {
						valid: (officeFull.issuesToCover || []).length > 1,
						tooltip: `This office has ${(officeFull.issuesToCover || []).length} issues to cover.`
					},
					'has-descriptions': {
						valid: officeFull.descriptionLong && officeFull.descriptionShort,
						tooltip: (
							officeFull.descriptionLong && officeFull.descriptionShort
							? `This office has a long and short description.` 
							: `This office is missing a long or short description.`
						)
					}
				}
				return {
					...office,
					...officeFull,
					officeStatus
				}
			}).sort((a, b) => b.count - a.count);
			setOfficeInfo(officeInfoZipped)
		} catch (err) {
			console.log(`Error loading offices`, err)
		}

    setLoading(false)
    ReactTooltip.rebuild()
  }
  useEffect(() => {
    if(feathers) {
      loadOfficeInfo()
    }
  }, [ feathers, aggregatedInformation ])

  return (
    <Wrapper style={style} className={className}>
      {
        !loading && officeInfo && officeInfo.length === 0 &&
        <Typography variant={'body1'} color={'primary'} style={{ padding: '4px 0 16px' }}>No offices for this election.</Typography>
      }
      {
        loading && 
				<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: 'calc(100% - 36px * 2)', padding: '36px'}}>
					<LoadingSpinner />
				</div>
			}
			{
				!loading && officeInfo && officeInfo.length > 0 &&
				<WrapperInner>
					{(officeInfo || []).map(office => {
						return (
							<OfficeRow key={office.key}>
								<Typography variant={'body1'} color={'primary'} style={{ fontWeight: 'bold' }}>
									{office.name}
									<Link to={`/offices/${office.key}`} style={{ marginLeft: 8, fontSize: 12}}>View</Link>
								</Typography>
								<Typography variant={'body1'} color={'primary'} style={{ }}>{office.count} races</Typography>
								<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
									{showDetailedInfo && Object.keys(office.officeStatus).map(statusKey => {
										const status = office.officeStatus[statusKey];
										return (
											<div key={statusKey} data-tip={status.tooltip} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: 20, height: 20, borderRadius: 12, background: status.valid ? '#4CAF50' : 'rgb(198, 40, 40)', color: 'white', fontSize: 12, fontWeight: 'bold', marginRight: 8 }}>
												{status.valid ? '✓' : '✗'}
											</div>
										)
									})}
								</div>
							</OfficeRow>
						)
					})}
				</WrapperInner> 
      }
      <ReactTooltip place='left' effect='solid' type='light' multiline/>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: stretch;
`

const WrapperInner = styled.div`
  flex: 1;
  width: 100%;
  border-top: solid 1px #EEEEEE;
`

const OfficeRow = styled.div`
	display: grid;
	grid-template-columns: 1.5fr 1fr 1fr;
	grid-gap: 8px;
	padding: 8px 0;
	border-bottom: solid 1px #EEEEEE;
	align-items: center;
`

export { OfficesList };