import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { Redirect, useParams } from 'react-router-dom';
import { useFormInputs, useFeathers } from '../../app/util';
import { useSelector } from 'react-redux';
import {
  CTAButton,
  CloseButton,
  DistrictSelectInput,
  TypeDropdown
} from '../../components';
import { Chip, TextField } from '@mui/material';
import superagent from 'superagent';
const { stateCodeForName } = require('@branchpolitics/microservice.clean.states');

const CreateOffice = ({
  onClose,
  onCreate,
  seedState
}) => {
  const feathers = useFeathers();
  const [ formInputs, setFormInputs ] = useState({
    name: '',
    state: seedState ? {
      ...seedState,
      text: seedState.longName
    } : null,
    districtTypes: []
  })
  const submittable = formInputs?.name?.length > 0 && formInputs?.state && formInputs?.districtTypes?.length > 0;
  const [ districtTypeConfig, setDistrictTypeConfig ] = useState(null);

  useEffect(() => {
    fetchDistrictTypes()
  }, [])

  const fetchDistrictTypes = async () => {
    // use superagent to request 
    const response = await superagent.get('https://districts-microservice.herokuapp.com/api/v1/district-configurations');
    const { body } = response;
    setDistrictTypeConfig(body);
  }

  const onChange = e => {
    const { name, value } = e.target;
    const update = {};
    update[name] = value;
    let newInputs = {
      ...formInputs,
      ...update
    };

    // Maintain the workflow for the inputs
    setFormInputs(newInputs)
  }

  const [ submitting, setSubmitting ] = useState(false);
  const [ error, setError ] = useState(false);
  const submit = async () => {
    if(!submittable || submitting) return;

    setSubmitting(true);
    setError(false);
    const mappedInputs = {
      name: formInputs?.name,
      district: formInputs?.state?._id,
      districtTypes: formInputs?.districtTypes
    }

    try {
      const office = await feathers.getService('offices').create(mappedInputs);
      if(onCreate) onCreate(office);
      onClose();

    } catch(err) {
      // Is error code a duplicate error?
      console.log(err)
      const errorAsJson = err.toJSON()
      setError(errorAsJson)
      setSubmitting(false);
    }
  }

  const districtTypesByState = useMemo(() => {
    if(!districtTypeConfig) return;
    console.log(districtTypeConfig)
    const returnVals = Object.entries(districtTypeConfig).reduce((acc, [state, { types, optionalTypes, names }]) => {
      console.log({
        state,
        types,
        optionalTypes
      })
      const typesAll = [... new Set([ ...Object.values(types || {}).flat(), ...(optionalTypes || []) ])];
      const typesAllFull = typesAll.map(type => {
        const typeWritten = prettyNameForType(type, names);
        return {
          type,
          typeWritten
        }
      })
      acc[state] = typesAllFull;
      return acc;
    }, {});

    return returnVals;
  }, [ districtTypeConfig ]);

  const districtTypesForState = useMemo(() => {
    if(!districtTypesByState) return [];
    if(!formInputs?.state) return [];
    const stateCode = stateCodeForName(formInputs?.state?.shortName);
    if(!stateCode)  return [];
    return districtTypesByState[stateCode.toLowerCase()] || [];
  }, [ districtTypesByState, formInputs, ])

  return (
    <Wrapper>
      <CloseButton
        onClick={onClose}
        style={{
          position: 'absolute',
          height: '36px',
          right: '-20px',
          top: '-20px'
        }}
      />
      <Top>
        {
          error && (
            <div style={{ color: 'red', marginBottom: '20px' }}>
              {error.message}
            </div>
          )
        }
        <h1>New Office</h1>
        <Row>
          <div className='label'>State</div>
          <DistrictSelectInput
            value={formInputs?.state}
            onChange={e => {
              console.log(e)
              setFormInputs({ ...formInputs, state: e.target.value, districtTypes: [] })
            }}
            style={{ flex: 1 }}
            districtTypes={['state']}
            multi={false}
          />
        </Row>
        <Row>
          <div className='label'>Name</div>
          <TextField
            name='name'
            value={formInputs?.name}
            onChange={onChange}
            variant='outlined'
            size='small'
            style={{ flex: 1 }}
          />
        </Row>
        <Row>
          <div className='label'>District types</div>
          <div style={{ flex: 1, display: 'flex', flexWrap: 'wrap' }}>
            {
              formInputs?.districtTypes?.map((type, i) => (
                <Chip
                  key={i}
                  label={districtTypesForState.find(t => t.type === type)?.typeWritten || type}
                  onDelete={() => {
                    const newTypes = formInputs?.districtTypes.filter(t => t !== type)
                    setFormInputs({ ...formInputs, districtTypes: newTypes })
                  }}
                  style={{ margin: '4px' }}
                />
              ))
            }
            <TypeDropdown
              placeholder={'Add type'}
              value={null}
              options={districtTypesForState.map(({ type, typeWritten }) => ({ type, title: typeWritten }))}
              onChange={(newType) => {
                const newTypes = formInputs?.districtTypes.concat(newType)
                setFormInputs({ ...formInputs, districtTypes: newTypes })
              }}
            />
          </div>
        </Row>
      </Top>
      <CTAButton
        value={submitting ? `Creating...` : `Create`}
        disabled={!submittable || submitting}
        onClick={submit}
      />
    </Wrapper>
  )
}

const prettyNameForType = (type, names) => {
  const prettyName = (names || {})[type]?.prettyName;
  return prettyName || (type.split('-').map(t => t.charAt(0).toUpperCase() + t.slice(1)).join(' ') + ' District')
}

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 500px;
  min-height: 200px;
`
const Top = styled.div`
  display: flex;
  flex-direction: column;

  h1 {
    font-size: 28px;
    margin: 0 0 26px;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-bottom: 28px;

  div.label {
    font-size: 18px;
    margin: 0 14px 0 0;
    opacity: 0.7;
    width: 130px;
  }
`;

export { CreateOffice };
