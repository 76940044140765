import React from 'react';
import styled from 'styled-components';
import CurrencyFormat from 'react-currency-format';
import Typography from '@mui/material/Typography';
import { BarPieGraph } from '../BarPieGraph';
import { HintTile } from '../HintTile';
import { formatMoney } from '../../app/util'

const isNumber = value => typeof value === 'number' && value === value && value !== Infinity && value !== -Infinity;

const LinksForReports = (doc, i, docs) => (
  <React.Fragment key={i}>
    <a style={{ whiteSpace: 'nowrap' }} href={doc.url} target='_blank'>{doc.title || `Report ${i+1}`}</a>{i !== docs?.length - 1 ? ', ' : ''}
  </React.Fragment>
);

const CampaignFinance = ({
  finance,
  style,
  className
}) => {
  if(finance?.schemaVersion !== '2.0') return null;
  console.log(finance)

  // Version 2 finance schema
  const segments = [
    { name: '% raised from small-dollar donors', subText: 'People that contributed less than $200', percentage: finance?.summaryData?.pct_small_individual_amount },
    { name: '% raised from large-dollar donors', subText: 'People that contributed more than $200', percentage: finance?.summaryData?.pct_large_individual_amount },
    { name: '% raised from PACs & Corporations', subText: 'Political Action Committees and Corporations', percentage: finance?.summaryData?.pct_corporation_amount }
  ]

  const numProblemDocs = (finance.problemDocs || []).length;
  const numReferenceDocs = (finance.referenceDocs || []).length;
  const totalAmount = finance.summaryData?.total_amount;
  const pacsFiltered = (finance.summaryData?.top_corporations || [])
                          .map(p => Array.isArray(p) ? p : Object.values(p))
                          .filter(p => p?.length === 2 && p[1] && !isNaN(p[1]))
  const numPacs = pacsFiltered.length;

  if(numReferenceDocs === 0 && !totalAmount) {
    if(numProblemDocs === 0) {
      return (
        <Wrapper style={style} className={className}>
          <Typography variant='body1' color='textPrimary'>
            No financial data is available for this candidate.
          </Typography>
        </Wrapper>
      )
    }
    return (
      <Wrapper style={style} className={className}>
        <Typography variant='body1' color='textPrimary'>
          We were not able to parse this candidate's financial report{numProblemDocs === 1 ? '' : `s`}. You can read {numProblemDocs === 1 ? 'it' : `them`} for yourself here:{` `}{ finance.problemDocs.map(LinksForReports) }
        </Typography>
      </Wrapper>
    )
  }

  return (
    <Wrapper style={style} className={className}>
      {
        numProblemDocs > 0 &&
        <HintTile style={{ padding: '12px', marginBottom: '24px' }}>
          <Typography variant='body2'>
            This summary does not include data from the following report{numProblemDocs === 1 ? '' : `s`} because we could not parse {numProblemDocs === 1 ? 'it' : `them`}:{` `}{ finance.problemDocs.map(LinksForReports) }
          </Typography>
        </HintTile>
      }
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', marginBottom: '16px' }}>
        <Typography variant='h3' style={{ fontSize: '16px' }}>
          Total amount raised
        </Typography>
        <div style={{ dipslay: 'flex', flexDirection: 'column', alignItems: 'flex-end', marginLeft: '8px' }}>
          <Typography variant='h3' style={{ fontSize: '16px', letterSpacing: '0.5px', textAlign: 'right' }}>
            {formatMoney(totalAmount)}
          </Typography>
          {
            totalAmount > 0 && finance.summaryData?.donor_count > 0 &&
            <Typography variant='body1' style={{ fontSize: '13px', textAlign: 'right' }}>
              from {finance.summaryData?.donor_count}+ donor{finance.summaryData?.donor_count === 1 ? '' : 's'}
            </Typography>
          }
        </div>
      </div>
      {
        totalAmount > 0 &&
        <BarPieGraph
          segments={segments}
        />
      }
      {
        numPacs > 0 &&
        <DonorList style={{ marginTop: '24px' }}>
          <Typography variant='h4' color='textPrimary' style={{ fontSize: '15px' }}>
            Top{numPacs === 1 ? '' : ` ${numPacs}`} PAC and Corporate donors
          </Typography>
          <ListWrapper>
            {pacsFiltered.map((pac, i) => (
              <Typography variant='body1' color='textPrimary' key={i} style={{ fontSize: '14px', marginTop: '5px', lineHeight: '1.1' }}>
                {i + 1}. {pac[0]} ({formatMoney(pac[1])})
              </Typography>
            ))}
          </ListWrapper>
        </DonorList>
      }

      <Typography variant='subtitle2' style={{ fontSize: '11px', opacity: 0.9, marginTop: '12px' }}>
        Finance data reflects public disclosures for the current election cycle. The following document{numReferenceDocs === 1 ? '' : 's'} were aggregated to create this report:{` `}{finance.referenceDocs.map(LinksForReports)}.
      </Typography>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const DonorList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const ListWrapper = styled.div`
  padding-left: 8px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`


/*

const pacDataAvailable = props.pacs ? true : false;
const pacs = (props.pacs && props.pacs.length > 0)
  ? props.pacs.sort((a, b) => ((a.amount || 0) > (b.amount || 0)) ? -1 : 1)
  : null;

const corpDataAvailable = props.corporations ? true : false;
const corps = (props.corporations && props.corporations.length > 0)
  ? props.corporations.sort((a, b) => ((a.amount || 0) > (b.amount || 0)) ? -1 : 1)
  : null;

const breakdownAvailable = props.breakdown && props.breakdown.length > 0;
let mappedBreakdownPerc = (breakdownAvailable)
  ? props.breakdown.map((group) => {
    let order;
    switch(group.donorType) {
      case 'individuals':
        order = 0;
        break;
      case 'corporations':
        order = 1;
        break;
      case 'pacs':
        order = 2;
        break;
      default:
        order = 10;
    }
    return {
      percentage: isNumber(group.percentage) ? group.percentage : 0,
      donorType: group.donorType,
      order
    }
  })
  : [];
mappedBreakdownPerc.sort((a, b) =>  a.order - b.order);
const breakdownPercTotal = mappedBreakdownPerc.reduce((acc, perc) => acc + perc.percentage, 0);
if(breakdownPercTotal < 100) {
  mappedBreakdownPerc.push({
    percentage: 100 - breakdownPercTotal,
    donorType: 'other'
  });
}
if(typeof props.total === 'undefined' || typeof props.total === 'null') {
  return <div className='finance-wrapper'></div>
}


{
  (typeof props.total !== 'undefined' && typeof props.total !== 'null') &&
  (
    <Text variant='body1' color='textPrimary' style={{ marginTop: '4px' }}>
      <b>Total amount raised:</b>&nbsp;
      <CurrencyFormat
        value={props.total}
        displayType={'text'}
        thousandSeparator={true}
        prefix={'$'}
        decimalScale={2}
        fixedDecimalScale={true}
      />
    </Text>
  )
}
{
  breakdownAvailable &&
  mappedBreakdownPerc.map(percGroup => {
    let donorTypeDisplay;
    switch(percGroup.donorType) {
      case 'individuals':
        donorTypeDisplay = 'individuals';
        break;
      case 'corporations':
        donorTypeDisplay = 'Businesses';
        break;
      case 'pacs':
        donorTypeDisplay = 'PACs';
        break;
      default:
        donorTypeDisplay = 'others';
        break;
    }

    return (
      <div className='breakdown-list-item' key={percGroup.donorType}>
        <div className='bd-list-item-1'>
          <div className={`bd-list-swatch ${percGroup.donorType}`} />
          <Text variant='body2' color='textPrimary' className='bd-list-span'>{`% raised from ${donorTypeDisplay}`}</Text>
        </div>
        <Text variant='body2' color='textPrimary' style={{ fontWeight: 'bold' }} className='bd-list-span'>{`${percGroup.percentage}%`}</Text>
      </div>
    )
  })
}
{
  (pacs && pacs.length > 0)
  ? (
      <div className='finance-pac-wrapper' style={{ marginTop: '24px' }}>
        <Text variant='h4' color='textPrimary' className='finance-pacs'>
          {
            (pacs.length === 1)
            ? `Top PAC donor`
            : `Top ${pacs.length} PAC donors`
          }
        </Text>
        <ol>
          {pacs.map((pac, index) => {
            const amountStr = pac.amount ? ` - $${formatMoney(pac.amount, 0)}` : '';
            return (<li key={`pac-${index}`}>{pac.name + amountStr}</li>)
          })}
        </ol>
      </div>
    )
  :
    <Text variant='h4' color='textPrimary' className='finance-pacs' style={{ marginTop: '24px' }}>
      {(pacDataAvailable) ? 'No PAC donors.' : 'No PAC donor information available.'}
    </Text>
}
{
  (corps && corps.length)
  ? (
      <div className='finance-corp-wrapper' style={{ marginTop: '24px' }}>
        <Text variant='h4' color='textPrimary' className='finance-corps'>
          {
            (corps.length === 1)
            ? `Top Business donor`
            : `Top ${corps.length} Business donors`
          }
        </Text>
        <ol>
          {corps.map((corp, index) => {
            const amountStr = corp.amount ? ` - $${formatMoney(corp.amount, 0)}` : '';
            return (<li key={`corp-${index}`}>{corp.name + amountStr}</li>)
          })}
        </ol>
      </div>
    )
  :
  <Text variant='h4' color='textPrimary' className='finance-corps' style={{ marginTop: '24px' }}>
    {(corpDataAvailable) ? 'No business donors.' : 'No business donor information available.'}
  </Text>
}
*/

export { CampaignFinance };
