import React, { Component, useEffect, useState } from 'react';
import { useFeathers, isPermitted } from '../../app/util';
import { ReactComponent as GreenCheckSvg } from '../../images/green-check.svg'//green-check.svg';
import styled from 'styled-components';
import {
   FormControl,
   FormControlLabel,
   RadioGroup,
   Radio,
  } from '@mui/material';

const SelectPermissions = ({ handlePermissionsChange, userPermission }) => {
  return (
    <FormControl>
      <RadioGroup
        aria-labelledby="permissions-radio-buttons-group-label"
        name="radio-buttons-group"
        onChange={handlePermissionsChange}
        value={userPermission || ''}
      >
        <FormControlLabel value="researcher" control={<Radio />} label="Researcher" />
        <FormControlLabel value="reviewer" control={<Radio />} label="Reviewer" />
        <FormControlLabel value="publisher" control={<Radio />} label="Publisher" />
        <FormControlLabel value="auditor" control={<Radio />} label="Auditor" />
    </RadioGroup>
    </FormControl>
  )
}

export default SelectPermissions;