import React, { useEffect, useState } from 'react';
import {isPermitted, useFeathers} from '../../app/util';
import { useSelector } from 'react-redux';
import {
  useParams,
} from 'react-router-dom';

import {
  LoadingSpinner,
  DataDetailToolbar,
  ElectionHealth
} from '../../components';
import styled from 'styled-components';
import Typography from '@mui/material/Typography';
import IconButton from "@mui/material/IconButton";
import RefreshIcon from '@mui/icons-material/Refresh';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip'


const ElectionHealthDetail = () => {
  const feathers = useFeathers();
  const electionKey = useParams().key;
  const user = useSelector(state => state.user);
  const refreshPerms = isPermitted(user, ['super-admin']);

  const electionDataPreloaded = useSelector(state => state?.elections?.byKey[electionKey]);
  const [ election, setElection ] = useState(electionDataPreloaded);
  const [ loading, setLoading ] = useState(!Boolean(electionDataPreloaded));
  const [ inRefreshMode, setInRefreshMode ] = useState(false)
  const [ electionImplementations, setElectionImplementations ] = useState(null)


  const refreshElectionData = async () => {
    try {
      const newData = await feathers.getService('elections').get(
        electionKey
      );
      setElection(newData)
      const eiData = await feathers.getService('election-implementations').get(
        electionKey
      );
      setElectionImplementations(eiData)
      setLoading(false)
    } catch(err) {
      console.error(err)
    }
  }

  const reaggregateElectionData = async () => {
    try {
      setInRefreshMode(true)
      const newData = await feathers.getService('election-implementations').patch(
        electionKey,
        {
          $reaggregateInformation: true
        }
      );
      setElection(newData)
      setInRefreshMode(false)
    } catch(err) {
      console.error(err)
    }
  }

  useEffect(() => {
    if(feathers) {
      refreshElectionData()
    }
  }, [ feathers ])

  if(loading) {
    return (
      <Wrapper>
        <LoadingTile>
          <LoadingSpinner scale={0.8}/>
        </LoadingTile>
      </Wrapper>
    )
  }

  return (
    (<Wrapper>
      <DataDetailToolbar
        onBack={{ to: {
            pathname: `/elections`,
          }}}
        navTree={[
          {
            text: 'Elections',
            to: {
              pathname: `/elections`,
            }
          },
          {
            text: election?.name,
            to: {
              pathname: election?.key
            }
           },
          { text: 'Health'}
        ]}
      />
      <div
        style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}
      >
        <Typography style={{ marginBottom: '24px', marginTop: '36px' }} variant={'h1'}>
          Election Health and Status for {election.name}
        </Typography>
        { !inRefreshMode && refreshPerms &&
            <Tooltip
              title={electionImplementations?.aggregatedInformation?.byDate
                ?`Last refreshed on  ${Object.keys(electionImplementations?.aggregatedInformation.byDate)[0]}`
                : 'Click to Reaggregate Election Information'
              }
              placement='top'
              arrow={true}
              >
              <IconButton onClick={reaggregateElectionData} size="large">
                <RefreshIcon fontSize="large"></RefreshIcon>
              </IconButton>
            </Tooltip>
        }
        { inRefreshMode && refreshPerms &&
          <CircularProgress size={40} color='secondary' />
        }
      </div>
      <Content>
        <ElectionHealth
          election={election}
          inRefreshMode={inRefreshMode}
        />
      </Content>
    </Wrapper>)
  );
}


const Wrapper = styled.div`
  width: calc(100% - 2 * 24px);
  min-height: calc(100% - 24px * 2);
  display: flex;
  flex-direction: column;
  padding: 24px;
  align-items: stretch;
`;

/*
   Defining our grid for the tiles
   width between 700 and 1000 will be 3 columns
   width >= 1000 will be 4 columns
* */

const range2 = `(min-width: 700px) and (max-width: 1000px)`
const range3 = `(min-width: 1000px)`

const Content = styled.div`
  width: 100%;
`

const LoadingTile = styled.div`
  width: 100%;
  align-self: center;
  justify-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default ElectionHealthDetail;
