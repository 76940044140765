import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import IconButton from '@mui/material/IconButton';
import TranslateIcon from '@mui/icons-material/Translate';
import { useFeathers } from '../../app/util';
import moment from 'moment';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

const { languageConfig } = require('../../language.config');

const TranslationPairDownload = ({
  serviceName,
  objectId,
}) => {
  const feathers = useFeathers();
  const [ modalOpen, setModalOpen ] = useState(false);

  const downloadTranslationEdits = async (language) => {
    setModalOpen(false)
    let data;
    try {
      data = await feathers.getService('translation-pairs').get(objectId, {
        query: {
          language,
          service: serviceName
        }
      })
    } catch(err) {
      console.log('err')
      console.log(err)
      return;
    }

    const ws = XLSX.utils.aoa_to_sheet(data);
    ws["!cols"] = [ { wch: 10 }, { wch: 10}, { wch: 20 }, { wch: 60 }, { wch: 60 }, { wch: 60 }, ];
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");
		/* generate XLSX file and download to client */
    XLSX.writeFile(wb, `${language}-${serviceName}-${objectId}-${moment().format()}.xlsx`);
  }

  return (<>
    <IconButton
      data-tip='Download translation pairs'
      onClick={() => setModalOpen(true)}
      size="large">
      <TranslateIcon/>
    </IconButton>
    <Dialog
      open={modalOpen}
      onClose={() => setModalOpen(false)}
    >
      <DialogTitle id="alert-dialog-title">{"Select a language"}</DialogTitle>
      <List sx={{ pt: 0 }}>
        {languageConfig.map(({ code, name }) => (
          <ListItem button onClick={() => downloadTranslationEdits(code)} key={code}>
            <ListItemText primary={name} />
          </ListItem>
        ))}
      </List>
    </Dialog>
  </>);
}

export { TranslationPairDownload };
