import React from 'react';
import styled from 'styled-components'
import { Typography, TextField } from '@mui/material';
import { InputListCondensed } from '../InputListCondensed';
import { AddMeasureSource } from '../AddMeasureSource';

const EditMeasure = ({
	value,
	disabled,
	onChange
}) => {

	return (
		<EditingWrapper>
			<FieldGroup>
				<Typography variant='body1'>
					Title
				</Typography>
				<TextField
					disabled={disabled}
					variant='outlined'
					name='title'
					value={value?.title}
					onChange={onChange}
					size='small'
				/>
			</FieldGroup>
			<FieldGroup>
				<Typography variant='body1'>
					Short description
				</Typography>
				<TextField
					disabled={disabled}
					variant='outlined'
					name='descriptionShort'
					value={value?.descriptionShort}
					onChange={onChange}
					size='small'
					placeholder='5 to 15 words about the referendum'
				/>
			</FieldGroup>
			<FieldGroup>
				<Typography variant='body1'>
					Act numbers
				</Typography>
				<TextField
					disabled={disabled}
					variant='outlined'
					name='actNumbers'
					value={value?.actNumbers}
					onChange={onChange}
					size='small'
					placeholder='The act numbers for the referendum (optional)'
				/>
			</FieldGroup>
			<FieldGroup>
				<Typography variant='body1'>
					How it appears on the ballot
				</Typography>
				<TextField
					disabled={disabled}
					variant='outlined'
					name='ballotText'
					value={value?.ballotText}
					onChange={onChange}
					multiline
					minRows={4}
					placeholder={`The exact text of how this appears on the ballot`}
				/>
			</FieldGroup>
			<FieldGroup>
				<Typography variant='body1'>
					What it means
				</Typography>
				<TextField
					disabled={disabled}
					variant='outlined'
					name='whatItMeans'
					value={value?.whatItMeans}
					onChange={onChange}
					multiline
					minRows={4}
					placeholder={`The Branch breakdown of what the referendum means`}
				/>
				<div style={{ paddingTop: '6px'}}>
					<InputListCondensed
						disabled={disabled}
						name={`sources`}
						value={value?.sources}
						onChange={onChange}
						labelForItem={(item) => {
							if(item.sourceType === 'website') return 'Candidate website';
							if(item.sourceType === 'questionnaire') return item.title || 'Questionnaire';
							return item?.title;
						}}
						addComponent={onAdd => <AddMeasureSource onAdd={onAdd}/>}
					/>
				</div>
				
			</FieldGroup>
			<FieldGroup>
				<Typography variant='body1'>
					What a "yes" vote means
				</Typography>
				<TextField
					disabled={disabled}
					variant='outlined'
					name='supportersSay'
					value={value?.supportersSay}
					onChange={onChange}
					multiline
					minRows={4}
					placeholder={`2 to 5 bullet points on what a "yes" vote would mean`}
				/>
			</FieldGroup>
			<FieldGroup>
				<Typography variant='body1'>
					What a "no" vote means
				</Typography>
				<TextField
					disabled={disabled}
					variant='outlined'
					name='opponentsSay'
					value={value?.opponentsSay}
					onChange={onChange}
					multiline
					minRows={4}
					placeholder={`2 to 5 bullet points on what a "no" vote would mean`}
				/>
			</FieldGroup>
		</EditingWrapper>
	)
}

const EditingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 600px;
`

const FieldGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`

export { EditMeasure }