// find moment locale codes here: 

import { ViewModuleOutlined } from "@mui/icons-material"

// https://stackoverflow.com/questions/31109303/how-to-obtain-a-list-of-available-locale-keys-from-momentjs-with-locale
const languageConfig = [
  { 
    code: 'en', 
    name: 'English', 
    nameInLanguage: 'English', 
    branchLanguage: true, 
    momentLocale: 'en',
    keepProperNouns: false,
    googleTranslationCode: 'en',
    machineTranslation: false,
    onlyPartialTranslation: false
  },
  { 
    code: 'es', 
    name: 'Spanish', 
    nameInLanguage: 'Español', 
    branchLanguage: true, 
    momentLocale: 'es',
    keepProperNouns: true,
    googleTranslationCode: 'es',
    machineTranslation: true,
    onlyPartialTranslation: false
  },
  { 
    code: 'pt', 
    name: 'Portuguese', 
    nameInLanguage: 'Português', 
    branchLanguage: true, 
    momentLocale: 'pt-br',
    keepProperNouns: true,
    googleTranslationCode: 'pt-BR',
    machineTranslation: true,
    onlyPartialTranslation: false
  },
  { 
    code: 'ko', 
    name: 'Korean', 
    nameInLanguage: '한국어', 
    branchLanguage: true, 
    momentLocale: 'ko',
    keepProperNouns: false,
    googleTranslationCode: 'ko',
    machineTranslation: true,
    onlyPartialTranslation: false
  },
  { 
    code: 'zh', 
    name: 'Chinese (Simplified)', 
    nameInLanguage: '简体中文', 
    branchLanguage: true, 
    momentLocale: 'zh-cn',
    keepProperNouns: false,
    googleTranslationCode: 'zh-CN',
    machineTranslation: true,
    onlyPartialTranslation: false
  },
  { 
    code: 'zh-TW', 
    name: 'Chinese (Traditional)', 
    nameInLanguage: '繁體中文', 
    branchLanguage: true, 
    momentLocale: 'zh-tw',
    keepProperNouns: false,
    googleTranslationCode: 'zh-TW',
    machineTranslation: true,
    onlyPartialTranslation: false
  },
  { 
    code: 'vi', 
    name: 'Vietnamese', 
    nameInLanguage: 'Tiếng Việt', 
    branchLanguage: false, 
    momentLocale: 'vi',
    keepProperNouns: true,
    googleTranslationCode: 'vi',
    machineTranslation: true,
    onlyPartialTranslation: false
  },
  { 
    code: 'hi', 
    name: 'Hindi', 
    nameInLanguage: 'हिंदी', 
    branchLanguage: false, 
    momentLocale: 'hi',
    keepProperNouns: false,
    googleTranslationCode: 'hi',
    machineTranslation: true,
    onlyPartialTranslation: true
  },
  {
    code: 'gu', 
    name: 'Gujarati', 
    nameInLanguage: 'ગુજરાતી', 
    branchLanguage: false, 
    momentLocale: 'gu',
    keepProperNouns: false,
    googleTranslationCode: 'gu',
    machineTranslation: true,
    onlyPartialTranslation: true
  },
  {
    code: 'pa', 
    name: 'Punjabi', 
    nameInLanguage: 'પંજાબી', 
    branchLanguage: false, 
    momentLocale: 'pa-in',
    keepProperNouns: false,
    googleTranslationCode: 'pa',
    machineTranslation: true,
    onlyPartialTranslation: true
  }
]

export { languageConfig };