import React from 'react';

import {
   FormControl,
   FormControlLabel,
   RadioGroup,
   Radio,
  } from '@mui/material';

const SelectResearcherLevel = ({ onChange, value }) => {
  return (
    <FormControl>
      <RadioGroup
        aria-labelledby="permissions-radio-buttons-group-label"
        name="radio-buttons-group"
        onChange={onChange}
        value={value ? `${value}` : null}
      >
        <FormControlLabel value={'1'} control={<Radio />} label="Level 1" />
        <FormControlLabel value={'2'} control={<Radio />} label="Level 2" />
        <FormControlLabel value={'3'} control={<Radio />} label="Level 3" />
    </RadioGroup>
    </FormControl>
  )
}

export default SelectResearcherLevel;