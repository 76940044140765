import React from 'react';
import { Typography } from '@mui/material';
import styled from 'styled-components';
import { onClickOrLink } from '../../app/util';

const TaskTile = ({
  icon,
  color,
  task,
  style,
  onClick,
  disabled,
  count
}) => {
  return (
    <Tile disabled={disabled ? 1 : 0} onClick={disabled ? () => {} : onClick} style={{ ...style, height: 'calc(250px - 32px * 2)', width: 'calc(190px - 24px * 2)', minHeight: 'calc(250px - 32px * 2)', minWidth: 'calc(190px - 24px * 2)', backgroundColor: color || '#FFFFFF', opacity: disabled ? 0.7 : 1.0 }}>
      <img src={icon} style={{ height: '60px' }}/>
      <div>
        <Typography variant={'h2'} style={{ alignSelf: 'start'}}>{task}</Typography>
        {count !== undefined && <Typography variant={'body1'} style={{ alignSelf: 'start', marginTop: '8px'}}>{count} tasks</Typography>}
      </div>
    </Tile>
  );
};

const Tile = styled.div`
  display: grid;
  cursor: ${props => props.disabled ? '' : 'pointer'};
  border: solid 1px #00000020;
  grid-template-rows: 100px 1fr;
  align-items: center;
  box-shadow: ${props => props.disabled ? 'none' : '0px 8px 17px #00000020'};
  border-radius: 8px;
  padding: 32px 24px;
  gap: 12px;
`

export { TaskTile };
