import React, { useEffect, useMemo, useState } from 'react';
import { taskConfigurations, useFeathers} from '../../app/util';
import { useSelector } from 'react-redux';

import {
  DataDetailToolbar,
  PaginationControl,
  ErrorPreviewTable,
  TypeDropdown,
  DownloadData,
  DateRangePicker
} from '../../components';
import styled from 'styled-components';
import qs from 'qs';
import { loadFullDisplayDataForTasks } from '../../app/util';

import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert';


const ReportedErrorList = () => {
  const feathers = useFeathers();
  const user = useSelector(state => state.user);
  const { page, status } = qs.parse(window.location.search, { ignoreQueryPrefix: true });

  // options for status include complete or not-started

  const [ loading, setLoading ] = useState(true);
  const [ errors, setErrors ] = useState([]);
  const [ errorCount, setErrorCount ] = useState(null);
  const [ displayError, setDisplayError ] = useState(undefined);

  const pageSize = 30;

  const loadTasks = async (page, status) => {
    setLoading(true)
    setErrors([])

    try {
      const pageAsNumber = (page && !isNaN(parseInt(page))) ? parseInt(page) : 1;
      const $skip = Math.ceil(pageAsNumber - 1, 0) * pageSize;

      const statusToReference = status || 'all';
      const statusFilter = statusToReference === 'all' ? null: statusToReference;

      const tasks = await feathers.getService('reported-errors').find({
        query: {
          ...(statusFilter ? { status: statusFilter } : {}),
          $limit: pageSize,
          $skip,
          $sort: {
            createdAt: -1
          }
        }
      });

      setErrors(tasks.data)
      setErrorCount(tasks.total)

      // const tasksMapped = await loadFullDisplayDataForTasks(taskType, feathers, tasks.data);
      // setTasksMapped(tasksMapped)
    } catch(err) {
      console.error(err)
      setDisplayError(err)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if(feathers) {
      loadTasks(page, status)
    }
  }, [ feathers ])

  return (
    <Wrapper>
      <DataDetailToolbar
        onBack={{ to: {
					pathname: `/home`,
				}}}
        navTree={[
          {
            text: 'Home',
            to: {
              pathname: `/home`,
            }
          },
          { text: 'Reported errors' }
        ]}
        actionButtonsComponent={
          <div>
            <DownloadData
              service='reported-errors'
              serviceDisplay={'reported errors'}
              transformData={async (reportedErrors) => {
                return reportedErrors.map(reportedError => {
                  return {
                    'Error ID': reportedError._id,
                    'Type': reportedError.type,
                    'Subtype': reportedError.details?.errorDescription,
                    'Status': reportedError.status,
                    'Resolved By': reportedError.resolvedByUser?.name || reportedError.resolvedByUser?.firstName || reportedError.resolvedByUser?.email,
                    'Resolution notes': reportedError.note,
                    'Service': reportedError.service,
                    'Service ID': reportedError.serviceId,
                    'Election': reportedError.election,
                    'Source': reportedError.source,
                    'Reported At': reportedError.createdAt,
                    'User notes': reportedError.description,
                    'Wants followup': reportedError.wantsFollowUp,
                    'User Email': reportedError.email || reportedError.reportedByUser?.email
                  }
                })
              }}
            >
              <ReportedErrorExportControls />
            </DownloadData>
          </div>
        }
      />
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', marginTop: '12px', gap: '12px' }}>
        <TypeDropdown
          value={status || 'all'}
          options={[
            { type: 'all', title: 'All' },
            { type: 'open', title: 'Unresolved' },
            { type: 'resolved', title: 'Resolved' },
            { type: 'resolved-as-ignore', title: 'Ignored' },
          ]}
          onChange={(newType) => {
            const newQuery = qs.stringify({ ...qs.parse(window.location.search, { ignoreQueryPrefix: true }), status: newType, page: 1 })
            window.history.pushState({}, '', `${window.location.pathname}?${newQuery}`)
            loadTasks(1, newType)
          }}
        />
      </div>
			<ErrorPreviewTable 
        errors={errors}
        loading={loading} 
        style={{ marginTop: '12px' }}
      />
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginTop: '12px' }}>
        <PaginationControl
          activePage={page ? parseInt(page) : 1}
          items={errorCount}
          itemsPerPage={pageSize}
          onChange={(page) => {
            const newQuery = qs.stringify({ ...qs.parse(window.location.search, { ignoreQueryPrefix: true }), page })
            window.history.pushState({}, '', `${window.location.pathname}?${newQuery}`)
            loadTasks(page, status)
          }}
        />
      </div>
      <Snackbar
          open={Boolean(displayError)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          autoHideDuration={ 6000 }
          style={{position: 'absolute'}}
          onClose={() => setDisplayError(undefined)}
        >
          <Alert
            severity="error"
            onClose={() => setDisplayError(undefined)}
            elevation={6}
            variant="filled"
          >
            {displayError?.message ?? `${displayError}`}
          </Alert>
        </Snackbar>
    </Wrapper>
  )
}


const Wrapper = styled.div`
  width: calc(100% - 2 * 24px);
  min-height: calc(100% - 24px * 2);
  display: flex;
  flex-direction: column;
  padding: 24px;
  align-items: stretch;
`;

const ReportedErrorExportControls = ({ onChangeQuery }) => {
  const [ startDate, setStartDate ] = useState(null);
	const [ endDate, setEndDate ] = useState(null);

  useEffect(() => {
    if(startDate && endDate) {
      // convert to ISO strings
      onChangeQuery({ createdAt: { $gte: new Date(startDate).toISOString(), $lte: new Date(endDate).toISOString() } })
    }
  }, [ startDate, endDate ])

  return (
    <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '24px' }}>
      <DateRangePicker startDate={startDate} endDate={endDate} onChangeStartDate={setStartDate} onChangeEndDate={setEndDate} />
    </div>
  )
}

export default ReportedErrorList;
