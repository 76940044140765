import React from "react";
import { Typography } from "@mui/material";
import styled from "styled-components";
import { ViewDiff } from "../ViewDiff";

const displayNames = {
	bioPolitical: 'Political Background',
	bioProfessional: 'Professional Background',
	bioPersonal: 'Personal Background',
}

const ViewCandidateDiff = ({
	diff
}) => {
	// diff is expected to have properties bioPolitical, bioProfessional, bioPersonal, and issues

  return (
		<Wrapper>
			<Typography variant='h4' color='textPrimary'>Background</Typography>
			{['bioPolitical', 'bioProfessional', 'bioPersonal'].map((key, index) => {
				return (
					<Section key={key}>
						<Typography variant='h5' color='textPrimary'>{displayNames[key]}</Typography>
						<ViewDiff diff={diff?.[key]} />
					</Section>
				)
			})}
			<Seperator />
			<Typography variant='h4' color='textPrimary'>Issues</Typography>
			{(diff?.issues || []).map((issue, index) => {

				console.log('issue', (issue.stances || issue.diff))
				return (
					<Section key={index}>
						<Typography variant='h5' color='textPrimary' style={{ marginBottom: '4px'}}>{issue.key}</Typography>
						{
							(issue.stances || issue.diff || []).length === 0
							? <ViewDiff diff={[]} />
							: <List>
								{
									(issue.stances || issue.diff).map((stance, index) => {
										return <li key={stance.stanceId}>
											<ViewDiff diff={stance.diff} />
										</li>
									})
								}
							</List>
						}
					</Section>
				)
			})}
		</Wrapper>
	);
}

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: stretch;
	width: 100%;
	gap: 24px;
`

const Section = styled.div`

`

const List = styled.ul`
	margin: 0;
	padding-inline-start: 16px;
`

const Seperator = styled.div`
	height: 1px;
	width: 100%;
	background-color: #E0E0E0;
`

export { ViewCandidateDiff };