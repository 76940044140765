import React, { useState, useEffect, useMemo } from 'react';
import deepEqual from 'deep-equal'
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import {
  DataDetailToolbar,
  SidePane,
  BackButton,
  Modal,
 } from '..';
import styled from 'styled-components';
import { useLocation, Link, Prompt, useHistory} from 'react-router-dom';
import Skeleton from '@mui/material/Skeleton';
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import qs from 'qs';
import { useSelector } from 'react-redux';
import { useTaskTimeTracker } from '../../app/hooks/useTaskTimeTracker';
import { useFeathers } from '../../app/util';
import { TaskPreviewTable } from '..';
import { useLoadPaginatedFrontend } from '../../app/hooks/useLoadPaginatedFrontend';
import { loadFullDisplayDataForTasks } from '../../app/util';
import TextField from '@mui/material/TextField';
import CommentIcon from '@mui/icons-material/Comment';
import { IconButton, Tooltip } from '@mui/material';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import { taskConfigurations } from '../../app/util';
import moment from 'moment';


// This is a smart functional component that wraps any task, and performs the following functions:
// Loading new tasks: When you open the page, it loads all open tasks that fit the query
// checking out new tasks: The first task is then selected, and will be checked out.
// completing tasks

const TaskPage = ({
  // Required properties:
  taskType,                               // Required. taskType to fetch tasks to load
  children,
  onChangeSaving = (saving) => {},        // function to trigger when saving state changes

  onCheckoutTask = () => { },             // functions in queue once task checked out

  task,                                   // Once a task is initially loaded, pass it back in through this prop
  completionData,                         // payload to provide completeTask
  holdingData,                            // payload to update the task continuously with checkmarked data, especially when a new task is started.
  allowMultipleCheckedOutTasks = false,   // if true, will allow someone to check out multiple tasks at once and display the left sidebar

  onCompleteTaskDialog = (onLoadNextFunc) => null,                   // a child component to be displayed if we're in the liminal space between completion and loading next
  sidePaneConfig = [],                    // an array of objects, each with the following properties:
                                          // title: section title
                                          // initiallyExpanded: true or false
                                          // content: where content is a react component to render in the sidePane
  // optional functions
  onCheckoutError, // actions / state updates to take if checkout fails
  onCompleteError, // actions / state updates to take if complete fails
  completeButtonLabel = 'Save',
  completeButtonDisabled,
  noMoreTasksMessage = 'There are currently tasks that need to be completed. Try back later.',
  disableTimeTracker = false,             // Optional. Set to true if we have a task that is using it's own visible time tracker.
                                          // Performance note: using time trackers will automatically cause a re-render of children components / the task page
                                          // every 10 seconds or so. For interface-heavy tasks like ResearchCandidate task,
                                          // We want to avoid this, which is why we use our own seperate TimeTracker component and disable this one.
  onCompleteTaskBeforeLoadNext,           // if provided, this function will be called as a trigger point after a task has been completed
                                          // but will interrupt the next task from loading. Then the onCompleteTaskDialog can be displayed
  autoSaveHoldingData = true                                        
}) => {
  const feathers = useFeathers()
  const user = useSelector(state => state.user);
  const [error, setError] = useState(false);
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);
  const [noMoreTasks, setNoMoreTasks] = useState(false);
  const [queueLoadTask, setQueueLoadTask] = useState(false);  // setting to true triggers a new task to be loaded
  const [disableAlert, setDisableAlert] = useState(false);
  const [errorSeverity, setErrorSeverity] = useState('error');
  const [ taskTotal, setTaskTotal ] = useState(0);
  const [ showHoldTaskModal, setShowHoldTaskModal] = useState(false);
  const [ userCheckedOutTasksMapped, setUserCheckedOutTasksMapped ] = useState([]);
  const [ checkedOutTasksLoading, setCheckedOutTasksLoading ] = useState(true);
  const [note, setNote] = useState(''); // notes for holding task
  const [taskNotes, setTaskNotes] = useState([]) // notes set on task - updates when additional notes are patched in
  const [notesUpdated, setNotesUpdated] = useState(false);
  const [addingNote, setAddingNote] = useState(false);
  const [skippingTask, setSkippingTask] = useState(false);
  const configForTaskType = taskType ? taskConfigurations[taskType] : null;
  const [trackedHoldingData, setTrackedHoldingData] = useState(null);    // holdingData on research task record used to compare with active holdingData coming from parent Task
  const [ lastSavedTime, setLastSavedTimed ] = useState(null);
  const [ holdingDataLastEditedTime, setHoldingDataLastEditedTime ] = useState(null);
  const [ autoSaving, setAutoSaving ] = useState(false)
  
  //Time Tracker 
  useTaskTimeTracker({ seedTask: task, disableTimeTracker });

  const [ triggerHoldingDataUpdate, setTriggerHoldingDataUpdate ] = useState(false)

  // trigger holding data update every 10 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setTriggerHoldingDataUpdate(true)
    }, 7000)
    return () => clearInterval(interval)
  }, [])

  // Task Flow
  // Parent task sends down arguments for at three stages in lifecycle
  // onCheckoutTask - state updates once task is checked out after task query in loadTask function, notably setting task in parent itself
  const getTaskTotal = async () => {
    const { total } = await feathers.getService('research-tasks').find({
      query: {
        type: taskType,
        $includeSkippedBy: true,
        $limit: 0
      },
    });
    setTaskTotal(total);
  }

  useEffect(() => {
    if (feathers) {
      getTaskTotal();
    }
  }, [feathers]);


  const loadTask = async () => {
    // This function checks out a task from the task list
    // It finds the next task in queue based on all of the non-checked out tasks that meet the query params in th epage
    // for a task type that allows multiple checked out types at once, we explicitly ignore those existing checked out tasks as options
    // unless a specific _id is being queried in the addtlDetailQuery
    setDisableAlert(false);
    setLoading(true);
    setNoMoreTasks(false);
    setNote('');
    let tasks;
    const addtlDetailQuery = {};
    Object.entries((detailQuery || {})).forEach(([key, value]) => {
      if(key === '_id') return
      addtlDetailQuery[`details.${key}`] = value;
    });

    // for things like a publish candidate task, for example, we want to prevent the user from reviewing their own work
    const taskSpecificAdditionalQuery = configForTaskType?.additionalTaskQuery ? configForTaskType.additionalTaskQuery(user) : {};


    const searchForSpecificId = Boolean(detailQuery?._id);


    if(!searchForSpecificId) {
      try {
        // get the number of tasks
        const tasksOptions = await feathers.getService('research-tasks').find({
          query: {
            type: taskType,
            ...(detailQuery?._id ? { _id: detailQuery._id } : {}),
            ...(addtlDetailQuery || {}),
            ...taskSpecificAdditionalQuery,
            $sort: {
              priority: -1
            }
          },
        });

        if(allowMultipleCheckedOutTasks) {
          const taskOptionsFiltered = tasksOptions.data.filter(task => (task.checkedOutByUser?._id || task.checkedOutByUser) !== user._id);
          tasks = {
            total: taskOptionsFiltered.length,
            data: taskOptionsFiltered
          }
        } else {
          tasks = tasksOptions;
        }
      } catch (e) {
        setError(e);
        if(onCheckoutError) onCheckoutError(e)// if any state updates are necessary on unsuccesful load of task
        return;
      }

      if (tasks.data.length === 0) {
        setNoMoreTasks(true);
        setLoading(false);
        return;
      }
    }

    try {
      const task = await feathers
        .getService('research-tasks')
        .get(detailQuery?._id || tasks.data[0]._id, {
          query: {
            type: taskType,
            $checkOut: true
          },
        });
      await onCheckoutTask(task, loadNextTask) // set variables and async operations to trigger once task is checked out
      if(task.notes) setTaskNotes(task?.notes)
      if(task?.holdingData) {
        const { updatedAt, ...dataRest } = task?.holdingData
        setTrackedHoldingData(dataRest)
      }
      setLoading(false);
      return
    } catch (err) {
      console.log(`${detailQuery?._id} has no task for this user`)
      setError(err);
      if(onCheckoutError) onCheckoutError(err)// if any state updates are necessary on unsuccesful load of task
      return;
    }
  };


  const history = useHistory();
  const resetQueryString = (string = '') => {
    history.replace({
      search: string
    })
  }
  // get the query string
  const location = useLocation();
  const detailQuery = qs.parse(location.search, { ignoreQueryPrefix: true });
  const isCheckedOutByUser = (task?.checkedOutByUser?._id || task?.checkedOutByUser) === user._id;

  // used when completing or skipping a task -- if the task comes from the already checked out task list, it will load the next one in the list
  // or a random task if none are available.
  // The reason to sometimes load the tasks from the userCheckedOutTasks is so the researcher can get their checked out tasks count lower;
  // starting another task where it just loads the next task for the team keeps the list the same length.
  const loadNextTask = () => {
    if (detailQuery._id) {
      let nextId = null;
      if (userCheckedOutTasks.length) {
        nextId = userCheckedOutTasks[0]._id === detailQuery._id ? userCheckedOutTasks[1]?._id : userCheckedOutTasks[0]._id
      }
      if (nextId) {
        resetQueryString(`_id=${nextId}`)
      } else {
        resetQueryString()
      }
    } else {
      loadTask();
    }
  }

  const completeTask = async () => {
    if (!task) return;
    if (saving) return;
    setSaving(true);
    try {
      await feathers.getService('research-tasks').patch(task._id, {
        status: 'complete',
        completionData // provided through params
      });
      setTaskNotes([])
      setShowHoldTaskModal(false)
      if (onCompleteTaskBeforeLoadNext) {
        onCompleteTaskBeforeLoadNext()
      } else {
        loadNextTask();
      }
      setSaving(false);
    } catch (e) {
      setError(e);
      setSaving(false);
      if(onCompleteError) onCompleteError()
      return;
    }
  };

  const skipTask = async () => {//if a note has already been patched don't require another note
    try {
      await feathers.getService('research-tasks').patch(task._id, {
        $skipTask: true,
      });
      setNote('')
      setTaskNotes([])
      setShowHoldTaskModal(false)
    } catch (err) {
      setError(err);
    } finally {
      loadNextTask()
      setAddingNote(false)
    }
  }

  useEffect(() => {
    if (feathers) {
      if(!allowMultipleCheckedOutTasks || Boolean(location.search)) {
        loadTask();
      }
    }
  }, [feathers, allowMultipleCheckedOutTasks, location.search]);

  useEffect(() => {
    if(feathers && queueLoadTask) {
      setQueueLoadTask(false)
      loadTask()
    }
  }, [feathers, location.search, queueLoadTask ] )

  useEffect(() => {
    if(onChangeSaving) onChangeSaving(saving)
  }, [ saving ])

  useEffect(() => {
    if(!showHoldTaskModal) {
      setSkippingTask(false)
      setAddingNote(false)
    }
  }, [ showHoldTaskModal])

  const startAnotherTask = async () => {
    try {
      setDisableAlert(true)
      if(task) {
        await feathers.getService('research-tasks').patch(task._id, {
          holdingData,
          ...(note ? { note } : {})
        });
      }
      if (detailQuery._id) {
        resetQueryString();
        setQueueLoadTask(true);
      } else {
        loadTask();
      }
      setNote('')
      setTaskNotes([])
    } catch (err) {
      setError(err);
      setDisableAlert(false)
    } finally {
      setShowHoldTaskModal(false)
      setAddingNote(false)
    }
  }

  // HOLDING DATA
  // compare equality between holdingData on task record and holdingData from parent
  // to conditionally trigger patch request
  const [ holdingDataUpdated, setHoldingDataUpdated ] = useState(false)
  useEffect(() => {
    if(loading) {
      setHoldingDataUpdated(false)
      return;
    }
    if(holdingData) {
      const { updatedAt, ...holdingDataRest } = holdingData;
      const { updatedAt: trackedUpdatedAt, ...trackedHoldingDataRest } = trackedHoldingData || {};
      const changedInfo = !deepEqual(holdingDataRest, trackedHoldingDataRest)
      setHoldingDataUpdated(changedInfo)
      if(changedInfo) {
        setHoldingDataLastEditedTime(new Date())
      }
      return;
    }
    setHoldingDataUpdated(false)
  }, [trackedHoldingData, holdingData, loading])

  const allChangesSaved = useMemo(() => {
    if(!holdingDataLastEditedTime) return true;
    if(!lastSavedTime) return false;
    return lastSavedTime > holdingDataLastEditedTime;
  }, [lastSavedTime, holdingDataLastEditedTime])

  // updates researchTask record with holdingData using sendUpdate  from useTaskTimeTracker which is based on set pingInterval
  useEffect(() => {
    if(triggerHoldingDataUpdate) {
      setTriggerHoldingDataUpdate(false)
      if (holdingDataUpdated && !saving) {
        patchHoldingData()
      }
    }
  }, [holdingDataUpdated, triggerHoldingDataUpdate, saving])

  const patchHoldingData = async () => {
    setAutoSaving(true)
    try {
      const saveTime = new Date();
      const res = await feathers.getService('research-tasks').patch(task._id, {
        holdingData,
        // ...(note ? { note } : {})
        }
      )
      console.log(`Saved holding data for ${task._id}`)
      console.log(res)
      const { updatedAt, ...dataRest } = res.holdingData 
      setTrackedHoldingData(dataRest)
      setLastSavedTimed(saveTime)
    } catch(err) {
      console.log(`Error saving holding data...`)
      console.log(err)
      setError(err)
    } finally {
      setAutoSaving(false)
    }
  }

  const handleDisplayNote = async() => {
    setShowHoldTaskModal(false);
    try {
      const res = await feathers.getService('research-tasks').patch(task._id, {
        note: note
      });
      setTaskNotes([ ...res.notes])
      setNote('')
      setShowHoldTaskModal(false)
    } catch (err) {
      setError(err);
    } finally {
      setAddingNote(false)
    }
  }

  const handleAddingNote = () => {
    setAddingNote(true)
    setShowHoldTaskModal(true)
  }

  useEffect(() => {
    setNotesUpdated(task?.notes.length !== taskNotes.length)
  }, [task, taskNotes])

  const { entity: userCheckedOutTasks } = useLoadPaginatedFrontend('research-tasks', {
    type: taskType,
    $includeCheckedOut: true,
    status: 'in-progress',
    checkedOutByUser: user?._id
  }, [user, task, taskType], 20)

  const getDisplayDataForTasks = async () => {
    try {
      const tasksMapped = await loadFullDisplayDataForTasks(taskType, feathers, userCheckedOutTasks);
      setUserCheckedOutTasksMapped(tasksMapped)
    } catch(err) {
      setError(err)
    } finally {
      setCheckedOutTasksLoading(false)
    }
  }

  useEffect(() => {
    if(feathers && userCheckedOutTasks) {
      getDisplayDataForTasks()
    }
  }, [ feathers, userCheckedOutTasks ])

  const errorMessage = useMemo(() => {
    if (!error) return;
    if (typeof error === 'string') return error;
    if (typeof error === 'object') {
      if (error.type === 'FeathersError') {
        let messageToShow;
        if (error?.errors && Object.values(error.errors).length > 0) {
          messageToShow = Object.values(error.errors).join(' ');
        } else {
          messageToShow = error?.message;
        }

        switch (error.code) {
          case 400:
            if (messageToShow) return messageToShow;
            return 'There was a problem with the data you submitted. Please check the form and try again.';
          case 401:
          case 403:
            if (messageToShow) return messageToShow;
            return 'You are not authorized to perform this action.';
          default:
            return messageToShow;
        }
      } else if (
        error.message &&
        typeof error.message === 'string' &&
        !error.message.includes('Object')
      ) {
        return error.message;
      } else {
        return JSON.stringify(error);
      }
    }

    return 'There was an error when saving. Please try again!';
  }, [error]);

  useEffect(() => {
    setErrorSeverity(error?.data?.needsMoreWork ? 'warning' : 'error')
  }, [error])

  const commonSections = useMemo(() => { //if sections are shared between all tasks we can add here and update deps
    return [{
      title: 'Notes',
      content: (
        <>
          {!!taskNotes?.length &&
            taskNotes.map(note => (
              <div style={{ display: 'flex', flexDirection: 'column' }} key={note?._id}>
                <Typography variant='body1'>{note?.text}</Typography>
                <Typography variant='caption' align='right'>{note?.user?.email}</Typography>
              </div>
            ))
          }
        </>
      ),
      initiallyExpanded: !!taskNotes?.length
    }]
  }, [taskNotes])

  const updatedSidePaneConfig = [...commonSections, ...sidePaneConfig]

  return (
    <React.Fragment>
      {
        !disableAlert && <Prompt
          when={Boolean(task) && holdingDataUpdated}
          message='You have unsaved changes, are you sure you want to leave?'
        />
      }
      {
        task && !isCheckedOutByUser &&
        <WarningBanner>
          <Typography variant='body1'>This task has not been checked out! Other people may be working on it.</Typography>
        </WarningBanner>
      }
      <Wrapper>
        {
          allowMultipleCheckedOutTasks &&
          <SidePane
            style={{ backgroundColor: '#C3CEEC' }}
            leftSide={true}
            autoScroll={true}
            expandedWidth={'300px'}
          >
            <div style={{ display: 'flex', alignItems: 'center', gap: '12px', marginBottom: '12px' }}>
              <BackButton onClick={{ to: {
                pathname: `/home`,
              }}} />
              <Typography variant='body1'>Back to home</Typography>
            </div>
            <div style={{display: 'flex', flexDirection: 'column', gap: '10px', marginBottom: '48px', marginTop: '22px'}}>
              <Typography variant='body1' style={{ width: '100%', textAlign: 'center', fontWeight: 'bold' }}>{taskTotal} Unfinished tasks</Typography>
              <Button
                onClick={
                  task
                  ? () => {
                    setSkippingTask(false)
                    setShowHoldTaskModal(true)
                  }
                  : () => startAnotherTask()
                }
                variant='contained'
                color='primary'
              >
                Start Another
              </Button>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant='body1' style={{ marginBottom: '6px'}}>{userCheckedOutTasksMapped?.length} tasks in progress</Typography>
              <TaskPreviewTable
                tasks={userCheckedOutTasksMapped}
                loading={checkedOutTasksLoading}
                style={{ marginLeft: '-24px', width: 'calc(100% + 10px)', borderRadius: 0,}}
                columnsToDisplay={['name', 'link']}
                columnConfig={{ name: { hideType: true, showDetailName: true} }}
              />
            </div>
          </SidePane>
        }
        <Content>
          <DataDetailToolbar
            style={{ width: 'calc(100% - 24px * 2)', padding: '0 24px'}}
            titleComponent={<Typography variant='h3' style={{ marginBottom: '24px'}}>{configForTaskType?.title}</Typography>}
            actionButtonsComponent={
              <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                {
                  autoSaveHoldingData &&
                  <div style={{ marginRight: '16px', marginBottom: '-8px' }}> 
                    <Typography variant='body2' style={{ opacity: 0.8 }}>
                      {
                        autoSaving 
                        ?
                          'Auto saving...'
                        : (
                          allChangesSaved
                          ? 'All changes saved' 
                          : ( lastSavedTime
                            ? `Last saved ${moment(lastSavedTime).fromNow()}`
                            : `Changes not saved`
                          ) 
                        )
                      }
                    </Typography>
                  </div>
                }
                <Tooltip title='Add a note' placement='top'>
                  <IconButton size='medium' onClick={handleAddingNote}>
                    <CommentIcon/>
                  </IconButton>
                </Tooltip>
                <Tooltip title='Skip this task' placement='top'>
                  <IconButton size='medium' onClick={() => { setSkippingTask(true); setShowHoldTaskModal(true) }} disabled={noMoreTasks || saving || loading }>
                    <SkipNextIcon/>
                  </IconButton>
                </Tooltip>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={() => completeTask()}
                  style={{ marginLeft: '24px'}}
                  disabled={noMoreTasks || saving || loading || completeButtonDisabled}
                >
                  {completeButtonLabel}
                </Button>
              </div>
            }
          />
          {
            (loading) ?
            <WrapperInner>
              <div style={{ width: '400px', padding: '36px 36px'}}>
                <Skeleton variant='text' width={'100%'} height={40}/>
                <Skeleton variant='text' width={'100%'} height={40}/>
                <Skeleton variant='text' width={'100%'} height={40}/>
              </div>
            </WrapperInner> :
            <WrapperInner>
              {
                noMoreTasks ?
                <div style={{ width: '800px', padding: '36px 36px'}}>
                  <Typography variant='h3'>No more tasks</Typography>
                  <Typography variant='body1'>{noMoreTasksMessage}</Typography>
                  {
                    Object.values(detailQuery || {}).length > 0 &&
                    <Typography variant='body1'>
                      You are currently seeing a filtered task list. <Link to={{ pathname: location.pathname, search: ''}}>Clear the filter</Link>
                    </Typography>
                  }
                </div> :
                <TaskWrapper>
                  <Main>
                    {children}
                  </Main>
                  {
                    !!updatedSidePaneConfig?.length  &&
                    <SidePane
                      style={{ height: 'calc(100% + 12px)'}}
                      sectionTitles={updatedSidePaneConfig.map((section) => section.title)}
                      sectionsInitiallyExpanded={updatedSidePaneConfig.map((section) => section.initiallyExpanded)}
                      autoScroll={true}
                    >
                      { updatedSidePaneConfig.map((section) => section.content)}
                    </SidePane>
                  }
                </TaskWrapper>
              }
            </WrapperInner>
          }
          <Snackbar
            open={Boolean(errorMessage)}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            autoHideDuration={ 6000 }
            style={{position: 'absolute'}}
            onClose={() => setError(null)}
          >
            <Alert
              severity={errorSeverity}
              onClose={() => setError(null)}
              elevation={6}
              variant='filled'
            >
              {errorMessage}
            </Alert>
          </Snackbar>
        </Content>
        {
          Boolean(onCompleteTaskDialog) &&
          onCompleteTaskDialog(loadNextTask)
        }
      </Wrapper>
      {
        showHoldTaskModal && (
        <Modal onClose={() => {
          setShowHoldTaskModal(false);
        }}>
          <ModalContentWrapper>
            <Typography variant='h3' mt={-2}>Add a note</Typography>
            <Typography>Add a note for yourself or other researchers to help give them better context on current progress.</Typography>
            <TextField
              id='notes-for-hold-task'
              multiline
              placeholder='ex: Waiting on email from county clerk, expected to receive a reply on Thurs.'
              rows={4}
              value={note}
              onChange={(e) => setNote(e.target.value)}
              variant='outlined'
              />
              { (notesUpdated || skippingTask) && //if note was already patched in while researcher was working allow them to skip without adding another
                <Button
                onClick={async () => { await skipTask() }}
              >
                Skip Note
              </Button>
              }
              <Button
                onClick={async () => {
                  if(addingNote) await handleDisplayNote()
                  else if(skippingTask) await skipTask()
                  else await startAnotherTask()
                }}
                variant='contained'
                color='primary'
              >
                {addingNote ? 'Add Note' : (
                  skippingTask ? 'Skip Task' : 'Start Another'
                )}
              </Button>
          </ModalContentWrapper>
        </Modal>
      )}
    </React.Fragment>
  )
}

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
  gap: 18px;
  position: relative;
`

// the 'right' side of the page'
const Content = styled.div`
  height: calc(100% - 12px - 36px);
  flex: 1;
  align-self: stretch;
  padding: 12px 0px 36px 0px;
`

const WarningBanner = styled.div`
  background-color: #FFD700;
  padding: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100%);
  margin-left: -24px;
  margin-top: -12px;
`

const WrapperInner = styled.div`
  width: 100%;
  height: calc(100% - 28px);    /* Height of the toolbar */
  display: flex;
  flex-direction: column;
`;

const TaskWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: stretch;
  justify-content: space-between;
`

const Main = styled.div`
  height: calc(100% - 36px - 36px);
  max-height: calc(100% - 36px - 36px);
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0px 18px 36px;
`


const ModalContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  min-width: 400px;
`

export { TaskPage };
