import React from 'react';
import styled from 'styled-components';
import { DatePicker } from '@material-ui/pickers';
import { Typography } from '@mui/material';

const DateRangePicker = ({
	startDate,
	endDate,
	onChangeStartDate,
	onChangeEndDate,
	style,
	className
}) => {
	return (
		<Wrapper style={style} className={className}>
			<DatePicker
				disableToolbar
				variant="inline"
				format="MM/DD/YYYY"
				label={'Start'}
				style={{
					width: '150px',
					highlight: { color: '#FFFFFF' }
				}}
				value={startDate}
				onChange={value => onChangeStartDate(value.toDate())}
			/>
			<Typography style={{ marginBottom: '-12px'}}>—</Typography>
			<DatePicker
				disableToolbar
				variant="inline"
				format="MM/DD/YYYY"
				label={'End'}
				style={{
					width: '150px',
					highlight: { color: '#FFFFFF' }
				}}
				value={endDate}
				onChange={(value) => onChangeEndDate(value.toDate())}
			/>
		</Wrapper>
	)
}

const Wrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	gap: 8px;
`

export { DateRangePicker };