import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useLocation, Link } from 'react-router-dom';
import {useFeathers, usePrevious, isPermitted} from '../../app/util';
import styled from 'styled-components';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';

import {
  ChevronRightButton,
  LoadingSpinner,
  PaginationControl,
  PaginationStatus, 
  TextInput,
  Modal,
  CreateOffice
} from '../../components';
import moment from 'moment';
import qs from 'qs';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Button } from '@mui/material';

const Offices = ({

}) => {
  const feathers = useFeathers();
  const history = useHistory();
  const location = useLocation();
  const user = useSelector(state => state.user);
  const editorPerms = isPermitted(user, ['editor', 'super-admin', 'admin']);

  /* handle filter controls for data */
  const {
    page,
    sFilters,
    sortBy,
    activeState
  } = qs.parse(location.search, { ignoreQueryPrefix: true });

  const filters = sFilters ? JSON.parse(sFilters) : [];
  const sortFilters = sortBy ? JSON.parse(sortBy) : [];
  const activePage = page ? parseInt(page) : 0;
  const activePagePrev = usePrevious(activePage);

  /* data */
  const [ createModalOpen, setCreateModalOpen ] = useState(false);
  const [ stateOptions, setStateOptions ] = useState([]);
  const [ stateSelected, setStateSelected ] = useState(activeState);
  const stateSelectedPrev = usePrevious(stateSelected)

  const [ searchTerm, setSearchTerm ] = useState('');
  const searchTermPrev = usePrevious(searchTerm)

  const [ data, setData ] = useState([]);
  const [ dataLoading, setDataLoading ] = useState(true);
  const [ totalNumber, setTotalNumber ] = useState(0);
  const officesPerPage = 15;

  const updatePageQuery = useCallback((page, filters, sortBy, activeState) => {
    const pageMax = Math.floor(totalNumber / officesPerPage );
    const searchTermNew = qs.stringify({
      ...(page ? { page: Math.min(page, pageMax) } : {}),
      ...(filters?.length ? { sFilters: JSON.stringify(filters) } : {}),
      ...(sortBy?.length ? { sortBy: JSON.stringify(sortBy) } : {}),
      ...(activeState ? { activeState: activeState } : {})
    })

    history.push(location.pathname + '?' + searchTermNew)
  }, [ totalNumber ])

  const loadData = async (withinState, searchFor, page = 0) => {
    setDataLoading(true)
    let term = searchFor || searchTerm || '';
    try {
      const res = await feathers.getService('offices').find({ query: {
        $sort: { createdAt: -1, name: 1 },
        $limit: officesPerPage,
        $skip: page * officesPerPage,
        district: withinState,
        ...(term?.length > 0 ? { name: { $search: term }} : {})
      }})
      setData(res.data)
      setTotalNumber(res.total);
    } catch(err) {
      console.error(err)
    } finally {
      setDataLoading(false);
    }
  }

  const loadStates = async () => {
    try {
      const res = await feathers.getService('districts').find({ query: {
        $sort: { name: 1 },
        $limit: 50,
        type: 'state',
        $skip: 0
      }})
      const nat = await feathers.getService('districts').find({ query: {
        type: 'country',
      }})
      const states = [ ...nat.data.map(d => ({ ...d, name: 'National' })), ...res.data ];
      setStateOptions(states);
      if(!stateSelected) setStateSelected(states[0]._id)

    } catch(err) {
      console.error(err)
    }
  }

  useEffect(() => {
    if(feathers) loadStates()
  }, [ feathers ])

  useEffect(() => {
    if(stateOptions?.length > 0 && stateSelected) {
      if(
        dataLoading ||
        stateSelected !== stateSelectedPrev ||
        searchTerm !== searchTermPrev ||
        activePage !== activePagePrev
      ) loadData(stateSelected, searchTerm, activePage)
    }
  }, [ stateSelected, searchTerm, activePage, stateOptions, dataLoading ])

  const selectNewActiveState = newStateId => {
    updatePageQuery(activePage, null, null, newStateId)
    setStateSelected(newStateId)
  }

  /* Table render config */
  const labelsForField = {
    name: 'Name',
    descriptionShort: 'Short description',
    lastUpdated: 'Last updated'
  }
  const renderField = (label, value, index) => {
    if(label === 'name') {
      return (
        <Typography
          key={index}
          variant='h3'
          style={{ gridColumn: index + 2 }}
        >
          {value}
        </Typography>
      );
    } else {
      return (
        <Typography
          key={index}
          variant='body1'
          style={{ gridColumn: index + 2 }}
        >
          {value}
        </Typography>
      );
    }
  }

  const dataMapped = data.map(office => ({
    to: `/offices/${office.key}`,
    name: office.name,
    descriptionShort: office.descriptionShort || 'No description.',
    lastUpdated: moment(office.updatedAt).fromNow(),
  }))

  const { onClick0, ...columnFields } = (dataMapped[0] || {});

  const stateSelectedFull = stateOptions.find(s => s._id === stateSelected);
  return (
    <Wrapper>
      <Typography variant='h1' style={{ marginBottom: '20px'}}>Offices</Typography>
      <div style={{ marginBottom: '32px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
        <ToolbarRow>
          <FormControl style={{ width: '200px', marginTop: '0px'}} variant={'outlined'} size={'small'}>
            <Select
              labelId="select-state-label"
              id="select-state"
              value={stateSelected || ''}
              label="State"
              onChange={(e) => selectNewActiveState(e.target.value)}
            >
              {
                stateOptions?.length > 0 &&
                stateOptions.map(stateD => <MenuItem key={`${stateD._id}`} value={`${stateD._id}`}>{stateD.name}</MenuItem>)
              }
            </Select>
          </FormControl>
          <TextInput
            mode='condensed'
            onChange={(e) => setSearchTerm(e.target.value)}
            name='search'
            value={searchTerm || ''}
            placeholder='Search...'
            style={{ marginLeft: '18px', height: '26px', border: 'solid 1px #BBBBBB', borderRadius: '4px' }}
          />
        </ToolbarRow>
        {
          editorPerms ?
          <Button
            variant='contained'
            color='primary'
            onClick={() => setCreateModalOpen(true)}
          >
            Create Office
          </Button>
          : <div />
        }
      </div>
      {
        dataLoading
        ?
          <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <LoadingSpinner />
          </div>
        :
          <>
            <TableGrid>
              <Heading>
                {
                  Object.entries(columnFields).map(([ key, value ], index) => (
                    <Typography variant='h5' style={{ gridColumn: index + 1 }} key={`${index}`}>{labelsForField[key] || ''}</Typography>
                  ))
                }
              </Heading>
              {
                dataMapped.map(({ to, ...office }, i) => (
                  <Row key={i} as={Link} to={to}>
                    {
                      Object.entries(office).map(([ fieldName, fieldValue ], index) => renderField(fieldName, fieldValue, index))
                    }
                    <ChevronRightButton />
                  </Row>
                ))
              }
            </TableGrid>
            <PaginationRow>
              <PaginationStatus
                activePage={activePage + 1}
                items={totalNumber}
                itemsPerPage={officesPerPage}
                itemName='office'
              />
              {
                totalNumber > officesPerPage &&
                <PaginationControl
                  activePage={activePage + 1}
                  items={totalNumber}
                  itemsPerPage={officesPerPage}
                  onChange={(pg) => updatePageQuery(pg - 1, filters, sortFilters)}
                />
              }
            </PaginationRow>
          </>
      }
      {
        createModalOpen &&
        (
          <Modal onClose={() => setCreateModalOpen(false)}>
            <CreateOffice
              onClose={() => setCreateModalOpen(false)}
              onCreate={(o) => setData(data => [ o, ...data ])}
              seedState={stateSelectedFull}
            />

          </Modal>
        )
      }
    </Wrapper>
  )
}

const sidePadding = '20px';

const Wrapper = styled.div`
  width: calc(100% - 36px - 24px);
  height: calc(100% - 36px * 2);
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  padding: 36px 36px 36px 24px;
  align-items: stretch;
`;

const PaginationRow = styled.div`
  margin: 18px 0 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const TableGrid = styled.div`
  display: grid;
  grid-template-columns:
    ${sidePadding}
    minmax(150px, 1fr)
    minmax(250px, 2fr)
    minmax(150px, 1fr)
    20px
    ${sidePadding};
  grid-row-gap: 10px;
  grid-column-gap: 4px;
`

const Heading = styled(TableGrid)`
  grid-column: 1 / -1;
`

const Row = styled(TableGrid)`
  grid-column: 1 / -1;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
  background-color: #FFFFFF;
  padding: 10px 0;
  min-height: 50px;
  align-items: center;
  text-decoration: none;

  &:hover {
    cursor: pointer;
    background-color: #F8F8F8;
  }
`

const ToolbarRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
export default Offices;
