import React from 'react';
import styled from 'styled-components';
import {CirclePhoto} from "../CirclePhoto";
import Typography from "@mui/material/Typography";
import { ProgressBar } from "../ProgressBar";
import {useHistory, Link} from "react-router-dom";
import {ChevronRightButton} from "../ChevronRightButton";
import {DueDate} from "../DueDate";

const TeamPreviewTable = ({
  style,
  className,
  users,
  teamMembers,
  electionKey,
}) => {
  const history = useHistory();
  return (
    <Wrapper style={style} className={className}>
      {
        (teamMembers || []).map((teamMember, i) => (
          <Cell
            key={teamMember?._id}
            as={Link}
            to={`/elections/${electionKey}/team/${teamMember?.user?._id}`}
          >
            <div/>
            <CirclePhoto photoPath={teamMember?.photo} size={'small'} style={{ margin: '8px 0'}}/>
            <Typography variant={'body1'}>
              {teamMember?.user?.name || teamMember?.user?.firstName || teamMember?.user?.email}
            </Typography>
            <div/>
            {
              teamMember?.assignment
              ? <div style={{ display: 'flex', alignItems: 'column', gap: '4px'}}>
                  <DueDate
                    date={teamMember.assignment.extensionDueBy || teamMember.assignment.dueBy}
                    extensionGranted={!!teamMember.assignment.extensionDueBy}
                    showColor={!teamMember.assignment.closed}
                  />
                </div>
              : <div>
                  <Typography variant={'body1'}>No active assignment</Typography>
                </div>
            }
            {
              teamMember?.assignment
              ? <div style={{}}>
                  <ProgressBar percentage={teamMember.assignment?.analytics?.percentageCandidatesSubmitted} />
                  <Typography variant={'body1'} style={{ marginTop: '2px' }}>
                    {teamMember.assignment?.analytics?.numCandidatesSubmitted}/{teamMember.assignment?.analytics?.numCandidates} submitted
                  </Typography>
                </div>
              : <div/>
            }
            <div/>
            <ChevronRightButton />
            <div/>
            { i !== (users.length - 1) &&
              <div style={{ backgroundColor: '#D8D8D8', height: '1px', gridColumn: '1 / -1' }}/>
            }
          </Cell>
        ))
      }
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: #FFFFFF;
  border: solid 1px #D8D8D8;
  border-radius: 8px;
`

const Cell = styled.div`
  display: grid;
  grid-template-columns: 8px 50px 1fr 1fr 1fr 100px 40px 40px 8px;
  grid-gap: 0px 12px;
  align-items: center;

  text-decoration: none;

`

export { TeamPreviewTable };