import React, { useEffect, useState } from 'react';
import { useFeathers } from '../../app/util';
import { ReactComponent as GreenCheckSvg } from '../../images/green-check.svg';
import styled from 'styled-components';
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { Modal, SearchableSelectInput } from '../../components'

const AddTeamMemberModal = ({
  election,
  role = 'researcher',
  onClose = () => {},
  refreshUsers = () => {}
}) => {
  const feathers = useFeathers()
  const [ sending, setSending ] = useState(false)
  const [ sent, setSent ] = useState(false)
  const [ error, setError ] = useState('');
  const submittable = true;
  const [ selectedUsers, setSelectedUsers ] = useState([])
  const [ userOptions, setUserOptions ] = useState([])
  const [ searchText, setSearchText ] = useState('')
  const [ allUsers, setAllUsers ] = useState([])

  const loadUsers = async () => {
    if (!feathers) return
    const perms = role === 'researcher'
      ? ['researcher']
      : ['publisher', 'reviewer'];
    const permsNot = role === 'researcher'
      ? ['reviewer', 'publisher']
      : [];
    const permsNotAll = ['editor', 'admin', 'super-admin', 'test', ...permsNot];
    const { data: users } = await feathers.getService('users').find({ query: {
      $and: [
        { permissions: { $in: perms } },
        { permissions: { $nin: permsNotAll } },
      ],
      $limit: 200,
    }})
    const { data: existingTeamMembers } =  await feathers.getService('team-members').find({
      query: {
        $and: [
          { role: { $in: [ role ]} },
          { election: election.key },
        ],
        $limit: 200,
      }})

    const existingTeamMemberIds = existingTeamMembers.map(tm => tm.user._id)
    const possibleUsers = users.filter(user => !existingTeamMemberIds.includes(user._id))
    setAllUsers(possibleUsers)
  }

  const formatUserName = (user) => {
    if (user?.name) {
      return `${user.name} (${user.email})`
    } else if (user?.firstName || user?.lastName) {
      return `${user.firstName || ''} ${user.lastName || ''} (${user.email})`
    } else {
      return user?.email
    }
  }

  const searchUser = (text) => {
    setSearchText(text)
    text = text?.toLowerCase() || ''
    const relevantUsers = text
      ? allUsers.filter(user => {
        const lowerFirst = user.firstName?.toLowerCase() || ''
        const lowerLast = user.lastName?.toLowerCase() || ''
        const lowerEmail = user.email?.toLowerCase() || ''
        const lowerUserName = user.name?.toLowerCase() || ''
      return (
        lowerFirst.startsWith(text) ||
        lowerLast.startsWith(text) ||
        `${lowerFirst} ${lowerLast}`.startsWith(text) ||
        lowerEmail.startsWith(text)) ||
        lowerUserName?.startsWith(text)
      })
      : allUsers

    const formattedUsers = relevantUsers.map(user => {
        return {
          text: formatUserName(user),
          key: user._id,
          _id: user._id,
        }
      })
    setUserOptions(formattedUsers)
  }

  const addTeamMember = async() => {
    if(!submittable) return;
    try {
      setSending(true)
      await Promise.all(selectedUsers.map(user => {
        return feathers.getService('team-members').create({
          user: user._id,
          election: election.key,
          role,
        })
      }))
      setSending(false)
      setSent(true)
      refreshUsers()
    } catch (err) {
      setError(err.message )
    }
  }

  const selectUser = (userInput) => {
    const users = userInput.target.value
    setSelectedUsers(users)
  }

  useEffect(() => {
    loadUsers()
  }, [feathers])


  return (
    <Modal onClose={onClose}>
      <AddTeamMemberWrapper>
        <div style={{ paddingBottom: '6px' }}>
          <Typography variant='h2' align='center'>Add {role}</Typography>
          <Typography variant='h3' align='center'>{election.name}</Typography>
        </div>

        <SearchableSelectInput
          name='Add Team Members'
          multi={true}
          onChangeSearchText={(searchText) => searchUser(searchText)}
          value={selectedUsers}
          onChange={(u) => selectUser(u)}
          options={userOptions}
          style={{ flexGrow: 1 }}
          disabled={sent}
        />
        {
          ! sending && !sent &&
          <Button
            variant={'contained'}
            color={'primary'}
            disabled={!submittable || sending}
            onClick={addTeamMember}
          >Add Team Member
          </Button>
        }
        {
          sending &&
          <Typography style={{color: error ? 'red' : '' }}>
              {error ? error : 'Sending....'}
          </Typography>
        }
        {
          sent &&
          <SavedWrapper>
            <GreenCheck/>
            <Typography>Sucesfully Added Team Members!</Typography>
          </SavedWrapper>
        }
      </AddTeamMemberWrapper>
    </Modal>
  )
}

const AddTeamMemberWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 60px;
  justify-content: space-between;
  gap: 30px;
  width: 350px;

  .MuiInput-root {
    border-bottom: 1px solid #22034F !important;
    color: #313639
  }
`

const GreenCheck = styled(GreenCheckSvg)`
  width: 25px;
  height: auto;
`

const SavedWrapper = styled.div`
  display: flex;
  gap: 10%;
  justify-content: center;
  align-items: center;
`

export { AddTeamMemberModal };
