import React, { useState, useMemo, useEffect } from 'react'
import styled from 'styled-components';
import TextField from '@mui/material/TextField';
import { InputListCondensed } from '../InputListCondensed';
import { AddSource } from '../AddSource';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';

const noDataOptionsStatic = {
  'no-public-info': 'There is little to no public information on this candidate.',
  'no-response': 'Candidate has no website and has not responded to the questionnaire.',
  'issue-specific': 'Website does not mention this specific information.'
}

const EditBioField = React.forwardRef(({
  title,
  value,              // expected to be an object containing properties: text, sources, and missingData
  onChange,
  disabled,
  possibleSources,
  hasWebsite
}, inputRef) => {
  const noDataOptions = useMemo(() => {
    const keys = hasWebsite ? ['issue-specific'] : ['no-response', 'no-public-info'];
    return keys.reduce((acc, key) => {
      acc[key] = noDataOptionsStatic[key];
      return acc;
    }, {})
  }, [ hasWebsite ])


  const checkBox = (e) => {
    onChange({ ...value, missingData: e.target.checked ? null : Object.keys(noDataOptions)[0] })
  }

  const onChangeMissingReason = (e) => {
    onChange({ ...value, missingData: e.target.value })
  }
  const onChangeText = (e) => {
    onChange({ ...value, text: e.target.value })
  }
  
  const onChangeSources = (e) => {
    onChange({ ...value, sources: e.target.value })
  }

  useEffect(() => {
    if(value.missingData) {
      if(!noDataOptions[value.missingData]) {
        onChange({ ...value, missingData: Object.keys(noDataOptions)[0] })
      }
    }
  }, [ hasWebsite, value?.missingData, noDataOptions ])

  return (
    <Wrapper>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between',  scrollMarginTop: '60px' }} ref={inputRef}>
        <Typography variant={'h4'}>{title}</Typography>
        <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
          <Typography variant='body2'>Has information</Typography>
          <Checkbox
            size='small'
            checked={!Boolean(value.missingData)}
            onChange={checkBox}
            style={{ marginRight: '-12px'}}
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
        </div>
      </div>
      {
        value.missingData &&
        <FormControl>
          <Select
            variant='outlined'
            value={value?.missingData}
            onChange={onChangeMissingReason}
            size='small'
          >
            {
              Object.keys(noDataOptions).map(option => (
                <MenuItem key={option} value={option}>{noDataOptions[option]}</MenuItem>
              ))
            }
          </Select>
        </FormControl>
      }
      {
        !value.missingData &&
        <>
          <TextField
            disabled={disabled}
            onChange={onChangeText}
            value={value?.text || ''}
            multiline
            minRows={3}
            variant='outlined'
            color='primary'
          />
          <SourceWrapper>
            <Typography variant='body2' style={{ }}>Sources</Typography>
            <InputListCondensed
              disabled={disabled}
              value={value.sources}
              onChange={onChangeSources}
              labelForItem={(item) => {
                if(item.sourceType === 'website') return 'Candidate website';
                if(item.sourceType === 'questionnaire') return item.title || 'Questionnaire';
                return item?.title;
              }}
              addComponent={onAdd => <AddSource onAdd={onAdd} possibleSources={possibleSources} sourcesAlreadyUsed={value.sources}/>}
            />
          </SourceWrapper>
        </>
      }
    </Wrapper>
  )
})

export { EditBioField };

const SourceWrapper = styled.div`
  width: 100%;
  max-width: 100%;
  gap: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 6px;
`;


const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
`
