import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useSelector } from 'react-redux';
import { useFeathers } from '../../app/util';
import {
  DataDetailToolbar,
  BasicCoverageTile,
  BasicCoverageCount
 } from '../../components'
import styled from 'styled-components';
import {useParams} from "react-router-dom";
import Skeleton from '@mui/material/Skeleton';


const DataHookupsBasicCoverage = () => {
  const feathers = useFeathers();
  const electionKey = useParams()?.key;
  const election = useSelector(state => state?.elections?.byKey[electionKey]);

  if(!electionKey) return null;

  return (
    <Wrapper>
      <DataDetailToolbar
        onBack={{ to: {
            pathname: `/elections/${electionKey}/data`,
          }}}
        navTree={[
          {
            text: 'Elections',
            to: {
              pathname: `/elections`,
            }
          },
          {
            text: election?.name,
            to: {
              pathname: `/elections/${electionKey}`,
            }
          },
          { 
            text: 'Data Hook-ups',
            to: {
              pathname: `/elections/${electionKey}/data`,
            }
          },
          {
            text: 'Basic coverage'
          }
        ]}
      />
      <Typography style={{ marginBottom: '16px', marginTop: '24px' }} variant={'h1'}>Basic coverage</Typography>
      <BasicCoverageTile electionKey={electionKey} style={{ marginTop: '24px' }}/>
    </Wrapper>
  )
}


const Wrapper = styled.div`
  width: calc(100% - 24px - 36px);
  display: flex;
  flex-direction: column;
  padding: 36px 36px 36px 24px;
  align-items: stretch;
`;


/*

<Section>
        <Typography variant='h1'>Photos</Typography>
        {
          photoOptionsExpanded
          ? <PhotoOptionsHookups electionKey={electionKey}/>
          : <Button
            color='primary'
            onClick={() => setPhotoOptionsExpanded(true)}
            variant='contained'
            style={{ alignSelf: 'flex-start', marginTop: '8px'}}
          >
            Load
          </Button>
        }
      </Section>
      <Section>
        <Typography variant='h1'>Election Results (Candidates)</Typography>
        {
          electionResultsExpanded
          ? <ElectionResultsHookups electionKey={electionKey}/>
          : <Button
            color='primary'
            onClick={() => setElectionResultsExpanded(true)}
            variant='contained'
            style={{ alignSelf: 'flex-start', marginTop: '8px'}}
          >
            Load
          </Button>
        }
      </Section>
      <Section>
        <Typography variant='h1'>Election Results (Referendums)</Typography>
        {
          electionResultsExpandedMeasures
          ? <ElectionResultsHookups electionKey={electionKey} mode='measures'/>
          : <Button
            color='primary'
            onClick={() => setElectionResultsExpandedMeasures(true)}
            variant='contained'
            style={{ alignSelf: 'flex-start', marginTop: '8px'}}
          >
            Load
          </Button>
        }
      </Section>
      <Section>
        <Typography variant='h1'>Campaign finance</Typography>
        {
          cfExpanded
          ? <FinanceHookups electionKey={electionKey}/>
          : <Button
            color='primary'
            onClick={() => setCfExpanded(true)}
            variant='contained'
            style={{ alignSelf: 'flex-start', marginTop: '8px'}}
          >
            Load
          </Button>
        }

      </Section>
*/

export default DataHookupsBasicCoverage;
