import React, { useEffect, useMemo, useState } from 'react';
import { taskConfigurations, useFeathers} from '../../app/util';
import { useSelector } from 'react-redux';
import {
  useParams,
  Link
} from 'react-router-dom';

import {
  LoadingSpinner,
  DataDetailToolbar,
	ChevronRightButton,
	DateRangePicker
} from '../../components';
import styled from 'styled-components';
import moment from 'moment'
import Typography from '@mui/material/Typography';
import BarChartIcon from '@mui/icons-material/BarChart';
import TimeIcon from '@mui/icons-material/AccessTime';
import Numbers from '@mui/icons-material/Check'
import { Tooltip } from '@mui/material';

const ResearcherAnalytics = () => {
  const feathers = useFeathers();
  const user = useSelector(state => state.user);

  const [ loading, setLoading ] = useState(false);
	const [ analytics, setAnalytics ] = useState(null);
	const [ startDate, setStartDate ] = useState(null);
	const [ endDate, setEndDate ] = useState(null);
	const [ expandedTaskType, setExpandedTaskType ] = useState(null);

	const taskTypesToDisplay = [
		...Object.keys(taskConfigurations),
		'total'
	];

  const loadAnalytics = async () => {
		if(loading) return;
    try {
			setLoading(true)
      const analytics = await feathers.getService('researcher-analytics').find({
				query: {
					group: ['user', 'taskType'],
					...(startDate ? { startDate: moment.utc(startDate).format('YYYY-MM-DD') } : {}),
					...(endDate ? { endDate: moment.utc(endDate).format('YYYY-MM-DD') } : {}),
				}
			});

			setAnalytics(analytics.data)
			setStartDate(analytics.startDate)
			setEndDate(analytics.endDate)
    } catch(err) {
      console.error(err)
    } finally {
			setLoading(false)
		}
  }

  useEffect(() => {
    if(feathers) {
      loadAnalytics()
    }
  }, [ feathers ])

	useEffect(() => {
		if(startDate && endDate) {
			loadAnalytics()
		}
	}, [ startDate, endDate ])

	const userAnalyticsTabular = useMemo(() => {
		const analyticsMapped = (analytics || []).reduce((acc, { _id, count, totalTimeToComplete, user, }) => {
			const userId = _id.completedByUser;
			
			if(!acc[userId]) {
				acc[userId] = {
					user: {
						_id: userId,
						...user
					}
				}
			}
			
			const taskType = _id.type;

			acc[userId][taskType] = {
				count,
				totalTimeToComplete,
				averageTimePerTask: count > 0 ? totalTimeToComplete / count : null
			}

			return acc;
		}, {});

		const prepped = Object.values(analyticsMapped).map(({ user, ...taskTypes }) => {
			const totalCount = Object.values(taskTypes).reduce((acc, { count }) => acc + count, 0);
			const totalTimeToComplete = Object.values(taskTypes).reduce((acc, { totalTimeToComplete }) => acc + totalTimeToComplete, 0);
			const averageTimePerTask = totalCount > 0 ? totalTimeToComplete / totalCount : null;

			return {
				user,
				...taskTypes,
				'total': {
					count: totalCount,
					totalTimeToComplete,
					averageTimePerTask
				}
			}
		});

		return prepped.sort((a, b) => {
			return b.total.count - a.total.count;
		});
	}, [ analytics ])

	if(loading) {
    return (
      <Wrapper>
          <LoadingSpinner scale={0.8}/>
      </Wrapper>
    )
  }

	console.log('expandedTaskType', expandedTaskType)

  return (
    <Wrapper>
      <DataDetailToolbar
        onBack={{ to: {
					pathname: `/user-management`,
				}}}
        navTree={[
          {
            text: 'Team',
            to: {
              pathname: `/user-management`,
            }
          },
          { text: 'Analytics' }
        ]}
      />
			<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
				<Typography style={{ marginBottom: '24px', marginTop: '36px' }} variant={'h1'}>Team analytics</Typography>
				<DateRangePicker startDate={startDate} endDate={endDate} onChangeStartDate={setStartDate} onChangeEndDate={setEndDate} />
			</div>
			<TableWrapper numRows={userAnalyticsTabular.length} numDataColumns={taskTypesToDisplay.length}>
				{userAnalyticsTabular.map(({ user, ...rest }, index) => {
					const gridRow = `${index + 2} / ${index + 3}`
					return (
						<Row key={index} style={{ gridRow, gridColumn: '1 / -1' }} />
					);
				})}
				<TableBackground />
				<div style={{ gridRow: '1 / 2', gridColumn: '1 / 2'}}></div>
				{
					taskTypesToDisplay.map((taskType, index) => {
						return <React.Fragment key={index}>
							{
								taskConfigurations[taskType] && 
								<Column style={{ gridColumn: `${index + 2} / ${index + 3}`, background: taskConfigurations[taskType].color}} />
							}
							<Header 
								style={{ gridColumn: `${index + 2} / ${index + 3}`, gridRow: '1 / 2'}} 
								taskType={taskType}
								expanded={[expandedTaskType, 'total'].includes(taskType)} 
								onClick={() => {
									if(taskType === 'total') {
										// do nothing
									} else {
										setExpandedTaskType(expandedTaskType === taskType ? null : taskType)
									}
								}}
							>
								{taskConfigurations[taskType]?.title || 'Total'}
							</Header>
						</React.Fragment>
					})
				}
				<div style={{ gridRow: '1 / 2', gridColumn: '-2 / -1'}}></div>
				<RowSeperator style={{ gridRow: '1 / 2' }} />
				{userAnalyticsTabular.map(({ user, ...rest }, index) => {
					const gridRow = `${index + 2} / ${index + 3}`
					return (
						<React.Fragment key={index}>
							<div style={{ gridRow, gridColumn: `1 / 2`, display: 'flex', alignItems: 'center' }}>
								<Typography variant={'body1'} >{user.name ?? (user.firstName ? `${user.firstName} ${user.lastName || ''}` : undefined) ?? user.email}</Typography>
							</div>
							{taskTypesToDisplay.map((taskType, index) => {
								if(expandedTaskType === taskType || taskType === 'total') {
									return (
										<ThreeNumberCell key={index} style={{ gridRow, gridColumn: `${index + 2} / ${index + 3}` }}>
											<Typography variant='body1'>{rest[taskType]?.count ?? 0} tasks</Typography>
											<Typography variant='body1'>{rest[taskType]?.totalTimeToComplete ? formatTime(rest[taskType].totalTimeToComplete) : '0m'}</Typography>
											<Typography variant='body1'>{rest[taskType]?.averageTimePerTask ? formatTime(rest[taskType].averageTimePerTask) : '--'}</Typography>
										</ThreeNumberCell>
									);
								} else {
									return (
										<OneNumberCell key={index} style={{ gridRow, gridColumn: `${index + 2} / ${index + 3}` }}>
											<Typography variant='body1'>{rest[taskType]?.count ?? 0} tasks</Typography>
										</OneNumberCell>
									);
								}
							})}
							<Cell style={{ gridRow, gridColumn: '-2 / -1', alignSelf: 'center', marginBottom: '-4px' }}>
								<Link to={`/team/analytics/${user._id}`}>
									<ChevronRightButton />
								</Link>
							</Cell>
							<RowSeperator style={{ gridRow }} />
						</React.Fragment>
					)
				})}
			</TableWrapper>
    </Wrapper>
  )
}

const formatTime = (timeInSec) => {
	const hours = Math.floor(timeInSec / 60 / 60);
	const minutes = Math.floor(timeInSec / 60 - hours * 60);
	const seconds = Math.floor(timeInSec - minutes * 60 - hours * 60 * 60);

	return `${hours ? `${hours}h ` : ''}${minutes ? `${minutes}m ` : ''}${seconds}s`
}

const Header = ({ children, style, expanded, onClick, taskType }) => {
	return (
		<div onClick={onClick} style={{ ...(style || {}), display: 'flex', flexDirection: 'column', alignItems: 'stretch', justifyContent: 'flex-end', marginBottom: '8px', width: 'auto', cursor: onClick ? 'pointer' : null }}>
			{
				expanded &&
				<Typography variant={'body1'} style={{ gridRow: '1 / 2', gridColumn: '1 / -1', textAlign: 'center', marginBottom: '8px' }}>{children}</Typography>
			}
			{
				expanded 
				?	
					<ThreeNumberCell style={{ width: '100%' }}>
						<Tooltip title={'Number of tasks completed'}>
							<Numbers/>
						</Tooltip>
						<Tooltip title={'Total time spent'}>
							<TimeIcon/>
						</Tooltip>
						<Tooltip title={'Average time per task'}>
							<BarChartIcon/>
						</Tooltip>
					</ThreeNumberCell>
				: 
					<OneNumberCell style={{ width: 'calc(100% - 16px)'}}>
						<Tooltip title={`Number of ${taskConfigurations[taskType].title} completed`}>
							<img style={{ width: '30px', height: '30px', objectFit: 'contain' }} src={taskConfigurations[taskType]?.icon} />
						</Tooltip>
					</OneNumberCell>
			}
		</div>
	)
}

const ThreeNumberCell = ({ children, style }) => {
	return (
		<div style={{ ...(style || {}), display: 'grid', gridTemplateColumns: 'auto', gridTemplateColumns: '1fr 1fr 1fr', gridGap: '8px', alignItems: 'center', justifyItems: 'center' }}>
			{
				children.map((child, index) => {
					return (
						<Cell key={index}>
							{child}
						</Cell>
					)
				})
			}
		</div>
	)
}

const OneNumberCell = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 8px;
`

const Wrapper = styled.div`
  width: calc(100% - 2 * 24px);
  min-height: calc(100% - 24px * 2);
  display: flex;
  flex-direction: column;
  padding: 24px;
  align-items: stretch;
`;

const TableWrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr repeat(${props => props.numDataColumns + 1}, auto) auto;
	grid-template-rows: auto repeat(${props => props.numRows}, 1fr);
	width: calc(100% - 24px * 2);
	grid-gap: 0px 48px;
	padding: 0 24px;

	* {
		white-space: nowrap;
	}
`;

const RowSeperator = styled.div`
	grid-column: 1 / -1;
	height: 1px;
	background-color: #22034F50;
	align-self: end;
	width: calc(100% + 25px * 2);
	margin-left: -24px;
`

const TableBackground = styled.div`
	background-color: #FFFFFF;
	border-radius: 0px;
	margin-top: -1px;
	border: 1px solid #22034F50;
	grid-column: 1 / -1;
	width: calc(100% + 24px * 2);
	margin-left: -24px;
	grid-row: 2 / -1;
`

const Row = styled.div`
	grid-column: 1 / -1;
	/* background-color: #FFFFFF;
	border-radius: 4px;
	border: 1px solid #E0E0E0; */
	height: 100%;
	width: calc(100% + 24px * 2);
	margin-left: -24px;
	min-height: 42px;
`;

const Column = styled.div`
	grid-row: 1 / -1;
	background-color: #FFFFFF;
	border-radius: 4px;
	align-self: stretch;
	width: calc(100% + 8px * 2);
	height; calc(100% + 8px * 2);
	margin-left: -8px;
	margin-top: -8px;
`

const Cell = styled.div`
	padding: 0 4px;
`


export default ResearcherAnalytics;
