import React, { useEffect, useState } from 'react';
import { Button, IconButton, Modal, Typography } from "@mui/material";
import DownloadIcon from '@mui/icons-material/GetApp';
import { asyncPoolAll, useFeathers } from "../../app/util";
import * as XLSX from 'xlsx';
import moment from 'moment';
import superagent from 'superagent';
import { ModalInner } from '../lower-order';

/* This button is designed to be a generic solution to downloading data
	When clicked, a modal will popup confirming whether to download the data, and also with different filter options
*/
const DownloadData = ({
	service,			          // This is the feathers service to download data from	
	serviceDisplay,         // This is the display name of the service
	transformData,          // This is a function that takes in the data and returns the data to be downloaded. It is async
	children,               // This is an optional child component, if you want to provide filteering on the service for which data to download
													// if provided, if must support the property onChangeQuery, which returns the query to launch against the service
  defaultQuery,
	pageSize = 20
}) => {
	const feathers = useFeathers();
	const [ modalOpen, setModalOpen ] = useState(false);
	const [ downloadQuery, setDownloadQuery ] = useState(defaultQuery || {})
	const [ recordCount, setRecordCount ] = useState(null);
	const [ downloading, setDownloading ] = useState(false);
	const [ downloadComplete, setDownloadComplete ] = useState(false);

	const fetchRecordCount = async () => {
		if(!feathers) return;
		setRecordCount(null);
		const response = await feathers.getService(service).find({ query: { $limit: 0, ...(downloadQuery || {}) } });
		setRecordCount(response.total);
	}
	useEffect(() => { fetchRecordCount() }, [downloadQuery, feathers]);

	const closeModal = () => {
		setModalOpen(false)
		setDownloadComplete(false)
	}

	const downloadSpreadsheet = async () => {
		if(downloading) return;
		setDownloading(true)

		const numRecords = await feathers.getService(service).find({ 
			query: {
				$limit: 0,
				...(downloadQuery || {})
			}
		});
		const numPages = Math.ceil(numRecords.total / pageSize);
		const paginatedQueries = Array.from({ length: numPages }, (_, i) => ({ ...downloadQuery, $skip: i * pageSize, $limit: pageSize }));

		const records = await asyncPoolAll(5, paginatedQueries, async query => {
			return (await feathers.getService(service).find({ query })).data
		});
		console.log(`Loaded ${records.length} pages of data with ${records.flat().length} records`);
		const transformedData = transformData ? await transformData(records.flat()) : records.flat();

		console.log('Transformed data', transformedData);
		if(transformedData.length === 0) {
			setDownloading(false);
			return alert('No data to download');
		}

		// generate the spreadsheet
		const wb = XLSX.utils.book_new();

		const ws = XLSX.utils.json_to_sheet(transformedData);
		const rows = Object.keys(transformedData[0]);

		ws["!cols"] = rows.map(header => ({ wch: 40 }));
		XLSX.utils.book_append_sheet(wb, ws, serviceDisplay);

		const filename = `Data-Export-${service}-${moment().format('LLL')}.xlsx`;

		/* generate XLSX file and download to client */
		XLSX.writeFile(wb, filename);
		setDownloadComplete(true);
		setDownloading(false);
	}

	return (<>
        <IconButton onClick={() => setModalOpen(true)} size="large">
            <DownloadIcon />
        </IconButton>
        <Modal open={modalOpen} onClose={closeModal}>
            <ModalInner title={`Download ${serviceDisplay}`} onClose={closeModal}>
                {
                    downloadComplete &&
                    <>
                        <Typography variant='body1' style={{ marginBottom: '24px' }}>Download complete</Typography>
                        <Button onClick={closeModal} variant='contained' color='primary'>Close</Button>
                    </>
                }
                {
                    !downloadComplete &&
                    <>
                        {children && React.cloneElement(children, { onChangeQuery: setDownloadQuery })}
                        <Typography style={{ marginBottom: '24px' }} variant='body1'>There are {recordCount || '--'} {serviceDisplay || 'records'} to download</Typography>
                        <Button 
                            onClick={downloadSpreadsheet} 
                            variant='contained' 
                            color='primary'
                            disabled={downloading}
                        >
                            {downloading ? 'Downloading...' : 'Download'}
                        </Button>
                    </>
                }
            </ModalInner>
        </Modal>
    </>);
}

export { DownloadData };