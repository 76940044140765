import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import { TextField } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useFormInputs } from "../../app/util";
import XIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';

const ResearchCandidate = ({
  onSubmit,
  onFinishReview,
  style,
  notesInitial,
  onChangeNotes,
  className
}) => {
  const [ formInputs, onChange ] = useFormInputs({ notes: notesInitial || '' })

  useEffect(() => {
    if(onChangeNotes) onChangeNotes(formInputs.notes)
  }, [ formInputs ])

  return (
    <SubmitReviewModalStyles style={style} className={className}>
      <Typography
        style={{ width: '100%' }}
        variant='body1'
      >
        Please provide notes on what changes you made or can be improved in the future (optional)
      </Typography>
      <TextField
        variant='outlined'
        name={`notes`}
        value={formInputs.notes}
        onChange={onChange}
        multiline
        minRows={4}
        style={{ overflowY: 'auto', marginBottom: '24px' }}
      />
      {
        onSubmit &&
        <div className="buttonWrapper">
          <Button
            startIcon={<XIcon style={{ color: '#FFFFFF' }}/>}
            onClick={() => onSubmit(false, formInputs.notes)}
            variant='contained'
            style={{ backgroundColor: '#D2042D' }}
          >Reject</Button>
          <Button
            startIcon={<CheckIcon style={{ color: '#FFFFFF' }}/>}
            onClick={() => onSubmit(true, formInputs.notes)}
            variant='contained'
            style={{ backgroundColor: '#006400' }}
          >Approve</Button>
        </div>
      }
      {
        onFinishReview && !onSubmit &&
        <Button
          onClick={() => onFinishReview()}
          variant='contained'
          color='primary'
          size='small'
        >Finish review</Button>
      }
    </SubmitReviewModalStyles>
  )
}

const SubmitReviewModalStyles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;

  .buttonWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 24px;
  }
`

export { ResearchCandidate };