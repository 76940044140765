import React, { useEffect, useState } from 'react';
import {isPermitted, useFeathers} from '../../app/util';
import { useSelector } from 'react-redux';
import {
  useParams,
  Link
} from 'react-router-dom';

import {
  LoadingSpinner,
  Tile,
  electionTypeWritten,
  ProgressBar,
  ElectionStatusTag,
  CoveragePlanMap,
  DataDetailToolbar,
  ElectionHealth,
  OfficesList,
  CircularProgressBar,
  ElectionTimeline,
  VotingDate
} from '../../components';
import styled from 'styled-components';
import moment from 'moment'
import Typography from '@mui/material/Typography';
import { IconButton } from '@mui/material';
import { Edit } from '@mui/icons-material';

const ElectionDetail = () => {
  const feathers = useFeathers();
  const electionKey = useParams().key;
  const user = useSelector(state => state.user);
  const assignmentPerms = isPermitted(user, ['editor', 'super-admin']);
  const datesEditPermission = isPermitted(user, ['editor', 'super-admin', 'admin']);
  const electionDataPreloaded = useSelector(state => state?.elections?.byKey[electionKey]);
  const [ election, setElection ] = useState(electionDataPreloaded);
  const [ electionImplementation, setElectionImplementation ] = useState(null)
  const [ loading, setLoading ] = useState(!Boolean(electionDataPreloaded));
  const canViewDetailedElectionData = isPermitted(user, ['editor', 'super-admin', 'researcher', 'reviewer', 'publisher', 'admin', 'dms-shapefile']);

  const displayName = user?.firstName || user?.name;
  const refreshElectionData = async () => {
    try {
      const newData = await feathers.getService('elections').get(
        electionKey
      );
      setElection(newData)
      const eiData = await feathers.getService('election-implementations').get(electionKey);
      setElectionImplementation(eiData)
      setLoading(false)
    } catch(err) {
      console.error(err)
    }
  }


  useEffect(() => {
    if(feathers) {
      refreshElectionData()
    }
  }, [ feathers ])

  if(loading) {
    return (
      <Wrapper>
        <LoadingTile>
          <LoadingSpinner scale={0.8}/>
        </LoadingTile>
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      <DataDetailToolbar
        onBack={{ to: {
            pathname: `/elections`,
          }}}
        navTree={[
          {
            text: 'Elections',
            to: {
              pathname: `/elections`,
            }
          },
          { text: election?.name }
        ]}
      />
      <Typography style={{ marginBottom: '24px', marginTop: '36px' }} variant={'h1'}>Welcome{displayName ? `, ${displayName}` : ''}!</Typography>
      <Content>
        <ElectionSummaryTile fullCoverageVisible={canViewDetailedElectionData ? 1 : 0}>
          <div
            style={{
              padding: '8px 8px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'stretch',
              justifyContent: 'space-between',
              height: 'calc(100% - 8px * 2)',
            }}
          >
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant={'body1'}>{election?.name}</Typography>
              <ElectionStatusTag election={election} />
            </div>
            <Typography variant={'h2'} style={{fontSize: '38px'}}>{moment.utc(election?.date).format('MMMM Do, YYYY')}</Typography>
            <Typography variant={'body1'}>{electionTypeWritten(election)}</Typography>
          </div>
        </ElectionSummaryTile>
        {
          canViewDetailedElectionData &&
          <ProgressSummaryTile fullCoverageVisible={canViewDetailedElectionData ? 1 : 0}>
            <div
              style={{
                padding: '0px 8px 8px',
                display: 'grid',
                gridTemplateRows: 'auto 1fr',
                height: 'calc(100% - 8px * 2)',
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center', gap: '8px', marginBottom: '8px'}}>
                <Typography variant={'body1'} style={{ fontWeight: 'bold' }}>Progress</Typography>
                <Link to={`/elections/${electionKey}/progress`}>
                  <Typography variant={'body2'}><u>Details</u></Typography>
                </Link>
              </div>
              <div style={{ display: 'flex', gap: '22px', alignItems: 'center', justifyContent: 'space-around' }}>
                <CircularProgressBar
                  percentage={Boolean(electionImplementation?.aggregatedInformation?.numScoutingDistricts) 
                    ? Math.round((electionImplementation?.aggregatedInformation?.numScoutingDistrictsCompleted / electionImplementation?.aggregatedInformation?.numScoutingDistricts) * 100)
                    : 0}
                  subtext={'scouting complete'}
                  dataTip={
                    `${typeof(electionImplementation?.aggregatedInformation?.numScoutingDistrictsCompleted) === 'undefined' 
                    ? '--' 
                    : electionImplementation?.aggregatedInformation?.numScoutingDistrictsCompleted
                  } of ${
                    typeof(electionImplementation?.aggregatedInformation?.numScoutingDistricts) === 'undefined'
                    ? '--'
                    : electionImplementation?.aggregatedInformation?.numScoutingDistricts
                  } districts scouted`}
                />
                <CircularProgressBar
                  percentage={Boolean(electionImplementation?.aggregatedInformation?.numProfiles) 
                    ? Math.round((electionImplementation?.aggregatedInformation?.numProfilesCompleted / electionImplementation?.aggregatedInformation?.numProfiles) * 100)
                    : 0}
                  subtext={'profiles complete'}
                  dataTip={
                    `${typeof(electionImplementation?.aggregatedInformation?.numProfilesCompleted) === 'undefined' 
                    ? '--' 
                    : electionImplementation?.aggregatedInformation?.numProfilesCompleted
                  } of ${
                    typeof(electionImplementation?.aggregatedInformation?.numProfiles) === 'undefined'
                    ? '--'
                    : electionImplementation?.aggregatedInformation?.numProfiles
                  } complete`}
                />
                <CircularProgressBar
                  percentage={Boolean(electionImplementation?.aggregatedInformation?.numRaces) 
                    ? Math.round((electionImplementation?.aggregatedInformation?.numRacesCompleted / electionImplementation?.aggregatedInformation?.numRaces) * 100)
                    : 0}
                  subtext={'races complete'}
                  dataTip={
                    `${typeof(electionImplementation?.aggregatedInformation?.numRacesCompleted) === 'undefined' 
                    ? '--' 
                    : electionImplementation?.aggregatedInformation?.numRacesCompleted
                  } of ${
                    typeof(electionImplementation?.aggregatedInformation?.numRaces) === 'undefined'
                    ? '--'
                    : electionImplementation?.aggregatedInformation?.numRaces
                  } complete`}
                />
                <CircularProgressBar
                  percentage={Boolean(electionImplementation?.aggregatedInformation?.numMeasures) 
                    ? Math.round((electionImplementation?.aggregatedInformation?.numMeasuresCompleted / electionImplementation?.aggregatedInformation?.numMeasures) * 100)
                    : 0}
                  subtext={'measures complete'}
                  dataTip={
                    `${typeof(electionImplementation?.aggregatedInformation?.numMeasuresCompleted) === 'undefined' 
                    ? '--' 
                    : electionImplementation?.aggregatedInformation?.numMeasuresCompleted
                  } of ${
                    typeof(electionImplementation?.aggregatedInformation?.numMeasures) === 'undefined'
                    ? '--'
                    : electionImplementation?.aggregatedInformation?.numMeasures
                  } complete`}
                />
              </div>
            </div>
          </ProgressSummaryTile>
        }
        <RacesTile onClick={{ to: `/elections/${electionKey}/races`}} fullCoverageVisible={canViewDetailedElectionData ? 1 : 0}>
          <div
            style={{
              padding: '8px 8px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'stretch',
              justifyContent: 'space-between',
              height: 'calc(100% - 8px * 2)',
            }}
          >
            <Typography variant={'body1'}>Races</Typography>
            <Typography variant={'h2'} style={{fontSize: '38px'}}>{electionImplementation?.aggregatedInformation?.numRaces ?? 0}</Typography>
            <Typography variant={'body1'}><u>View races</u></Typography>
          </div>
        </RacesTile>
        <MeasuresTile onClick={{ to: `/elections/${electionKey}/measures`}} fullCoverageVisible={canViewDetailedElectionData ? 1 : 0}>
          <div
            style={{
              padding: '8px 8px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'stretch',
              justifyContent: 'space-between',
              height: 'calc(100% - 8px * 2)',
            }}
          >
            <Typography variant={'body1'}>Referendums</Typography>
            <Typography variant={'h2'} style={{fontSize: '38px'}}>{electionImplementation?.aggregatedInformation?.numMeasures ?? 0}</Typography>
            <Typography variant={'body1'}><u>View referendums</u></Typography>
          </div>
        </MeasuresTile>
        {
          canViewDetailedElectionData &&
          <CoveragePlanTile style={{}} fullCoverageVisible={canViewDetailedElectionData ? 1 : 0}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px'}}>
              <Typography variant={'body1'} style={{ fontWeight: 'bold' }}>Coverage plan</Typography>
              <Link to={`/elections/${electionKey}/coverage-plan`}>
                <Typography variant={'body2'}><u>Details</u></Typography>
              </Link>
            </div>
            <div style={{ height: 'calc(176px * 2 - 52px)', width: '100%' }}>
              {
                electionImplementation?.supportedArea?.length > 0
                ? <CoveragePlanMap
                  state={election?.state?._id}
                  supportedArea={electionImplementation?.supportedDistricts}
                  expectedReadershipForDistricts={electionImplementation?.expectedReadershipForDistricts}
                  priorityArea={electionImplementation?.priorityAreas}
                />
                : <Typography variant={'body1'}>No coverage plan set yet for this election.</Typography>
              }
            </div>
          </CoveragePlanTile>
        }
        {
          canViewDetailedElectionData &&
          <ElectionHealthTile fullCoverageVisible={canViewDetailedElectionData ? 1 : 0} onClick={{ to: `/elections/${electionKey}/health`}}>
            <div
              style={{
                padding: '8px 8px',
                display: 'grid',
                gridTemplateRows: 'auto 1fr',
                height: 'calc(100% - 8px * 2)',
              }}
            >
              <Typography variant={'body1'} style={{ fontWeight: 'bold' }}>Districts health</Typography>
              <ElectionHealth
                election={election}
                showDetails={false}
              />
            </div>
          </ElectionHealthTile>
        }
        <DatesTile>
          <div
            style={{
              padding: '8px 8px',
              height: 'calc(100% - 8px * 2)',
              display: 'grid',
              gridGap: '36px',
              gridTemplateColumns: '1fr 1fr',
              position: 'relative'
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'column'}}>
              <Typography variant={'body1'} style={{ fontWeight: 'bold' }}>Election dates</Typography>
              <ElectionTimeline
                election={election}
                datesPassed='show'
                subtextEnabled={false}
                mode='calendar'
              />
            </div>
            {
              canViewDetailedElectionData
              ? <div style={{ display: 'flex', flexDirection: 'column'}}>
                  <Typography variant={'body1'} style={{ fontWeight: 'bold' }}>Project dates</Typography>
                  <div style={{
                    display: 'grid',
                    gridTemplateColumns: `auto 1fr auto`,
                    gridGap: `8px 8px`,
                    gridTemplateRows: 'repeat(3, auto)',
                    alignItems: 'flex-start'
                  }}>
                    <VotingDate
                      dateTitle='Qualifying ends'
                      dateWritten={election?.qualifyingDate ? moment.utc(election?.qualifyingDate).format('MMM D') : '--'}
                    />
                    <VotingDate
                      dateTitle='Race scouting starts'
                      dateWritten={electionImplementation?.raceScoutingStartDate ? moment.utc(electionImplementation?.raceScoutingStartDate).format('MMM D') : '--'}
                    />
                    <VotingDate
                      dateTitle='Measure scouting starts'
                      dateWritten={electionImplementation?.measureScoutingStartDate ? moment.utc(electionImplementation?.measureScoutingStartDate).format('MMM D') : '--'}
                    />
                    <VotingDate
                      dateTitle='Go live'
                      dateWritten={electionImplementation?.goLiveDate ? moment.utc(electionImplementation?.goLiveDate).format('MMM D') : '--'}
                    />
                  </div>
                </div>
              : <div/>
            }
            {
              datesEditPermission &&
              <Link to={`/elections/${electionKey}/dates/edit`}  style={{ position:'absolute', right: 0, top: 0}}>
                <IconButton size='small'>
                  <Edit fontSize='small' />
                </IconButton>
              </Link>
            }
          </div>
        </DatesTile>
        <OfficeListTile style={{}} fullCoverageVisible={canViewDetailedElectionData ? 1 : 0}>
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px'}}>
            <Typography variant={'body1'} style={{ fontWeight: 'bold' }}>What's on the ballot</Typography>
            <Link to={`/offices?election=${electionKey}`}>
              <Typography variant={'body2'}><u>Details</u></Typography>
            </Link>
          </div>
          <div style={{ height: 'calc(176px * 2 - 52px)', width: '100%' }}>
            <OfficesList
              aggregatedInformation={electionImplementation?.aggregatedInformation}
              loading={!Boolean(electionImplementation)}
              canViewDetailedElectionData={canViewDetailedElectionData}
            />
          </div>
        </OfficeListTile>
      </Content>
    </Wrapper>
  )
}


const Wrapper = styled.div`
  width: calc(100% - 2 * 24px);
  min-height: calc(100% - 24px * 2);
  display: flex;
  flex-direction: column;
  padding: 24px;
  align-items: stretch;
`;

const DashboardTile = ({style, ...props}) => <Tile {...({
  ...(props || {}),
  shadowEnabled: true,
  chevronEnabled: false,
  style: {
    ...(style || {}),
  }
})}/>

/*
   Defining our grid for the tiles
   width between 700 and 1000 will be 3 columns
   width >= 1000 will be 4 columns
* */

const range2 = `(min-width: 700px) and (max-width: 1000px)`
const range3 = `(min-width: 1000px)`

const Content = styled.div`
  width: 100%;
  display: grid;
  grid-gap: 16px;
  align-items: stretch;
  grid-template-rows: repeat(4, 176px);

  @media${range2} {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media${range3} {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`

const ElectionSummaryTile = styled(DashboardTile)`
  @media${range2} {
    grid-row: 1 / span 1;
    grid-column: 1 / span 2;
  }

  @media${range3} {
    grid-row: 1 / span 1;
    grid-column: 1 / span 2;
  }
`

const ProgressSummaryTile = styled(DashboardTile)`
  @media${range2} {
    grid-column: 2 / span 2;
    grid-row: 5 / span 1;
  }

  @media${range3} {
    grid-row: 2 / span 1;
    grid-column: 3 / span 2;
  }
`

const RacesTile = styled(DashboardTile)`

  ${props => props.fullCoverageVisible ?
    `
    @media${range2} {
      grid-row: 1 / span 1;
      grid-column: 3 / span 1;
    }

    @media${range3} {
      grid-row: 3 / span 1;
      grid-column: 3 / span 1;
    }
  ` : `
    @media${range2} {
      grid-row: 1 / span 1;
      grid-column: 3 / span 1;
    }

    @media${range3} {
      grid-row: 2 / span 1;
      grid-column: 3 / span 1;
    }
  `}  
`

const MeasuresTile = styled(DashboardTile)`
  ${props => props.fullCoverageVisible ?
    `
    @media${range2} {
      grid-row: 2 / span 1;
      grid-column: 3 / span 1;
    }
  
    @media${range3} {
      grid-row: 3 / span 1;
      grid-column: 4 / span 1;
    }
  ` : `
    @media${range2} {
      grid-row: 2 / span 1;
      grid-column: 3 / span 1;
    }

    @media${range3} {
      grid-row: 2 / span 1;
      grid-column: 4 / span 1;
    }
  `}  
`

const CoveragePlanTile = styled(DashboardTile)`
  display: flex;
  flex-direction: column;
  align-items: stretch;

  @media${range2} {
    grid-row: 3 / span 2;
    grid-column: 1 / span 3;
  }
  overflow-y: auto;
  @media${range3} {
    grid-row: 2 / span 2;
    grid-column: 1 / span 2;
  }
`

const OfficeListTile = styled(DashboardTile)`
  display: flex;
  flex-direction: column;
  align-items: stretch;

  ${props => props.fullCoverageVisible ?
  `
    @media${range2} {
      grid-row: 6 / span 2;
      grid-column: 1 / span 3;
    }
    @media${range3} {
      grid-row: 4 / span 2;
      grid-column: 1 / span 2;
    }
  `: `
    @media${range2} {
      grid-row: 3 / span 2;
      grid-column: 1 / span 2;
    }
    @media${range3} {
      grid-row: 2 / span 2;
      grid-column: 1 / span 2;
    }
  `
  }
  
  overflow-y: auto;
  
`

const ElectionHealthTile = styled(DashboardTile)`
  @media${range2} {
    grid-column: 1 / span 1;
    grid-row: 5 / span 1;
  }
  @media${range3} {
    grid-column: 4 / span 1;
    grid-row: 3 / span 1;
  }
`

const DatesTile = styled(DashboardTile)`
  @media${range2} {
    grid-row: 2 / span 1;
    grid-column: 1 / span 2;
  }
  @media${range3} {
    grid-column: 3 / span 2;
    grid-row: 1 / span 1;
  }

  overflow-y: auto;
`

const LoadingTile = styled.div`
  width: 100%;
  align-self: center;
  justify-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;


const CoverageProgressBar = ({ numerator, denominator, name, style, size }) => (
  <div style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'stretch', ...(style || {}) }}>
    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '2px', justifyContent: 'space-between' }}>
      <Typography variant={'body1'} style={size === 'small' ? { fontSize: '14px'} : { fontWeight: 'bold' }}>{numerator} out of {denominator} {name} complete</Typography>
      <Typography variant={'body1'} style={size === 'small' ? { fontSize: '14px'} : { fontWeight: 'bold' }}>{denominator === 0 ? 100 : Math.round(numerator / denominator * 100)}%</Typography>
    </div>
    <ProgressBar percentage={denominator === 0 ? 100 : Math.round(numerator / denominator * 100)} height={size === 'small' ? 4 : 12} backgroundColor={'#D8D8D8'}/>
  </div>
)


export default ElectionDetail;
