import React, { useState, useEffect, useMemo } from 'react';
import {
  useFeathers,
} from '../../app/util';
import Button from '@mui/material/Button';

import {
  EditMeasure,
  TaskPage,
  TimeTracker,
} from '../../components';
import styled from 'styled-components';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const mapDraftToServerInputs = (draft) => {
  const {
    title,
    descriptionShort,
    ballotText,
    actNumbers,
    whatItMeans,
    commentaryAvailable,
    supportersSay,
    opponentsSay,
    sources,
  } = draft;
  return {
    title,
    descriptionShort,
    ballotText,
    actNumbers,
    whatItMeans,
    commentaryAvailable,
    supportersSay,
    opponentsSay,
    sources: (sources || []).map(({ _id, ...rest }) => rest),
  }
}

const PublishMeasureTask = () => {
  const feathers = useFeathers();
  const user = useSelector(state => state.user);
  const [task, setTask] = useState(null);
  const [saving, setSaving] = useState(false); 
  const [taskPageSaving, setTaskPageSaving] = useState(false);
  const [feedbackSaving, setFeedbackSaving] = useState(false);
  const [completionData, setCompletionData] = useState(null)
  const [showingFeedbackDialog, setShowingFeedbackDialog] = useState(false);

  const [measure, setMeasure] = useState(null);
  const [measureEdit, setMeasureEdit] = useState(null);

  const createFeedback = async (loadNewTask) => {
    if (feedbackSaving) return;
    setFeedbackSaving(true);

    try {
      // first, load the new candidate after publishing the updates 
      const afterDraft = await feathers.getService('measures').get(measure._id);

      const feedback = await feathers.getService('profile-feedbacks').create({
				measure: measure?._id,
				versionNumberBefore: draftVersionNumber,
				versionNumberAfter: afterDraft.previousDrafts[afterDraft.previousDrafts.length - 1].version
			});
      console.log(`Feedback created`)
      const linkToFeedback = `/elections/${measure?.election?.key || measure.election}/measures/${measure?._id}/feedback/${feedback._id}?confirm=true`
      console.log(linkToFeedback)

      window.open(linkToFeedback, "_blank");
    } catch (err) {
      console.error(`Error in creating feedback: `, err);
    } finally {
      setFeedbackSaving(false)
      loadNewTask()
      setShowingFeedbackDialog(false)
    }
  }
  
  const onCheckoutTask = async (task, loadNextTask) => {
    setMeasure(null);
    setMeasureEdit(null);
    setTask(task);
    let measure;
    try {
      measure = await feathers.getService('measures').get(task.details.measure);
    } catch(err) {
      if(err.name === 'NotFound') {
        // bad measure, we need to cancel this task
        console.log('measure not found')
        await feathers.getService('research-tasks').patch(task._id, {
          $cancelTask: true
        });
        setTask(null);
        loadNextTask()
        return
      } else {
        throw err;
      }
    }
    setMeasure(measure);
    const { updatedAt: holdingDataUpdatedAt, ...holdingData } = task?.holdingData || {};
    // seed inputs with most recently updated data from holdingData or measure record
    const { ...rest } = (
      ((holdingData && holdingDataUpdatedAt > measure?.updatedAt)
        ? holdingData
        : measure) 
      || {}
    )

    const mappedMeasure = {
      ...rest,
    };

    setMeasureEdit(mappedMeasure);
    console.log('measure', mappedMeasure)
  }

  useEffect(() => {
    if (measure && measureEdit) setCompletionData(mapDraftToServerInputs(measureEdit))
  }, [measure, measureEdit, task])

  const [submittedByUser, draftVersionNumber] = useMemo(() => {
    if(!task || !measure) return [null, null];
    let userToRef = task.details?.submittedBy;

    // find first non-user who worked on this
    const draftsPossible = [
      ...(measure.previousDrafts || [])
    ].filter(Boolean)

    const draft = draftsPossible.find(d => userToRef ? d?.user?._id === userToRef : (d?.user?._id && d?.user?._id !== user._id));
    return [ draft?.user, draft?.version ];
  }, [ task, user, measure ])

  const submittedByUserName = useMemo(() => {
    return (submittedByUser?.firstName || submittedByUser?.lastName)
      ? [submittedByUser?.firstName, submittedByUser?.lastName].filter(Boolean).join(' ')
      : (submittedByUser?.name || submittedByUser?.email || '--')
  }, [ submittedByUser ])

  return (
    <TaskPage
      task={task}
      taskType='publish-measure'
      onCheckoutTask={onCheckoutTask}
      completionData={completionData}
      holdingData={measureEdit}
      completeButtonLabel='Publish'
      onChangeSaving={(saving) => setTaskPageSaving(saving)}
      disableTimeTracker
      sidePaneConfig={([
        {
          title: 'Submitted by',
          content: (
            <>
              <Typography variant="body2" style={{ marginTop: '6px' }}>You're reviewing a profile submitted by</Typography>
              <Typography variant="h3" style={{ marginTop: '8px' }}>
                {submittedByUserName}
              </Typography>
            </>
          ),
          initiallyExpanded: true,
        },
        {
          title: 'Priority',
          content: (
            <>
              <TimeTracker
                seedTask={task}
                isReferendum
                benchmarkMode='review'
              />
            </>
          ),
          initiallyExpanded: true,
        },
      ])}
      onCompleteTaskBeforeLoadNext={(draftVersionNumber) ? () => setShowingFeedbackDialog(true) : null}
      onCompleteTaskDialog={
        (loadNextTask) => (
          <Dialog
            open={showingFeedbackDialog}
            onClose={() => {
              loadNextTask()
              setShowingFeedbackDialog(false)
            }}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-body'
          >
            <DialogTitle id='alert-dialog-title'>
              Give feedback?
            </DialogTitle>
            <DialogContent>
              <DialogContentText id='alert-dialog-body'>
                Do you want to give feedback to {submittedByUserName} on their profile?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button 
                onClick={() => {
                  loadNextTask()
                  setShowingFeedbackDialog(false)
                }}
                color='primary'
              >
                Skip
              </Button>
              <Button 
                onClick={() => createFeedback(loadNextTask)} 
                color='primary' 
                autoFocus
              >
                Yes
              </Button>
            </DialogActions>
          </Dialog>
        )
      }
    >
      <WrapperInner>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '8px', marginTop: '36px'}}>
          <Typography variant='h2' style={{  }}>{measure?.title}</Typography>
          <Typography variant='body1' style={{ marginBottom: '0px' }}>For {(measure?.originalDistrict || measure?.district)?.longName} in {measure?.election?.name}</Typography>
          <Link to={`/elections/${measure?.election?.key || measure?.election}/measures/${measure}`} target='_blank'>
            <div style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}>
              <Typography variant='body2'>Full page</Typography>
              <OpenInNewIcon style={{ width: '15px', height: '15px' }}/>
            </div>
          </Link>
        </div>
        <EditMeasure
          value={measureEdit}
          onChange={(e) => {
            const { name, value } = e.target;
            setMeasureEdit(existingDraft => {
              return {
                ...existingDraft,
                [name]: value
              }
            })
          }}
          disabled={saving || taskPageSaving} // being set in child
        />
      </WrapperInner>
    </TaskPage>
  );
};

const WrapperInner = styled.div`
  padding: 0 24px;
`
export default PublishMeasureTask;
