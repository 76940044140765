import React from 'react';

import ArchiveIcon from '@mui/icons-material/Archive';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Tooltip from '@mui/material/Tooltip';

import styled from 'styled-components';


const CoverageIcon = ({ coverageStatus, style = {}, className = '', onClick }) => {
  let statusText, icon;
  if(coverageStatus === 'archived') {
    statusText = 'Archived';
    icon = <ArchiveIcon style={{ color: '#080808' }} />;
  } else if(coverageStatus === 'no-coverage') {
    statusText = 'In progress';
    icon = <HourglassEmptyIcon style={{ color: '#080808' }} />;
  } else {
    statusText = 'Published';
    icon = <CheckCircleIcon style={{ color: 'green' }} />;
  }

  return (
    <Wrapper style={style} className={className} onClick={onClick}>
      <Tooltip title={statusText}>
        {icon}
      </Tooltip>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  height: auto;
  display: flex;
  align-items: center;
  `;

export { CoverageIcon };
