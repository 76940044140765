import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { Tile, ColorTag } from '../lower-order';
import Typography from "@mui/material/Typography";
import { CircularProgressBar } from '../CircularProgressBar';

const ElectionPreviewTile = ({
  onClick,
  style,
  election,
  condensed
}) => {
  return (
    <Tile
      onClick={onClick}
      shadowEnabled={true}
      style={{
        padding: condensed ? '8px 18px' : '28px 18px',
        ...style
      }}
    >
      <Inner condensed={condensed ? 1 : 0}>
        <div style={{ style: 'flex', flexDirection: 'column', gap: '2px' }}>
          <Typography variant={'h3'} className='title'>
            {election.name}
          </Typography>
          <Typography variant={'body1'}>
            {electionTypeWritten(election)}
          </Typography>
        </div>
        <div>
          <Typography variant={'body1'}>
            {moment.utc(election.date).format('MMMM D, YYYY')}
          </Typography>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', gap: '24px', alignItems: 'center' }}>
          <CircularProgressBar
            size={40}
            percentage={Boolean(election?.numScoutingDistricts) 
              ? Math.round((election?.numScoutingDistrictsCompleted / election?.numScoutingDistricts) * 100)
              : 0}
            dataTip={
              `${typeof(election?.numScoutingDistrictsCompleted) === 'undefined' 
              ? '--' 
              : election?.numScoutingDistrictsCompleted
            } of ${
              typeof(election?.numScoutingDistricts) === 'undefined'
              ? '--'
              : election?.numScoutingDistricts
            } districts scouted`}
          />
          <CircularProgressBar
            size={40}
            percentage={Boolean(election?.numProfiles) 
              ? Math.round((election?.numProfilesCompleted / election?.numProfiles) * 100)
              : 0}
            dataTip={
              `${typeof(election?.numProfilesCompleted) === 'undefined' 
              ? '--' 
              : election?.numProfilesCompleted
            } of ${
              typeof(election?.numProfiles) === 'undefined'
              ? '--'
              : election?.numProfiles
            } profiles complete`}
          />
        </div>
        <ElectionStatusTag election={election} />
      </Inner>
    </Tile>
  )
}

const ElectionStatusTag = ({ election }) => {
  let themeColor, statusText;
  if(election.status === 'live') {
    themeColor = 'green';
    statusText = 'LIVE';
  } else if(election.status === 'prep') {
    themeColor = 'yellow';
    statusText = 'UNPUBLISHED';
  } else if(election.status === 'results-certified') {
    themeColor = 'black';
    statusText = 'CERTIFIED'
  } else if(election.status === 'results-tentative') {
    themeColor = 'black';
    statusText = 'FINISHED'
  }

  return (
    <ColorTag themeColor={themeColor} style={{ marginLeft: '14px'}}>
      {statusText}
    </ColorTag>
  )
}

const electionTypeWritten = (election) => {
  let electionTypeWritten;
  switch(election.electionType) {
    case 'primary':
      electionTypeWritten = 'Primary Election'
      break;
    case 'primary-runoff':
      electionTypeWritten = 'Primary Runoff'
      break;
    case 'general':
      electionTypeWritten = 'General Election'
      break;
    case 'general-runoff':
      electionTypeWritten = 'General Runoff'
      break;
    case 'special':
      electionTypeWritten = 'Special Election'
      break;
    default:
      electionTypeWritten = 'Unknown Type'
      break;
  }
  return electionTypeWritten
}

const Inner = styled.div`
  width: calc(100% - 24px);
  padding: 0 24px 0 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 120px;
  grid-gap: 12px;
  align-items: center;

  div > .title {
    ${props => props.condensed ? '' : 'font-size: 20px;'}
    ${props => props.theme.font.bold}
  }


`;

export { ElectionPreviewTile, ElectionStatusTag, electionTypeWritten };
