import React from 'react';
import styled from 'styled-components';
import { CandidateProgressBar } from '../ProgressBar';
import { CandidatePhoto } from '../CandidatePhoto';
import Typography from "@mui/material/Typography";
import { ChevronRightButton } from '../ChevronRightButton';
import {onClickOrLink} from "../../app/util";

import { ReactComponent as WarningIcon } from '../../images/yellow-warning.svg';
import { ReactComponent as GreenCheck } from '../../images/green-check.svg';
/*
*  A preview tile used within the RaceDetail page. Displays a candidate's
*  name, photo, progress, and sync status
*/

const CandidatePreviewTile = ({
  candidate,
  onClick
}) => {

  const { _id, stagingDraft, workflow, party, coveragePlan } = candidate;
  const { name, photoPathFace, progress, synced } = stagingDraft;

  return (
    <CandidatePreviewInner
      {...(
        Boolean(onClick) ? onClickOrLink(onClick) : {}
      )}
      clickable={Boolean(onClick) ? 1 : 0}
    >
      <CandidatePhoto style={{ height: '36px', width: '36px' }} src={photoPathFace}/>
      <Typography variant={'body1'} style={{ fontWeight: 'bold', textDecoration: 'none' }}>{name}</Typography>
      <Typography variant={'body1'} style={{ fontWeight: 'bold', textDecoration: 'none' }}>({party})</Typography>
      <div />
      {
        coveragePlan === 'auto-coverage' &&
        <Typography variant={'body1'} style={{ textDecoration: 'none' }}>
          {workflow.status === 'draft' && 'Draft'}
          {workflow.status === 'references-added' && workflow.scrapingDetails.infoToScrape && 'Awaiting profiler'}
          {(
            workflow.status === 'auto-profile-created' || 
            (workflow.status === 'references-added' && !workflow.scrapingDetails.infoToScrape) 
          ) && 'Awaiting review'}
          {workflow.status === 'auto-profile-reviewed' && 'Awaiting final review'}
        </Typography>
      }
      <CandidateProgressBar percentage={progress*100} color='#99D266' backgroundColor='#DDDDDD' candidate={stagingDraft}/>
      <SyncStatus style={{ justifySelf: 'center' }} data-tip={
        (synced && workflow.status !== 'draft')
          ? 'All changes are live'
          : 'There are some changes that<br/>have not been reviewed or published.'
      }>
        {
          (synced && workflow.status !== 'draft')
            ? <GreenCheck />
            : <WarningIcon />
        }
      </SyncStatus>
      <ChevronRightButton style={{ justifySelf: 'right' }}/>
    </CandidatePreviewInner>
  )
}

const CandidatePreviewInner = styled.div`
  padding: 8px 16px;
  display: grid;
  grid-gap: 12px;
  grid-template-columns: 50px auto auto 1fr 150px 100px 50px 50px;
  align-items: center;
  border-bottom: solid 1px #EEEEEE;
  text-decoration: none;
  
  p {
    text-decoration: none;
  }
`

const SyncStatus = styled.div`
  color: #22034F;
  ${props => props.theme.font.bold}
  font-size: 14px;
  
  svg {
    width: 24px;
    height: 24px;
  }
`;

export { CandidatePreviewTile };
