import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useFeathers } from '../../app/util';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import {
  LoadingSpinner,
  DataDetailToolbar,
  StyledMarkdown,
  CirclePhoto,
  ViewMeasureDiff,
  ViewCandidateDiff
} from '../../components'
import LinkList from '../../components/LinkList/LinkList';
import qs from 'qs';
import { Button, TextField } from '@mui/material';

const FeedbackDetail = () => {
  const feathers = useFeathers();
  const history = useHistory();
  const location = useLocation();
  const { confirm } = qs.parse(location.search, { ignoreQueryPrefix: true });

  const { id: candidateId, feedbackId, raceId, key: electionKey, measureId } = useParams();
  const feedbackType = Boolean(candidateId) ? 'candidate' : 'measure';

  const [ loading, setLoading ] = useState(false)
  const [ candidate, setCandidate ] = useState(null);
  const [ race, setRace ] = useState(null);
  const [ measure, setMeasure ] = useState(null);
  const [ feedback, setFeedback ] = useState(null);
  const [ error, setError ] = useState(null);
  const [ note , setNote ] = useState('')
  
  useEffect(() => {
    if(feathers) {
      loadData()
    }
  }, [ feathers, feedbackId ])

  // Declare functions
  const loadData = async () => {
    if(loading) return;

    setLoading(true)
    try {
      const feedback = await feathers.getService('profile-feedbacks').get(feedbackId);
      setFeedback(feedback)
      setLoading(false)

      if(feedbackType === 'candidate') {
        const candidate = await feathers.getService('candidates').get(feedback.candidate);
        const race = await feathers.getService('races').get(candidate?.race?._id || candidate?.race);
        
        setRace(race);
        setCandidate(candidate);
      } else if(feedbackType === 'measure') {
        const measure = await feathers.getService('measures').get(feedback.measure);
        setMeasure(measure);
      }
    } catch (err) {
      console.log(`Error in retrieving candidate: `, err)
      setError(err)
    }
  }

  const errorMessage = useMemo(() => {
    if(!error) return;
    if (typeof error === 'string') return error;
    if (typeof error === 'object') {
      if (error.type === 'FeathersError') {
        let messageToShow;
        if(error?.errors && Object.values(error.errors).length > 0) {
          messageToShow = Object.values(error.errors).join(' ')
        } else {
          messageToShow = error?.message;
        }
      switch(error.code) {
        case 400:
          if(messageToShow) return messageToShow;
          return 'There was a problem with the data you submitted. Please check the form and try again.';
        case 401:
        case 403:
          if(messageToShow) return messageToShow;
          return 'You are not authorized to perform this action.';
        default:
          return messageToShow;
        }
      } else return error.message;
    }

    return 'There was an error when saving. Please try again!';
  }, [error]);

  const confirmFeedback = async () => {
    if(loading) return;
    setLoading(true);

    try {
      const noteValueClean = (note || '').trim();
      const feedback = await feathers.getService('profile-feedbacks').patch(feedbackId, {
        $sendEmail: true,
        ...(noteValueClean ? { note: noteValueClean } : {})
      })

      history.push(location.pathname)
      setFeedback(feedback)
      setLoading(false);
    } catch (err) {
      console.log(`Error in saving`)
      setError(err)
    }
  }

  if(!feedback) {
    return <LoadingSpinner />
  }

  const candidatePhotoPreview = () => (
    <Top>
      <TopInner>
        <CirclePhoto size='large' photoPath={candidate?.stagingDraft?.photoPathFace}/>
        <TopText>
          <div style={{ flex: 1, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Typography variant='h2' color='textPrimary'>
              { candidate?.stagingDraft?.name }
            </Typography>
          </div>
          <Typography variant='body1' color='textPrimary' style={{ marginTop: '8px', marginBottom: '8px' }}>{candidate?.name} is running for {race?.officeName} in {race?.district?.longName}.</Typography>
          <LinkList links={candidate?.stagingDraft?.links || []} align='flex-start'/>
        </TopText>
      </TopInner>
    </Top>
  )

  return (
    <Wrapper>
      <Snackbar
        open={error != null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        autoHideDuration={ 6000 }
        style={{position: 'absolute'}}
        onClose={() => setError(undefined)}
      >
        <Alert
          severity="error"
          onClose={() => setError(undefined)}
          elevation={6}
          variant="filled"
        >
          {errorMessage}
        </Alert>
      </Snackbar>
      <DataDetailToolbar
        style={{
          position: 'sticky',
          top: 0,
          zIndex: 1,
          width: 'calc( 100% - 36px * 2 )',
          padding: '16px 36px 16px',
          height: '72px'
        }}
        actionButtonsComponent={
          confirm
          ? <div>
            <Button variant='contained' color='primary' disabled={loading} onClick={confirmFeedback}>Send feedback</Button>
          </div>
          : null
        }
        onBack={{ 
          to: {
            pathname: feedbackType === 'candidate'
              ? `/elections/${electionKey}/races/${raceId}/candidates/${candidateId}`
              : `/elections/${electionKey}/measures/${measureId}`,
          }}
        }
        navTree={[
          {
            text: 'Election',
            to: {
              pathname: `/elections/${electionKey}/races`,
            }
          },
          feedbackType === 'candidate' ?
            {
              text: race?.officeName || '--',
              to: {
                pathname: `/elections/${electionKey}/races/${raceId}`,
              },
            } : null,
          { 
            text: feedbackType === 'candidate' ? (candidate?.name || '') : (measure?.title || ''),
            to: {
              pathname: feedbackType === 'candidate' 
                ? `/elections/${electionKey}/races/${raceId}/candidates/${candidateId}`
                : `/elections/${electionKey}/measures/${measureId}`,
            },
          },
          { text: 'Feedback' }
        ].filter(Boolean)}
      />
      <WrapperInner
      >
        <Main>
          <ContentWrapper>
            {
              Boolean(confirm) &&
              <NoteWrapper>
                <Typography variant='body2' color='textPrimary'>Add a note (optional)</Typography>
                <TextField variant='outlined' name='note' multiline={true} onChange={(e) => setNote(e.target.value)} value={note} />
              </NoteWrapper>
            }
            {
              Boolean(feedback.note) && 
              <NoteWrapper>
                <Typography variant='body2' color='textPrimary'>Feedback from {feedback?.generatedBy?.name || feedback?.generatedBy?.firstName}</Typography>
                <Typography variant='body1' color='textPrimary' style={{ fontWeight: 'bold' }}>{feedback.note}</Typography>
              </NoteWrapper>
            }
            {
              feedbackType === 'candidate' && candidatePhotoPreview()
            }
            {
              feedbackType === 'candidate' && feedback?.diff &&
              <ViewCandidateDiff diff={feedback.diff} />
            }
            {
              feedbackType === 'measure' && feedback?.diff &&
              <ViewMeasureDiff diff={feedback.diff} />
            }
          </ContentWrapper>
        </Main>
      </WrapperInner>
      
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: calc(100%);
  height: calc(100vh);
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const WrapperInner = styled.div`
  padding: 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: calc(100% - 72px);
  max-height: calc(100% - 72px);
  width: 100%;
`

const ContentWrapper = styled.div`
  width: calc(100% - 64px);
  max-width: calc(700px - 64px);
  padding: 32px 0 32px 64px;
  align-self: start;

  display: flex;
  flex-direction: column;
`

const Top = styled.div`
  padding: 24px 0;
  margin-left: -8px;
  width: calc(100%);

  display: flex;
  flex-direction: column;
  align-items: stretch;
`

const TopInner = styled.div`
  width: 100%;
  max-width: 700px;
  align-self: center;

  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  @media(min-width: 500px) {
    align-items: center;
  }
`

const NoteWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 24px;
  margin-bottom: 24px;

  border: solid 1px #eeeeee;
  border-radius: 6px;
  background-color: #f9f9f9;
`

const TopText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1;
  margin-left: 12px;
`

const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-left: -24px;
  padding: 0 0 0;
  border-top: 1px solid #EEEEEE;
  width: calc(100% + 24px * 2);
  background-color: #FFFFFF;
  overflow-y: scroll;
`;

export default FeedbackDetail;
