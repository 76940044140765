import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormInputs, useFeathers, isPermitted } from '../../app/util';
import styles from './styles';
import { TextInput, CTAButton } from '../../components';
import { userLogin } from '../../app/actions';
import { useHistory, useLocation } from 'react-router-dom';
import { ReactComponent as Blob } from '../../images/Vector.svg';
import PurpleLogo from '../../images/BranchLogo_DarkPurple-02.png';
import { Forbidden } from '@feathersjs/errors';
import qs from 'qs';
import Typography from "@mui/material/Typography";

const Login = () => {
  const feathers = useFeathers();
  const dispatch = useDispatch();
  const {
    loggedIn
  } = useSelector(state => state.user);

  const [ formInputs, onChange ] = useFormInputs({ email: '', password: '' });
  const submittable = formInputs.email && formInputs.password;

  const [ error, setError ] = useState(null);
  const [ isLoggingIn, setIsLoggingIn ] = useState(false);

  let history = useHistory();
  let location = useLocation();

  let formattedError;
  if(error) {
    if(error.code === 401) {
      formattedError = `Incorrect email or password. Please try again.`;
    } else if(error.code >= 403) {
      formattedError = `This user does not have admin access.`;
    } else if(error.code >= 500) {
      formattedError = `We're having an issue with our servers. Please try again.`;
    } else {
      console.error('Need to other error types')
      formattedError = 'There was an error. Please double-check your inputs.';
    }
  }

  const login = async (event) => {
    event.preventDefault();
    if(isLoggingIn) return;

    const { email, password } = formInputs;
    setIsLoggingIn(true);
    setError(null);
    try {
      const authRes = await feathers.authenticate({
        strategy: 'local',
        email,
        password,
      })
      if(!isPermitted(authRes.user, ['admin', 'super-admin', 'auditor', 'editor', 'researcher', 'reviewer', 'publisher', 'user-management'])) {
        // if a user without these permissions has a valid invitation and is coming from the invitation page-- then they can log in.
        // after logging in, they are redirected back to the invitation page.
        if(location.state?.from.pathname === '/acceptInvite') {
          const { invitationId } = qs.parse(location.state.from.search, { ignoreQueryPrefix: true });
          const invitation = await feathers?.getService('invite-links').get(invitationId, { query:
            { email: formInputs.email, type: 'data-editor', valid: true }
          })
          if (!invitation) throw new Forbidden('user is not an admin.')
        } else {
          throw new Forbidden('user is not admin.')
        }
      }
      dispatch(userLogin(authRes.user));
    } catch (err) {
      setError(err)
    } finally {
      setIsLoggingIn(false)
    }
  }

  let { from } = location.state || { from: { pathname: "/elections" } };
  useEffect(() => {
    if(loggedIn) history.replace(from);
  }, [ loggedIn, from, history ])

  return (
    <div style={styles.wrapper}>
      <Blob style={styles.backgroundBlob}/>
      <img src={PurpleLogo} style={styles.branchLogo}/>
      <form style={styles.center} onSubmit={login}>
        <h1 style={styles.heading}>{`Welcome back to Branch`}</h1>
        <p style={styles.p}>{`It's a good day to change the world.`}</p>
        {
          (formattedError)
            ?
          (<p className='error-text'>{formattedError}</p>)
            :
          null
        }
        <TextInput
          disabled={isLoggingIn}
          type='text'
          name='email'
          value={formInputs?.email}
          onChange={onChange}
          placeholder='Email'
          hasError={false}
        />
        <TextInput
          disabled={isLoggingIn}
          type='password'
          name='password'
          value={formInputs?.password}
          onChange={onChange}
          placeholder='Password'
          style={{
            marginBottom: '12px'
          }}
        />
        <a style={{ width: '100%' }} href={'https://www.branch.vote/forgot-password'}>
          <Typography variant={'body1'} style={{ width: '100%', fontWeight: 'bold', textAlign: 'right' }}>Forgot password?</Typography>
        </a>
         {submittable &&
           <input
             type='submit'
             style={{visibility: 'hidden'}}
           />
         }
         <CTAButton
           onClick={login}
           disabled={!submittable || isLoggingIn}
           type='submit'
           style={{
             marginTop: '40px'
           }}
           value={isLoggingIn ? `Signing in...` : 'Sign in'}
         />
      </form>
    </div>
  );
}

export default Login;
