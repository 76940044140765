import React from 'react';
import { TextField } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';


const partyOptions = [
  { label: 'Republican', value: 'R' },
  { label: 'Democrat', value: 'D' },
  { label: 'Nonpartisan', value: 'N' },
  { label: 'Libertarian', value: 'L' },
  { label: 'Green Party', value: 'G' },
]

const PartySelect = ({ value, onChange, style, className }) => {

	return (
		<TextField select name='party' onChange={e => {
			onChange(e.target.value)
		}} style={style} className={className} value={value}>
			{partyOptions.map((opt) => <MenuItem key={opt.value} value={opt.value}>{opt.label}</MenuItem>)}
		</TextField>
	);

}

export { PartySelect };