import React, { useState, useEffect } from 'react';
import {
  useFeathers,
} from '../../app/util';
import Button from '@mui/material/Button';

import {
  EditMeasure,
  TaskPage,
  TimeTracker,
} from '../../components';
import styled from 'styled-components';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const mapDraftToServerInputs = (draft) => {
  const {
    title,
    descriptionShort,
    ballotText,
    actNumbers,
    whatItMeans,
    commentaryAvailable,
    supportersSay,
    opponentsSay,
    sources,
  } = draft;
  return {
    title,
    descriptionShort,
    ballotText,
    actNumbers,
    whatItMeans,
    commentaryAvailable,
    supportersSay,
    opponentsSay,
    sources: (sources || []).map(({ _id, ...rest }) => rest),
  }
}

const ResearchMeasureTask = () => {
  const feathers = useFeathers();
  const user = useSelector(state => state.user);
  const [task, setTask] = useState(null);
  const [saving, setSaving] = useState(false); 
  const [taskPageSaving, setTaskPageSaving] = useState(false);
  const [completionData, setCompletionData] = useState(null)

  console.log(completionData)

  const [publishMeasure, setPublishMeasure] = useState(null)

  const [showingPublishDialog, setShowingPublishDialog] = useState(false);
  const userIsPublisher = (user?.permissions || []).some((p) => ['publish-measure', 'editor', 'super-admin'].includes(p))
  const publishPerms = userIsPublisher;

  const [measure, setMeasure] = useState(null);
  const [measureEdit, setMeasureEdit] = useState(null);
  
  const onCheckoutTask = async (task, loadNextTask) => {
    setMeasure(null);
    setMeasureEdit(null);
    setTask(task);
    let measure;
    try {
      measure = await feathers.getService('measures').get(task.details.measure);
    } catch(err) {
      if(err.name === 'NotFound') {
        // bad measure, we need to cancel this task
        console.log('measure not found')
        await feathers.getService('research-tasks').patch(task._id, {
          $cancelTask: true
        });
        setTask(null);
        loadNextTask()
        return
      } else {
        throw err;
      }
    }
    setMeasure(measure);
    const { updatedAt: holdingDataUpdatedAt, ...holdingData } = task?.holdingData || {};
    // seed inputs with most recently updated data from holdingData or measure record
    const { ...rest } = (
      ((holdingData && holdingDataUpdatedAt > measure?.updatedAt)
        ? holdingData
        : measure) 
      || {}
    )

    const mappedMeasure = {
      ...rest,
    };

    setMeasureEdit(mappedMeasure);
  }

  useEffect(() => {
    if (measure && measureEdit) setCompletionData(mapDraftToServerInputs(measureEdit))
  }, [measure, measureEdit, task])

  useEffect(() => {
    if(measure) setPublishMeasure(measure._id)
  }, [measure, task])

  const handlePublish = async (loadNextTask) => {
    if(saving) return;

    setSaving(true)
    try {
      await feathers.getService('measures').patch(publishMeasure, {
        coverageStatus: 'coverage'
      });
      setShowingPublishDialog(false);
      loadNextTask();
    } catch (e) {
      console.log('Error', e)
    } finally {
      setSaving(false)
      setShowingPublishDialog(false);
      loadNextTask()
    }
  };

  const dialogText = {
    title: 'Publish referendum?',
    body: `Do you want to publish this referendum's summary profile? If you
    click "Publish", it will be immediately available to the
    public. If you click "Not yet", it will be sent to the
    queue for review by another reviewer.`,
    cancel: 'Not yet',
    confirm: 'Publish'
  }

  return (
    <TaskPage
      task={task}
      taskType='explain-measure'
      onCheckoutTask={onCheckoutTask}
      completionData={completionData}
      holdingData={measureEdit}
      onChangeSaving={(saving) => setTaskPageSaving(saving)}
      disableTimeTracker
      onCompleteTaskBeforeLoadNext={publishPerms ? () => setShowingPublishDialog(true) : null}
      onCompleteTaskDialog={
        (loadNextTask) => (
          <Dialog
            open={showingPublishDialog}
            onClose={() => {
              loadNextTask()
              setShowingPublishDialog(false)
            }}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-body'
          >
            <DialogTitle id='alert-dialog-title'>
              {dialogText?.title}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id='alert-dialog-body'>
                {dialogText?.body}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => {
                loadNextTask()
                setShowingPublishDialog(false)
              }}
                color='primary'>
                {dialogText?.cancel}
              </Button>
              <Button onClick={() => handlePublish(loadNextTask)} color='primary' autoFocus>
                {dialogText?.confirm}
              </Button>
            </DialogActions>
          </Dialog>
        )
      }
      sidePaneConfig={([
        {
          title: 'Priority',
          content: (
            <>
              <TimeTracker
                seedTask={task}
                isReferendum
              />
              {
                publishPerms &&
                <Typography variant='body2' style={{ marginTop: '16px' }}>
                  You have permissions to publish this referendum directly.
                </Typography>
              }
            </>
          ),
          initiallyExpanded: true,
        },
      ])}
    >
      <WrapperInner>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '8px', marginTop: '36px'}}>
          <Typography variant='h2' style={{  }}>{measure?.title}</Typography>
          <Typography variant='body1' style={{ marginBottom: '0px' }}>For {(measure?.originalDistrict || measure?.district)?.longName} in {measure?.election?.name}</Typography>
          <Link to={`/elections/${measure?.election?.key || measure?.election}/measures/${measure?._id}`} target='_blank'>
            <div style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}>
              <Typography variant='body2'>Full page</Typography>
              <OpenInNewIcon style={{ width: '15px', height: '15px' }}/>
            </div>
          </Link>
        </div>
        <EditMeasure
          value={measureEdit}
          onChange={(e) => {
            const { name, value } = e.target;
            setMeasureEdit(existingDraft => {
              return {
                ...existingDraft,
                [name]: value
              }
            })
          }}
          disabled={saving || taskPageSaving} // being set in child
        />
      </WrapperInner>
    </TaskPage>
  );
};

const WrapperInner = styled.div`
  padding: 0 24px;
`
export default ResearchMeasureTask;
