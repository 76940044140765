import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import {
  TaskPage,
} from "../../components";
import styled from "styled-components";
import { ScoutRacesForArea } from "../../components/ScoutRacesForArea";
import { useLocation } from 'react-router-dom';


const IdentifyRaces = () => {
  const [task, setTask] = useState(null);
  const [error, setError] = useState(false);
  const [completionData, setCompletionData] = useState({})
  const [saving, setSaving] = useState(false);

  // get the pathname of the page
  const { pathname } = useLocation();
  const taskType = pathname.includes('identify-races') ? 'identify-races' : 'identify-measures';

  // an array of types ['races' and 'measures']. If none are selected, disable the ability to save
  const [contentTypesActive, setContentTypesActive] = useState([])

  const completeButtonDisabled = contentTypesActive.length === 0 || (
    taskType === 'identify-measures' && !contentTypesActive.includes('measures')
  );
  let completeButtonDisabledMessage;
  if(contentTypesActive.length === 0) {
    completeButtonDisabledMessage = `You must select at least one content type to finish saving.`
  } else if(taskType === 'identify-measures' && !contentTypesActive.includes('measures')) {
    completeButtonDisabledMessage = `You must select 'measures' to finish saving.`
  }

  const completionDataFull = {
    ...completionData,
  }
  if(taskType === 'identify-races' && contentTypesActive.includes('measures')) {
    completionDataFull.$scoutWithContentType = 'measures';
  }

  console.log(completionDataFull)


  return (
    <Wrapper>
      <TaskPage
        taskType={taskType}
        completeButtonDisabled={completeButtonDisabled}
        allowMultipleCheckedOutTasks
        errorMessage={error?.message}
        holdingData={completionDataFull}
        onCheckoutTask={(task) => setTask(task)}
        childError={error}
        completionData={completionDataFull}
        onChangeSaving={(saving) => setSaving(saving)}
        noMoreTasksMessage="There are currently no districts to identify ballot items for. Try back later."
        task={task}
      >
        {
          !task &&
          <Typography variant='body1'>Click a task on the left side pane to get started.</Typography>
        }
        {
          task &&
          <ScoutRacesForArea
            style={{ marginTop: '24px' }}
            onChangeContentTypes={(contentTypes) => setContentTypesActive(contentTypes)}
            primaryDistrictId={task.details.district}
            election={task.details.election}
            requireContentType={taskType === 'identify-races' ? 'races' : null}      // this is a slight bug... if we come in through the
                    // identify-races route, that task will always be completed, even if we switch to just scouting measures
            onChangeCompletionData={(data) => setCompletionData(data)}
            disabled={saving}
            onError={(error) => setError(error)}
          />
        }
      </TaskPage>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%;
`;



export default IdentifyRaces;
