import React from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';

import makeStyles from '@mui/styles/makeStyles';
import { Typography } from '@mui/material';

const useStyles = makeStyles({
  root: {
    backgroundColor: 'transparent',
    '& .MuiSelect-select': {
      backgroundColor: 'transparent',
      padding: '4px 30px 2px 8px',
      fontSize: '12px'
    },
  }, 
  outlined: {
    padding: '4px 30px 2px 8px',
  },
  notchedOutline: {
    borderColor: '#AAAAAA',
    borderRadius: '18px'
  }
});

const IssueDropdown = ({
  value,
  options,
  onChange,
  placeholder
}) => {
  const handleIssueChange = (event) => {
    event.stopPropagation();
    onChange(event.target.value);
  };
  const classes = useStyles();

  const renderValue = (value) => {
    if(value) { 
      const titleForIssue = options.find((option) => option.key === value)?.title;
      // render like normal 
      return <Typography variant='body1' style={{ fontSize: '12px', fontWeight: 'bold', }}>{titleForIssue || value}</Typography>

    } else {
      // render placeholder
      return <Typography variant='body1' style={{ fontSize: '12px' }}>{placeholder}</Typography>
    }
  }

  return (
    <Select displayEmpty renderValue={renderValue} className={classes.root} value={value ?? ''} size='small' onChange={handleIssueChange} variant='outlined' input={
      <OutlinedInput classes={{ notchedOutline: classes.notchedOutline }} />
    }>
      {options.map(({ key, title, subtopics }) => (
        <MenuItem style={{whiteSpace: 'normal', display: 'flex', flexDirection: 'column', alignItems: 'stretch' }} key={key} value={key}>
          <Typography variant='body1' style={ value !== key ? { fontSize: '14px', fontWeight: 'bold', } : { fontSize: '12px' }}>{title}</Typography>
          {value !== key && <Typography variant='body2' style={{ fontSize: '12px', color: '#666666'}}>{subtopics}</Typography>}
        </MenuItem>
      ))}
    </Select>
  );
}

export { IssueDropdown };
