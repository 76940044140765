import React, { useMemo, useEffect, useState } from 'react';
import styled from 'styled-components';
import { StatusTile , LoadingSpinner, Tab, TabControl, DownloadProcessBetaSpreadsheet } from '../../components'
import Typography from "@mui/material/Typography";
import { useFeathers } from '../../app/util';

const ElectionHealth = ({
  election,
  showDetails = true,
  onClick = () => {},
  inRefreshMode,
}) => {
  const feathers = useFeathers();
  const [ tier2Geoboundaries, setTier2Geoboundaries ] = useState(null);
  const [ tier2GeoboundariesStatus, setTier2GeoboundariesStatus ] = useState('');
  const [ childrenStructureValidation, setChildrenStructureValidation ] = useState(null);
  const [ childrenStructureValidationStatus, setChildrenStructureValidationStatus ] = useState('');
  const [ presentDistrictGeoboundaries, setPresentDistrictGeoboundaries ] = useState(null);
  const [ presentDistrictGeoboundariesStatus, setPresentDistrictGeoboundariesStatus ] = useState('');
  const [ activeTab, setActiveTab ] = useState(0)
  const [ electionFull, setElectionFull ] = useState(null)

  useMemo(async () => {
    try {
      if (!feathers) return
      const { data } = await feathers.getService('election-health').find({
        query: {
          electionKey: election.key,
          $tier2Geoboundaries: true,
        }
      })
      setTier2Geoboundaries(data)
      if (data.length) {
        setTier2GeoboundariesStatus({ failure: `Some Tier 2 Districts for ${election.name} are not matched with their geographical boundaries.`})
      } else {
        const tier2Response = await feathers.getService('districts').find({ query: {
          parentId: election.state._id
          }
        })
        setTier2GeoboundariesStatus({ success: `All ${ tier2Response.total } Tier 2 Districts for ${election.name} are matched with their geographical boundaries.  Tier 2 Districts are districts that are one level below state, such as counties and cities.`})
      }
    } catch (err) {
      setTier2GeoboundariesStatus({error: `A problem with this status check has occured: ${err.message}`})
    }
  }, [feathers])

  useEffect(() => { 
    if(feathers && election) {
      loadElectionFull()
    }
  }, [feathers, election])

  const loadElectionFull = async () => {
    try {
      const electionFull = await feathers.getService('elections').get(election.key)
      setElectionFull(electionFull)
    } catch (err) {
      console.log('Error loading election full')
    }
  }


  useMemo(async () => {
    try {
      if (!feathers) return
      const { data } = await feathers.getService('election-health').find({
        query: {
          electionKey: election.key,
          $childrenStructureValidation: true,
        }
      })

      setChildrenStructureValidation(data)
      if (data.length) {
        setChildrenStructureValidationStatus({ failure: `Some districts detailed in ${election.name} have not had their subdistricts researched and confirmed.`})
      } else {
        const coveredResponse = await feathers.getService('election-health').find({
          query: {
            electionKey: election.key,
            $childrenStructureValidation: true,
            $covered: true
          }
        })
        setChildrenStructureValidationStatus({ success: `All ${coveredResponse.total} districts detailed in ${election.name} have had their subdistricts researched and confirmed.`})
      }
    } catch (err) {
      setChildrenStructureValidationStatus({error: `A problem with this status check has occured: ${err.message}`})
    }
  }, [feathers])

  useMemo(async() => {
    if (!feathers) return
    try {
      const { data } = await feathers.getService('election-health').find({
        query: {
          electionKey: election.key,
          $presentDistrictGeoboundaries: true,
        }
      })

      const dataGrouped = data.reduce((acc, district) => {
        // group by parentId / type combo
        const key = `${district.parentId}-${district.type}`
        if (!acc[key]) acc[key] = { groupTitle: `${district.parent} - ${district.type}`, districts: [], population: 0 }
        acc[key].districts.push(district);
        acc[key].population += district.population;
        return acc;
      }, {})

      // get values and sort by population
      const dataArr = Object.values(dataGrouped).sort((a, b) => b.population - a.population);

      setPresentDistrictGeoboundaries(dataArr)

      if (dataArr.length) {
        setPresentDistrictGeoboundariesStatus({ failure: `Some districts that will be present in ${election.name} are not matched with their geographical boundaries.`})
      } else {
        const coveredResponse = await feathers.getService('election-health').find({
          query: {
            electionKey: election.key,
            $presentDistrictGeoboundaries: true,
            $covered: true
          }
        })
        setPresentDistrictGeoboundariesStatus({ success: `All ${coveredResponse.total} districts that are expected to be present in ${election.name} are matched with their geographical boundaries.`})
      }
    } catch (err) {
      setPresentDistrictGeoboundariesStatus({error: `A problem with this status check has occured: ${err.message}`})
    }
  }, [feathers, election])

  useEffect(() => {
    if (inRefreshMode) setPresentDistrictGeoboundariesStatus('')
  }, [inRefreshMode])


  return (
    <>
      <StatusTile
        statusOptions={[
          { primaryLabel: 'Districts Process Alpha', label: 'Tier 2 districts are created with geo boundaries', status: tier2GeoboundariesStatus },
          { primaryLabel: 'Districts Process Beta', label: 'Tier 3 districts have been researched and created', status: childrenStructureValidationStatus },
          { primaryLabel: 'Districts Process Gamma', label: 'Tier 3 districts have geo boundaries', status: presentDistrictGeoboundariesStatus },
        ]}
        displayDetailLabel={showDetails}
        onClick={showDetails ? null : onClick }
        style={{width: showDetails ? '70%': '100%'}}
      />
       {
        showDetails &&
        <>
          <div style={{ height: '48px' }}></div>  
          <TabWrapper>
            <TabControl
                onChange={(i) => setActiveTab(i)}
                activeTab={activeTab}
                align='center'
            >
              <Tab>GEOBOUNDARIES FOR TIER 2 DISTRICTS</Tab>
              <Tab>SUBDISTRICTS RESEARCHED</Tab>
              <Tab>GEOBOUNDARIES FOR ALL EXPECTED DISTRICTS{presentDistrictGeoboundaries ? ` (${presentDistrictGeoboundaries.length})` : ``}</Tab>
            </TabControl>
          </TabWrapper>
          <div style={{ backgroundColor: '#FFFFFF', padding: '24px', marginTop: '-12px', borderRadius: '8px', border: 'solid 1px #eee',  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}>
            {
              activeTab === 0 &&
              <DetailedStatus status={tier2GeoboundariesStatus} districts={tier2Geoboundaries}/>

            }
            {
              activeTab === 1 &&
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch'}}>
                {
                  childrenStructureValidationStatus?.success && 
                  <>
                    <Typography>{childrenStructureValidationStatus?.success}</Typography>
                  </>
                }
                {
                  childrenStructureValidationStatus?.error && 
                  <>
                    <Typography>{childrenStructureValidationStatus?.error}</Typography>
                  </>
                }
                {
                  childrenStructureValidationStatus?.failure && 
                  <>
                    <Typography style={{ marginBottom: '24px' }}>{childrenStructureValidationStatus?.failure}</Typography>
                    <div style={{ alignSelf: 'flex-end'}}>
                      <DownloadProcessBetaSpreadsheet election={election} tier2Districts={childrenStructureValidation}/>
                    </div>
                    {childrenStructureValidation.map(d => {
                      return (
                        <div  key={d._id} style={{ padding: '12px 0' }}>
                          <Typography variant='body1'  style={{ fontWeight: 'bold', fontSize: '16px' }}>{d.longName}</Typography>
                          <Typography variant='body2' style={{ opacity: 0.7, fontSize: '14px' }}><b>Population:</b> {(d.population || 0).toLocaleString()}</Typography>
                        </div>
                      )
                    })}
                  </>
                }
                {
                  !presentDistrictGeoboundariesStatus && <LoadingSpinner />
                }
              </div>
            }
            {
              activeTab === 2 &&
              <div>
                {
                  presentDistrictGeoboundariesStatus?.success && 
                  <>
                    <Typography>{presentDistrictGeoboundariesStatus?.success}</Typography>
                  </>
                }
                {
                  presentDistrictGeoboundariesStatus?.error && 
                  <>
                    <Typography>{presentDistrictGeoboundariesStatus?.error}</Typography>
                  </>
                }
                {
                  presentDistrictGeoboundariesStatus?.failure && 
                  <>
                    <Typography>{presentDistrictGeoboundariesStatus?.failure}</Typography>
                    {presentDistrictGeoboundaries.map(g => {
                      return (
                        <div style={{ padding: '16px 0' }}>
                          <Typography key={g.groupTitle} variant='body1' style={{ fontSize: '18px', fontWeight: 'bold' }}>{g.groupTitle}</Typography>
                          <div style={{ display: 'grid', gridTemplateColumns: '200px 1fr' }}>
                            <Typography variant='body2' style={{ opacity: 0.8 }}><b>Population:</b> {g.population ? formatPopulationWithCommas(g.population) : 'None'}</Typography>
                            <Typography variant='body2' style={{ opacity: 0.8 }}><b>Districts:</b> {g.districts.map(d => d.longName).join('; ')}</Typography>
                          </div>
                        </div>
                      )
                    })}
                  </>
                }
                {
                  !presentDistrictGeoboundariesStatus && <LoadingSpinner />
                }
              </div>
            }
          </div>
        </>
      }
    </>

  )
}

const formatPopulationWithCommas = (population) => {
  return population.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const DetailedStatus = ({status, districts}) => {
  if (status.success) {
    return <Typography>{status.success}</Typography>
  } else if (status.error) {
    return <Typography>{status.error}</Typography>
  } else if (status.failure) {
    return (
      <>
        <Typography>{status.failure}.</Typography>
        <DistrictList districts={districts}></DistrictList>
      </>
    )
  } else {
    return <LoadingSpinner />
  }
}

const DistrictList = ({ districts }) => {
  return (
    <DistrictListWrapper>
      <Typography variant="h4">Districts that Require Further Attention: </Typography>
      <div>
      { (districts || []).map(district => <Typography key={district._id} variant='body1'>{district.longName}</Typography>) }
      </div>
    </DistrictListWrapper>
  )
}

const DistrictListWrapper = styled.div`
  margin-top: 15px;
  max-height: 55vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 15px;
`

const TabWrapper = styled.div`
  width: 100%;
  margin-top: 12px;
  margin-bottom: 10px;
`;


export { ElectionHealth };
