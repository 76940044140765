import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Redirect, Link, useParams } from 'react-router-dom';
import { useFormInputs, useFeathers } from '../../app/util';
import { useSelector } from 'react-redux';
import {
  CTAButton,
  SearchableSelectInput,
  CloseButton,
  HintTile,
  OfficialsSelectInput
} from '../../components';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { CircularProgress, Typography } from '@mui/material';

const partiesAll = {
  'D': 'Democrat',
  'R': 'Republican',
  'L': 'Libertarian',
  'G': 'Green',
  'I': 'Independent',
  'N': 'Nonpartisan',
  'C': 'Constitution',
  'W': 'We The People',
  'P': 'Populist',
  'NL': 'No Labels',
  'S': 'Socialist Party',
  'F': 'Forward',
  'ASP': 'American Solidarity Party',
  'CP': 'Conservative Party',
  'WFP': 'Working Families',
  'PF': 'People First',
  'LR': 'LaRouche Party'
}

const partyOptions = Object.keys(partiesAll).map(key => ({
  value: key,
  label: partiesAll[key]
}))

const CreateCandidate = ({
  onClose,
  onSuccess
}) => {
  const feathers = useFeathers()
  const raceId = useParams().id;
  const election = useParams().key;
  const electionData = useSelector(state => state?.elections?.byKey[election])
  const isPartisan = electionData?.partisan;

  // Official full is only for national races
  const [ officialFull, setOfficialFull ] = useState(null)
  const [ officialFullLoading, setOfficialFullLoading ] = useState(false);
  const [ nationalRaces, setNationalRaces ] = useState(null);
  const [ nationalCandidate, setNationalCandidate ] = useState(null);

  const [ formInputs, setFormInputs ] = useState({
    race: null,
    official: null,
    ...(!isPartisan ? { party: null } : {})
  })
  const submittable = formInputs.race && formInputs.official && (nationalCandidate || (!isPartisan ? formInputs?.party : true)) && !officialFullLoading;

  useEffect(() => {
    if(feathers && raceId && !formInputs.race) loadInitialRace()
  }, [ feathers, raceId, formInputs ]);
  const loadInitialRace = async () => {
    const race = await feathers.getService('races').get(raceId);
    const raceAsOption = {
      text: race.officeName + (race?.party ? ` (${race?.party})` : ''),
      subText: race?.originalDistrict?.longName || race.district?.longName,
      isNationalRace: race.office?.district?.type === 'country',
      _id: race._id
    }
    setFormInputs({ race: raceAsOption })
  }

  const onChange = e => {
    const { name, value, isNew } = e.target;
    const update = {};
    update[name] = value;
    let newInputs = {
      ...formInputs,
      ...update
    };
    // console.log('e.target', e.target)
    // if(name === 'official' && isNew) {
    //   // We have a new official to reference
    //   const updateNew = {};
    //   updateNew[name] = {
    //     new: true,
    //     name: `New candidate "${value}"`,
    //     creationName: value
    //   };
    //   newInputs = {
    //     ...formInputs,
    //     ...updateNew
    //   }
    // } else 
    
    if (name === 'official' && !isNew && newInputs?.official?.key) {
      loadFullOfficialData(newInputs.official.key);
    }

    // See if we need to reset any formInputs
    if(!newInputs.official) {
      setError(null);
      setFormInputs(newInputs)
      setOfficialFull(null);
      setNationalRaces(null);
      setNationalCandidate(null);
    } else {
      setFormInputs(newInputs)
    }
  }

  console.log(formInputs)

  /* manage searching options for selection, based on search text */
  const [ optionsForField, setOptionsForField ] = useState({});
  const [ loadingForField, setLoadingForField ] = useState({});
  const searchForField = async (field, searchText) => {
    if(!feathers) return;

    const loadingUpdate = {};
    loadingUpdate[field] = true;
    setLoadingForField({ ...loadingForField, ...loadingUpdate });

    let options = [];
    if(field === 'official') {
      const officials = await feathers.getService('officials').find({ query:
        searchText ? {searchTerm: { $search: searchText }} : {}
      })
      options = officials.data.map(off => ({
        key: off.key,
        photo: off.recentCandidate?.stagingDraft?.photoPathFace || off.recentCandidate?.photoPathFace,
        name: off.recentCandidate?.stagingDraft?.name || off.recentCandidate?.name,
      }));
    }
    const updateForOptions = {};
    updateForOptions[field] = options;
    setOptionsForField({ ...optionsForField, ...updateForOptions })

    const loadingUpdate2 = {};
    loadingUpdate2[field] = false;
    setLoadingForField({ ...loadingForField, ...loadingUpdate2 });
  }

  const loadFullOfficialData = async (officialKey) => {
    setOfficialFullLoading(true);
    if(!formInputs?.race?.isNationalRace) {
      setOfficialFullLoading(false);
      return;
    }


    try {
      const fullOfficial = await feathers.getService('officials').get(officialKey);
      setOfficialFull(fullOfficial);

      // now, check if this official has any current national offices they're running for
      const potentialNationalOffices = (fullOfficial.candidates || []).filter(c => c.races?.length > 0);
      if(potentialNationalOffices.length === 0) {
        setOfficialFullLoading(false);
        return;
      }

      console.log('potentialNationalOffices', potentialNationalOffices)
      if(potentialNationalOffices.length > 1) {
        throw new Error(`This official is running for more than one national office. Dont know how to handle this case yet.`)
      }

      const nationalRaces = potentialNationalOffices[0].races;
      setNationalCandidate(potentialNationalOffices[0]);
      setNationalRaces(nationalRaces);
      setFormInputs((formInputs) => ({
        ...formInputs,
        party: potentialNationalOffices[0].party
      }))
      setOfficialFullLoading(false);
    } catch(error) {
      setError(error)
    }
  }

  const [ submitting, setSubmitting ] = useState(false);
  const [ successfulCreation, setSuccessfulCreation ] = useState(false);
  const [ error, setError ] = useState(null);
  const submit = async () => {
    if(submitting || !submittable) return;
    // Create the candidate
    setSubmitting(true);

    // Create a the new candidate
    try {
      if(nationalRaces) {
        if(!nationalCandidate) throw new Error('No national candidate selected')

        // in this case, just update the existing candidate with the new list of races 
        const newRaceIds = [ formInputs.race._id, ...nationalRaces.map(r => r._id) ];

        const updatedCandidate = await feathers.getService('candidates').patch(nationalCandidate._id, {
          races: newRaceIds
        })
        console.log('updated candidate', updatedCandidate)
      } else {

        const data = formInputs.official.isNew
          ?   // If we have a new official, pass in their name and use the $create flag
            {
              official: { $create: true },
              name: formInputs.official.name
            }
          :   // If this is an existing official, just provide their key
            {
              official: formInputs.official.key
            };

        const creation = await feathers.getService('candidates').create({
          ...data,
          ...(formInputs.race.isNationalRace ? { races: [formInputs.race._id] } : { race: formInputs.race._id }),
          ...(!isPartisan ? { party: formInputs.party } : {})
        })
      }
      
      if(onSuccess) {
        onSuccess()
      } else {
        onClose()
      }
    } catch (error) {
      console.log(error)
      setError(error.toJSON())
      setSubmitting(false)
    }
  }

  return (
    <Wrapper>
      <CloseButton
        onClick={onClose}
        style={{
          position: 'absolute',
          height: '36px',
          right: '-20px',
          top: '-20px'
        }}
      />
      <Top style={officialFullLoading ? {pointerEvents: "none", opacity: 0.8 } : {}}>
        <h1>New Candidate</h1>
        <Row>
          <div className='label'>Race</div>
          <SearchableSelectInput
            name='race'
            onChangeSearchText={(searchText) => searchForField('race', searchText)}
            value={formInputs.race}
            onChange={onChange}
            optionsLoading={loadingForField.race}
            options={optionsForField?.race || []}
            style={{ flexGrow: 1 }}
            disabled
          />
        </Row>
        {
          formInputs.race &&
          <Row>
            <div className='label'>Candidate</div>
            <OfficialsSelectInput
              onChange={onChange}
              value={formInputs.official}
              style={{ flexGrow: 1 }}
            />
          </Row>
        }
        {
          officialFullLoading &&
          <Row>
            <CircularProgress />
            <div className='label'>Loading...</div>
          </Row>
        }
        {
          nationalRaces &&
          <Row>
            <Typography variant='body1'>This candidate is also running for {nationalRaces[0].office} the following elections: {(nationalRaces || []).map(r => r.election).join(', ')}.</Typography>
          </Row>
        }
        {
          formInputs.race && formInputs.official && !isPartisan && !nationalRaces &&
          <Row>
            <div className='label'>Party</div>
            <TextField select name='party' onChange={onChange} style={{ flexGrow: 1 }} value={formInputs?.party}>
              {partyOptions.map((opt) => <MenuItem key={opt.value} value={opt.value}>{opt.label}</MenuItem>)}
            </TextField>
          </Row>
        }
        {
          error &&
          <HintTile style={{ marginBottom: '20px' }}>
            {
              error.code === 409
              ?
                <ErrorText><b>Uh oh! </b>{formInputs?.official.name} is already running for <Link to={`/elections/${error.data.election?.[0]}/races/${error.data.race}`}>another race</Link> this election. If this is incorrect, please delete their record.</ErrorText>
              :
                <ErrorText><b>Uh oh! </b>Error creating this candidate: {error.message}</ErrorText>
            }
          </HintTile>
        }
        <CTAButton
          value={nationalRaces ? 'Add to race' : 'Create'}
          onClick={submit}
          disabled={!submittable || submitting}
        />
      </Top>
    </Wrapper>
  );

};

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 500px;
  min-height: 200px;
`
const Top = styled.div`
  display: flex;
  flex-direction: column;

  h1 {
    font-size: 28px;
    margin: 0 0 26px;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-bottom: 28px;

  div.label {
    font-size: 18px;
    margin: 0 14px 0 0;
    opacity: 0.7;
    width: 100px;
    ${props => props.theme.font.normal}
    color: ${props => props.theme.colors.darkPurple};
  }
`;

const ErrorText = styled.div`
  ${props => props.theme.font.normal}
  color: ${props => props.theme.colors.darkPurple};
  b {
    ${props => props.theme.font.bold}
  }
`;

export default CreateCandidate;
