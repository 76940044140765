import React, { useState } from 'react';
import { useFeathers } from '../../app/util';
import { ReactComponent as GreenCheckSvg } from '../../images/green-check.svg';
import {
  Button,
  Checkbox,
  Typography
} from '@mui/material';
import styled from 'styled-components';
import SelectPermissions from './SelectPermissions';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import EditIcon from '@mui/icons-material/Edit';
import SelectResearcherLevel from '../SelectResearcherLevel';

const permissionsAddOn = [
  {
    permission: 'research-measure',
    title: 'Research referendums'
  },
  {
    permission: 'publish-measure',
    title: 'Publish referendums'
  },
  {
    permission: 'dms-shapefile',
    title: 'Access shapefiles'
  },
  {
    permission: 'resolve-error',
    title: 'Resolve errors'
  }
]


const EditPermissionsModal = ( { user, refreshUsers }) => {
  const feathers = useFeathers()
  const [ userPermission, setUserPermission ] = useState(user.permissions[0])
  const [ researcherLevel, setResearcherLevel ] = useState(user?.researcherLevel);
  const [ publisherLevel, setPublisherLevel ] = useState(user?.publisherLevel);
  const [ saved, setSaved ] = useState(false)
  const [ error, setError ] = useState('')

  const [ firstName, setFirstName ] = useState(user.firstName)
  const [ lastName, setLastName ] = useState(user.lastName)
  const [ editFirstName, setEditFirstName ] = useState(false)
  const [ editLastName, setEditLastName ] = useState(false)

  const [ additionalPermissions, setAdditionalPermissions ] = useState(user.permissions.filter((p) => permissionsAddOn.map(({ permission }) => permission).includes(p)))

  const editPermissions = async () => {
    try {
      await feathers.getService('users').patch(user._id, {
        permissions: [ userPermission, ...(additionalPermissions || []) ],
        firstName: firstName,
        lastName: lastName,
        ...(researcherLevel ? { researcherLevel: parseInt(researcherLevel) } : {}),
        ...(publisherLevel ? { publisherLevel: parseInt(publisherLevel) } : {}),
      })
      setSaved(true)
      refreshUsers()
    } catch (err) {
      setError(err.message)
    }
  }

  const handlePermissionsChange = (event) => {
    const {
      target: { value },
    } = event;
    setUserPermission(
      value
    );
  };

  return (<>
    <EditPermissionsModalWrapper>
      <Typography variant="h1">
        Manage User
      </Typography>
      <UserDetailsWrapper>
        <Typography style={{ paddingTop: '10px' }}>
          First Name
        </Typography>
        <EditRowWrapper>
          {
            editFirstName
            ?
              <TextField
                variant="outlined"
                autoFocus
                onBlur={() => (setEditFirstName(false))}
                value={firstName}
                onChange={(e) => (setFirstName(e.target.value))}
              />
            :
              <>
                <Typography style={{paddingTop: '10px'}}>
                  {firstName || '---'}
                </Typography>
                <IconButton onClick={() => (setEditFirstName(true))} size="large"><EditIcon /></IconButton>
              </>
          }
        </EditRowWrapper>
        <Typography style={{paddingTop: '10px'}}>
          Last
        </Typography>
        <EditRowWrapper>
          {
            editLastName
            ?
              <TextField
                variant="outlined"
                autoFocus
                onBlur={() => (setEditLastName(false))}
                value={lastName}
                onChange={(e) => (setLastName(e.target.value))}
              />
            :
              <>
                <Typography style={{paddingTop: '10px'}}>
                  {lastName || '---'}
                </Typography>
                <IconButton onClick={() => (setEditLastName(true))} size="large">
                  <EditIcon />
                </IconButton>
              </>
          }
        </EditRowWrapper>
        <Typography>Email</Typography>
        <Typography>{user.email}</Typography>
        <Typography>Access Level</Typography>
        <SelectPermissions
          handlePermissionsChange={handlePermissionsChange}
          userPermission={userPermission}
        />
        {
          userPermission !== 'auditor' &&
          <>
            <Typography>Researcher Level</Typography>
            <SelectResearcherLevel
              onChange={(e) => {
                setResearcherLevel(e.target.value)
              }}
              value={researcherLevel}
            />
            <Typography>Publisher Level</Typography>
            <SelectResearcherLevel
              onChange={(e) => {
                setPublisherLevel(e.target.value)
              }}
              value={publisherLevel}
            />
            <Typography>Additional Permissions</Typography>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
              {
                permissionsAddOn.map(({ permission, title }) => (
                  <div key={permission} style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
                    <Checkbox 
                      checked={additionalPermissions.includes(permission)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setAdditionalPermissions([...additionalPermissions, permission])
                        } else {
                          setAdditionalPermissions(additionalPermissions.filter((p) => p !== permission))
                        }
                      }}
                    />
                    <Typography variant='body1'>{title}</Typography>
                  </div>
                ))
              }
            </div>
          </>
        }
      </UserDetailsWrapper>
      { !saved
        ?
          <Button
            onClick={editPermissions}
            variant='contained'
            color={'primary'}
          >
            Save Changes
          </Button>
        :
          <SavedWrapper>
            <GreenCheck/>
            <Typography>Changes saved for { user.email }.</Typography>
          </SavedWrapper>
      }
      {
        error && <Typography style={{ color: 'red' }}>{error}</Typography>
      }
    </EditPermissionsModalWrapper>
  </>);
}

const EditPermissionsModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 60px;
  justify-content: space-between;
  gap: 30px;
  min-width: 200px;
`

const EditRowWrapper = styled.div`
  display: grid;
  grid-template-columns: 4fr 1fr;
`

const GreenCheck = styled(GreenCheckSvg)`
  width: 25px;
  height: auto;
`

const SavedWrapper = styled.div`
  display: flex;
  gap: 10%;
  justify-content: center;
  align-items: center;
`

const UserDetailsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-column-gap: 30px;
  grid-row-gap: 10px;
`

  export default EditPermissionsModal;