import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Link } from 'react-router-dom';
import { isPermitted, useFeathers } from "../../app/util";
import moment from "moment";
import Typography from "@mui/material/Typography";
import styled from "styled-components";
import EditIcon from '@mui/icons-material/Edit';
import { useSelector } from "react-redux";

const MeasurePreviewTile = ({
  measure,
  onError,
}) => {
  const feathers = useFeathers();
  const user = useSelector((state) => state.user);
  const editorDeletePerms = isPermitted(user, ['editor', 'super-admin'])
  const recentDeletePerms = isPermitted(user, ['reviewer', 'publisher', 'editor', 'super-admin'])

  const [district, setDistrict] = useState(null);

  const loadDistrict = async () => {
    try {
      const district = measure.district._id
        ? measure.district
        : await feathers.getService("districts").get(measure.district);
      setDistrict(district);
    } catch (err) {
      console.log(`Error loading office and district`, err)
    }
  };

  useEffect(() => {
    if (!feathers || !measure) return;
    loadDistrict();
  }, [feathers, measure]);

  const appearsOnBallotAs = useMemo(() => {
    const entityMatchInputs = (measure?.entityMatchInputs || []).filter(i => !!i)
    if (!entityMatchInputs?.length) {
      return ''
    } else if (entityMatchInputs.length === 1) {
      return `${entityMatchInputs[0]}`
    } else {
      return entityMatchInputs.slice(0, -1).join(', ') + ' or ' + entityMatchInputs.slice(-1)
    }
  }, [measure])

  return (
    <Tile>
      <TileInner>
        <HeadingWrapper>
          <Typography variant="h3">{measure?.title}</Typography>
          <div style={{ flex: 1}} />
          <Link to={`/elections/${measure.election?.key ?? measure.election}/measures/${measure._id}`} target='_blank'>
            <EditIcon style={{ marginBottom: '-12px' }}/>
          </Link>
        </HeadingWrapper>
        <Typography variant="body1" style={{ color: '#453DB7'}}>{district?.longName}</Typography>
        {appearsOnBallotAs && <Typography variant="body2" style={{ marginTop: '4px'}}>Appears on ballot as: "{appearsOnBallotAs}"</Typography>}
        <div style={{ paddingLeft: '36px', paddingTop: '12px'}}>
          <Typography variant="body2">{(measure?.ballotText || '')?.length > 400 ? `${measure.ballotText.slice(0, 400)}...` : measure.ballotText}</Typography>
        </div>
        <LastUpdated>{`Last updated on ${moment(measure.updatedAt).format(
          `MMMM Do, h:mm:ss a`
        )} `}</LastUpdated>
      </TileInner>
    </Tile>
  );
};

const Tile = styled.div`
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 30px 24px 30px;

  border: 1px solid #dddddd;
  text-decoration: none;
`;

const TileInner = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;



const HeadingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 12px;
  margin-bottom: 8px;
`


const LastUpdated = styled.span`
  font-size: 12px;
  opacity: 0.6;
  text-align: right;
`;

export { MeasurePreviewTile };
