import React, { useEffect, useState } from 'react';
import { useFeathers } from '../../app/util';
import styled from 'styled-components';
import {
   Typography,
  } from '@mui/material';
  import {
    Modal,
    Tile
   } from '../../components'

import { useHistory } from "react-router-dom";

const AssignedTeamsModal = ( {
  onClose = () => {},
  user
}) => {
  const feathers = useFeathers()
  const history = useHistory()

  const [ teams, setTeams ] = useState([])

  const loadTeams = async () => {
    const { data } =  await feathers.getService('team-members').find({
      query: {
        user: user?._id
      }})
      setTeams(data)
    }

  useEffect(() => {
    if (feathers) loadTeams()
  }, [feathers])


  return (
    <Modal onClose={onClose}>
      <AssignedTeamsWrapper>
        <Typography variant='h3' align='center'>
          Assigned Elections for {user?.firstName + user?.lastName
          ? `${user.firstName || ''} ${user.lastName || ''}`
          : user.email}
        </Typography>
        {
          teams?.length
          ?
          teams.map(team => (
            <Tile
              key={team?._id}
              onClick={() => history.push(`/elections/${team?.election.electionKey}/team/${team?.user?._id}`)}
            >
              <Typography variant='body1'>
                {(team?.election || '').split('-').map(word => word[0].toUpperCase() + word.slice(1)).join(' ')}
              </Typography>
            </Tile>
          ))
          :
          <Typography variant='body1' align='center'>No elections assigned</Typography>
        }
      </AssignedTeamsWrapper>
    </Modal>
  )
}

const AssignedTeamsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 60px;
  justify-content: space-between;
  gap: 30px;
  width: 350px;

  .MuiInput-root {
    border-bottom: 1px solid #22034F !important;
    color: #313639
  }
`

export { AssignedTeamsModal };
