import React, { useState, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { useSelector } from 'react-redux';
import { useFeathers } from '../../app/util';
import {
  TextLink,
  ModalInner,
  IssuePrimer,
  issueHasPrimer
} from '../lower-order';
import Modal from '@mui/material/Modal';

const FullIssuePrimer = ({
  issueName,
  raceId,
  candidateId,
  school,
  style,
  className,
  triggerComponent = (<TextLink>Read more</TextLink>)
}) => {
  const feathers = useFeathers();
  const ballotId = useSelector(state => state.ballot?._id);
  const uuidLock = useSelector(state => state.uuidLock);
  const { accountCreated } = useSelector(state => state.user);
  const [ modalShowing, setModalShowing ] = useState(false);

  useEffect(() => {
    if(modalShowing && feathers) logDF(issueName)
  }, [ modalShowing, issueName, feathers ])


  const logDF = async (issueName) => {
    const issueNameKebab = issueName ? issueName.toLowerCase().replaceAll(' ', '-') : undefined;
    if(!issueNameKebab || !ballotId) return;
    if(!candidateId && !raceId) return;

    try {
      const dfRes = await feathers.getService('decision-factors').create({
        ballot: ballotId,
        ...(candidateId ? {candidate: candidateId} : { race: raceId }),
        type: 'read-primer',
        issue: issueNameKebab,
        uuidLock: accountCreated ? undefined : uuidLock
      })
      console.log(dfRes)
    } catch(err) {
      console.log('Error saving decision factors...', err)
    }
  }

  const triggerComponentFull = React.cloneElement(
    triggerComponent,
    {
      style,
      className,
      onClick: () => setModalShowing(true)
    }
  );

  const primerForIssue = issueHasPrimer(issueName);

  if(primerForIssue) {
    return (
      <>
        {triggerComponentFull}
        <Modal
          open={modalShowing}
          onClose={() => setModalShowing(false)}
        >
          <ModalInner
            onClose={() => setModalShowing(false)}
            style={{
              ...(isMobile ? {} : {maxWidth: '500px'}),
              width: 'calc(100% - 24px * 2)',
              padding: '24px'
            }}
            title={issueName}
          >
            <IssuePrimer
              mode='full'
              issueName={issueName}
              school={school}
            />
          </ModalInner>
        </Modal>
      </>
    )
  } else {
    return null;
  }
}

export { FullIssuePrimer };
